import { t } from '@lingui/macro'
import {
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React from 'react'
import { Professional, Schedule as ISchedule, ScheduleSlotObservation, Slot } from '../../../api/api'
import { useSlotProperties, WebDisabled } from '../doctoragenda/SlotProperties'
import ScheduleMaintenanceActions, { slotShares } from './ScheduleMaintenanceActions'
import ScheduleMaintenanceContent from './ScheduleMaintenanceContent'
import ScheduleMaintenanceFilter from './ScheduleMaintenanceFilter'
import ScheduleMaintenanceHeader from './ScheduleMaintenanceHeader'
import ScheduleMaintenanceObservation from './ScheduleMaintenanceObservation'

const notFound = -1
const emptyFilter = ''
const emptyOption = ''
const empetyConfirmationMessageContent = ''
const slotSeparator = ','

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(0.1),
    paddingTop: theme.spacing(0.1)
  },
  dialogContent: {
    paddingBottom: theme.spacing(0.1),
    paddingTop: theme.spacing(0.1)
  },
  list: {
    width: '100%',
    minHeight: theme.spacing(30),
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(0.2),
    marginTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingTop: theme.spacing(0.2)
  },
  itemList: {
    padding: theme.spacing(0)
  },
  button: {
    textTransform: 'none',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0)
    }
  },
  dialogActions: {
    justifyContent: 'center'
  },
  chip: {
    minWidth: theme.spacing(12),
    color: 'white'
  },
  filter: {
    '& .MuiOutlinedInput-input': {
      paddingBottom: theme.spacing(0.2),
      paddingTop: theme.spacing(0.2),
      minWidth: theme.spacing(12)
    }
  }
}))

interface IScheduleMaintenanceProps {
  open: boolean
  setOpen(open: boolean): void
  schedule: ISchedule
  currentProfessional: Professional | null
  deleteSlots: (scheduleId: number, slotsIds: string) => void
  editSlotsEnabled: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotsIsReserved: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotsWebAppoinment: (professional: number, scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  webAppointmentSettings: boolean
  getObservation: (observationId: number) => string | undefined
  listObservations: ScheduleSlotObservation[]
}

const ScheduleMaintenance: React.FC<IScheduleMaintenanceProps> = ({
  open,
  setOpen,
  schedule,
  currentProfessional,
  deleteSlots,
  editSlotsEnabled,
  editSlotsIsReserved,
  editSlotsWebAppoinment,
  webAppointmentSettings,
  getObservation,
  listObservations
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { getStatusSlot } = useSlotProperties()
  const [checkedSlots, setCheckedSlots] = React.useState<Slot[]>([])
  const [confirmationMessageContent, setConfirmationMessageContent] = React.useState<string>(
    empetyConfirmationMessageContent
  )
  const [slotOptionSelected, setSlotOptionSelected] = React.useState<string>(emptyOption)
  const [openAddObservation, setOpenAddObservation] = React.useState(false)
  const [checkedAllSlots, setCheckedAllSlots] = React.useState<boolean>(false)
  const [filterScheduleStatus, setFilterScheduleStatus] = React.useState<string | number>(emptyFilter)
  const [slotsDisplayed, setSlotsDisplayed] = React.useState<Slot[]>(getEditableSlots(schedule.slots))
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setOpen(false)
    setFilterScheduleStatus(emptyFilter)
    setCheckedAllSlots(false)
    setCheckedSlots([])
  }

  const handleToggle = (value: Slot) => () => {
    const currentIndex = checkedSlots.indexOf(value)
    const newChecked = [...checkedSlots]

    if (currentIndex === notFound) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setCheckedSlots(newChecked)
  }

  React.useEffect(() => {
    if (filterScheduleStatus !== emptyFilter) {
      let visibleSlots = []
      if (webAppointmentSettings) {
        const webAppointmentFilter = filterScheduleStatus === WebDisabled
        visibleSlots = getEditableSlots(schedule.slots).filter(slot => {
          return slot.web_appointment === webAppointmentFilter
        })
      } else {
        visibleSlots = getEditableSlots(schedule.slots).filter(slot => {
          return getStatusSlot(slot).id === filterScheduleStatus
        })
      }
      setSlotsDisplayed(visibleSlots)
    } else {
      setSlotsDisplayed(getEditableSlots(schedule.slots))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterScheduleStatus, schedule.slots])

  React.useEffect(() => {
    if (checkedAllSlots) {
      setCheckedSlots(slotsDisplayed)
    } else {
      setCheckedSlots([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsDisplayed, checkedAllSlots])

  React.useEffect(() => {
    setCheckedSlots([])
  }, [JSON.stringify(slotsDisplayed)])

  const handleCheckboxChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAllSlots(event.target.checked)
  }

  const deleteSlotsSelect = () => {
    const slotsId = checkedSlots.map(slot => slot.id).join(slotSeparator)
    deleteSlots(schedule.id, slotsId)
  }

  const editSlotsDisabledSelect = () => {
    editSlotsEnabled(schedule.id, checkedSlots, false)
  }

  const editSlotsEnabledSelect = () => {
    editSlotsEnabled(schedule.id, checkedSlots, true)
  }

  const editSlotsReservedSelect = () => {
    editSlotsIsReserved(schedule.id, checkedSlots, true)
  }

  const editSlotsAvailableSelect = () => {
    editSlotsIsReserved(schedule.id, checkedSlots, false)
  }

  const editSlotsWebAppoinmentDisabledSelect = () => {
    editSlotsWebAppoinment(currentProfessional?.id || 0, schedule.id, checkedSlots, false)
  }

  const editSlotsWebAppoinmentEnabledSelect = () => {
    editSlotsWebAppoinment(currentProfessional?.id || 0, schedule.id, checkedSlots, true)
  }

  const handleConfirm = () => {
    switch (slotOptionSelected) {
      case slotShares.delete:
        deleteSlotsSelect()
        break
      case slotShares.disable:
        editSlotsDisabledSelect()
        break
      case slotShares.enable:
        editSlotsEnabledSelect()
        break
      case slotShares.reserve:
        editSlotsReservedSelect()
        break
      case slotShares.available:
        editSlotsAvailableSelect()
        break
      case slotShares.enableWebAppointments:
        editSlotsWebAppoinmentEnabledSelect()
        break
      case slotShares.disableWebAppointments:
        editSlotsWebAppoinmentDisabledSelect()
        break
    }
    setOpenAddObservation(false)
  }

  const addSlotObservation = (observationId: number) => {
    checkedSlots.forEach(slot => {
      slot.observation_id = observationId
    })
  }

  return (
    <>
      <ScheduleMaintenanceObservation
        {...{
          openConfirmDialog: openAddObservation,
          setOpenAddObservation,
          listObservations,
          addSlotObservation,
          handleConfirm,
          slotOptionSelected,
          confirmationMessageContent
        }}
      />
      <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} data-testid='schedule-maintenance'>
        <ScheduleMaintenanceHeader {...{ schedule, currentProfessional, handleClose }} />
        <Divider />
        <DialogTitle
          className={classes.dialogTitle}
          disableTypography={true}
          data-testid='test-tool-schedule-maintenance'
        >
          <Grid item={true} xs={12} container={true} direction='row' justifyContent='space-between' alignItems='center'>
            <Grid xs={6} item={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAllSlots}
                    name='selectAll'
                    id='selectAll'
                    onChange={handleCheckboxChangeAll}
                    data-testid='test-check-all-slots'
                  />
                }
                label={t`Seleccionar todos`}
              />
            </Grid>
            <Grid container={true} xs={6} item={true} spacing={1} alignItems='center' justifyContent='flex-end'>
              <ScheduleMaintenanceFilter
                {...{ filterScheduleStatus, setFilterScheduleStatus, webAppointmentSettings }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <ScheduleMaintenanceContent
          {...{ slotsDisplayed, handleToggle, checkedSlots, webAppointmentSettings, getObservation }}
        />
        <ScheduleMaintenanceActions
          {...{ setSlotOptionSelected, setConfirmationMessageContent, webAppointmentSettings }}
          setOpenConfirmDialog={setOpenAddObservation}
        />
      </Dialog>
    </>
  )
}
const getEditableSlots = (slots: Slot[]) => {
  return slots.filter(slot => new Date(slot.date).getTime() > new Date().getTime())
}
export default ScheduleMaintenance
