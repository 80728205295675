import { useEffect, useRef } from 'react'

declare type simpleFunction = () => void | Promise<void>

function useInterval(callback: simpleFunction, delay: number | null) {
  const savedCallback = useRef<simpleFunction>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        void savedCallback.current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
