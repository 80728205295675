import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { GetDataError } from 'restful-react'
import { APIError, usePostUsersPassword } from '../../api/api'

const defaultError = 0

const getMessageFromStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      return t`Es posible que tenga ya un proceso de recuperación pendiente, o no tenga un correo asignado, por favor comunicarse con el administrador o intenta acceder más tarde.`

    case 404:
      return t`El nombre de usuario ingresado no ha sido encontrado`

    case 500:
      return t`Error interno. En este momento no podemos atender su solicitud.`

    default:
      return t`Las instrucciones no han podido ser enviadas, puede intentarlo de nuevo más tarde.`
  }
}

export const useRecoveryPassword = (setErrorMessage: (message: string) => void) => {
  const [username, setUsername] = useState('')
  const [isRequestSent, setIsRequestSent] = useState(false)
  const recoveryPasswordApi = usePostUsersPassword({ username })

  useEffect(() => {
    setErrorMessage('')
    setIsRequestSent(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const recoveryPassword = () => {
    setErrorMessage('')
    recoveryPasswordApi
      .mutate()
      .then(() => {
        setIsRequestSent(true)
      })
      .catch((error: GetDataError<void | APIError>) =>
        setErrorMessage(getMessageFromStatusCode(error.status || defaultError))
      )
  }

  return { username, setUsername, recoveryPassword, loading: recoveryPasswordApi.loading, isRequestSent }
}
