import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogin } from '../Login/useLogin'
import { useSelectEntity } from '../Login/useSelectEntity'
import { SelectEntityFormProps } from '../Login/SelectEntityForm'
import { SelectEntityModel } from '../../api/api'
import { IChangeEntityContainer } from './ChangeEntityContainer'
import { ChangeEntityFormProps } from './ChangeEntityForm'

interface ChangeEntity {
  response: string
  error: boolean
  loading: boolean
}

export const useChangeEntity = (openComponent: IChangeEntityContainer): ChangeEntityFormProps => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isRequestSent, setIsRequestSent] = useState(false)
  const entityServices = useSelectEntity(setErrorMessage, true)
  const login = useLogin(setErrorMessage)
  const history = useHistory()

  const postSelectCompany = (requestSelect: SelectEntityModel) => {
    login.postSelectCompany(requestSelect)
    setIsRequestSent(true)
  }

  useEffect(() => {
    if (!login.loading && isRequestSent) {
      setIsRequestSent(false)
      history.push('/main')
    }
  }, [login.loading])
  return {
    open: openComponent.open,
    setOpen: openComponent.setOpen,
    changeEntity: {
      response: '',
      error: errorMessage !== '',
      loading: login.loading
    } as ChangeEntity,
    entitySelect: {
      entityServices,
      handleCloseSession: () => {
        return
      },
      isAuthenticated: true,
      postSelectCompany
    } as SelectEntityFormProps
  }
}
