import { Trans } from '@lingui/macro'
import { Backdrop, CircularProgress, Typography, Button, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import OtpInput from 'react18-otp-input'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  submit: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  cancel: {
    margin: theme.spacing(1, 0, 2, 0),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: theme.palette.error.contrastText
  },
  iconMain: {
    fontSize: theme.spacing(20)
  }
}))

export interface IValidateTwoFactorAuth {
  code: string
  handleOnChangeCode: (value: string) => void
  handleValidate: () => void
  isLoading: boolean
  handleCloseSession: () => void
}
const ValidateTwoFactorAuth: React.FC<IValidateTwoFactorAuth> = props => {
  const classes = useStyles()
  return (
    <>
      <Backdrop
        id='main-backdrop-load'
        data-testid='backdrop-load-two-factor'
        open={props.isLoading}
        className={classes.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <form className={classes.form} data-testid='form-only-validate' noValidate={true}>
        <Typography variant='h5' gutterBottom color={'primary'}>
          <Trans> 2FA - Segundo Factor de Autenticación</Trans>
        </Typography>
        <Typography variant='body1' gutterBottom>
          <Trans>Su entidad ha requerido un segundo factor de autenticación como política de ingreso al sistema.</Trans>
        </Typography>
        <LibraryAddCheckIcon className={classes.iconMain} color='primary' />
        <Typography variant='body1' gutterBottom>
          <Trans>
            Por favor ingrese el código que genera la aplicación de Google Auth asociada a su usuario para ingresar, de
            no tener configurado su usuario en la apliación pongase en contacto con el administrador.
          </Trans>
        </Typography>
        <OtpInput
          inputStyle='inputStyle'
          numInputs={6}
          onChange={props.handleOnChangeCode}
          separator={<span>-</span>}
          isInputNum={true}
          shouldAutoFocus
          value={props.code}
        />
        <Button
          id='enter-button'
          data-testid='button-submit'
          onClick={props.handleValidate}
          fullWidth={true}
          variant='contained'
          color='primary'
          className={classes.submit}
          disabled={props.code.length !== 6}
        >
          <Trans>Entrar</Trans>
        </Button>
        <Button
          id='enter-button'
          data-testid='button-submit'
          onClick={props.handleCloseSession}
          fullWidth={true}
          variant='contained'
          color='secondary'
          className={classes.cancel}
        >
          <Trans>Cancelar</Trans>
        </Button>
      </form>
    </>
  )
}

export default ValidateTwoFactorAuth
