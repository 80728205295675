import { useEffect } from 'react'
import { useGetSchedulesRooms } from '../../../api/api'
import { getMessageFromStatusCode } from '../Utils/useMessageFromStatusCode'

const useRoomsApi = (setMessageError: (value: string) => void) => {
  const roomsApi = useGetSchedulesRooms({})

  const roomsList = roomsApi.data ?? []
  const roomsError = roomsApi.error
  const isLoadingRooms = roomsApi.loading

  useEffect(() => {
    if (!roomsError) {
      setMessageError('')
      return
    }

    setMessageError(getMessageFromStatusCode(roomsError.status) || '')
  }, [roomsError])

  return { roomsList, isLoadingRooms, roomsError }
}

export default useRoomsApi
