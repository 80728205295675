import { ListItem } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import React, { FunctionComponent, KeyboardEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import MenuItem from '../../models/MenuItem'
import { useMenuItems } from './contexts/useMenuItems'

export interface MainMenuItemProps {
  item: MenuItem
  level: number
  innerRef?: React.RefObject<HTMLDivElement>
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void
}

export const MainMenuItem: FunctionComponent<MainMenuItemProps> = props => {
  const useStyles = makeStyles(theme =>
    createStyles({
      menuItem: {
        paddingLeft: theme.spacing(2 * props.level)
      }
    })
  )

  const classes = useStyles()
  const isExpandable = props.item.items && props.item.items.length > 0
  const [open, setOpen] = React.useState(false)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { selectedMenuItem } = useMenuItems()

  function handleClick() {
    if (props.item.url === '' || selectedMenuItem?.id === props.item.id) {
      setOpen(!open)
      return
    }

    const newUrl = `${url}/option/${props.item.id}`
    if (newUrl !== history.location.pathname) {
      history.push(newUrl)
    }
  }

  const MenuItemRoot = (
    <ListItem
      className={classes.menuItem}
      onClick={handleClick}
      button={true}
      innerRef={props.innerRef}
      onKeyDown={props.onKeyDown}
    >
      <ListItemText primary={props.item.title} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItem>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout='auto' unmountOnExit={true}>
      <Divider />
      <List component='div' disablePadding={true}>
        {props.item.items &&
          props.item.items.map((item, index) => <MainMenuItem item={item} key={index} level={props.level + 1} />)}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

export default MainMenuItem
