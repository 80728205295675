import { t, Trans } from '@lingui/macro'
import {
  Backdrop,
  CircularProgress,
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { usePutCurrentUserConfig, UserNotificationEmail } from '../../api/api'
import SimpleDialog from '../../components/MainMenu/SimpleDialog'
import useChangeNotificationSettingsVal, { Fields } from './useChangeNotificationSettingsVal'

type SetOpenFunction = (open: boolean) => void

export interface ChangeNotificationSettingsProps {
  open: boolean
  setOpen: SetOpenFunction
  currentEmail?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFormChangeNotificationSettings: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.error.contrastText
    }
  })
)

const ChangeNotificationSettings: FunctionComponent<ChangeNotificationSettingsProps> = props => {
  const [values, setValues] = useState({ newEmail: '', confirmNewEmail: '' })
  const validations = useChangeNotificationSettingsVal(values)

  const [changeNotificationSettingsApiError, setChangeNotificationSettingsApiError] = useState(false)
  const [successChangeNotificationSettings, setSuccessChangeNotificationSettings] = useState(false)
  const [responseAPI, setResponseAPI] = useState('')
  const changeNotificationSettingsApi = usePutCurrentUserConfig({})

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  useEffect(() => {
    if (responseAPI === 'ok') {
      setSuccessChangeNotificationSettings(true)
    }
  }, [responseAPI])

  const handleOk = () => {
    const settings: UserNotificationEmail = { email: values.newEmail }
    changeNotificationSettingsApi
      .mutate(settings)
      .then(() => {
        setResponseAPI('ok')
      })
      .catch(() => setChangeNotificationSettingsApiError(true))
  }

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleChange = (prop: keyof Fields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleCloseErrorDialog = () => {
    setChangeNotificationSettingsApiError(false)
  }

  const handleSuccesChangeNotificationSettingsDialog = () => {
    setSuccessChangeNotificationSettings(false)
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={props.open && !successChangeNotificationSettings}
        aria-labelledby='change-notifications-settings-form-dialog-title'
        fullScreen={fullScreen}
      >
        {changeNotificationSettingsApi.loading && (
          <Backdrop
            data-testid='change-notifications-settings-backdrop-load'
            open={changeNotificationSettingsApi.loading}
            className={classes.backdrop}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <DialogTitle id='form-dialog-title'>
          <Trans>Cambiar Correo de Notificaciones</Trans>
        </DialogTitle>
        <DialogContent className={classes.rootFormChangeNotificationSettings}>
          <FormControl fullWidth={true}>
            <div>
              <TextField
                id='input-email'
                data-testid='input-email'
                type='email'
                fullWidth={true}
                disabled={true}
                value={props.currentEmail}
                label='Actual'
              />
            </div>
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              id='input-new-email'
              data-testid='input-new-email'
              type='email'
              fullWidth={true}
              autoFocus={true}
              value={values.newEmail}
              onChange={handleChange('newEmail')}
              error={validations.newEmailVal !== ''}
              helperText={validations.newEmailVal}
              label='Nuevo'
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              id='input-confirm-new-email'
              data-testid='input-confirm-new-email'
              type='email'
              fullWidth={true}
              value={values.confirmNewEmail}
              onChange={handleChange('confirmNewEmail')}
              error={validations.confirmNewEmailVal !== ''}
              helperText={validations.confirmNewEmailVal}
              label='Confirmar Nuevo'
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            id='change-notification-settings-button-ok'
            onClick={handleOk}
            color='primary'
            disabled={!validations.isValid}
          >
            <Trans>Aceptar</Trans>
          </Button>
          <Button id='change-notification-settings-button-cancel' onClick={handleClose} color='primary'>
            <Trans>Cancelar</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      {changeNotificationSettingsApiError && (
        <SimpleDialog
          open={changeNotificationSettingsApiError}
          title={t`Error al comunicarse con el servicio de iMedicalCloud`}
          contentText={t`En este momento no podemos enviar su correo o no podemos comunicarnos con el servicio. Reintente más tarde.`}
          dialogActions={
            <Button data-testid='dialog-error-ok-button' onClick={handleCloseErrorDialog} color='primary'>
              Aceptar
            </Button>
          }
          dialogClose={handleCloseErrorDialog}
        />
      )}
      {successChangeNotificationSettings && (
        <SimpleDialog
          open={successChangeNotificationSettings}
          title={t`Cambiar Correo de Notificaciones`}
          contentText={t`Un mensaje fue enviado con las instrucciones para confirmar el correo. Si no encuentra el mensaje no olvide buscarlo en la carpeta de SPAM.`}
          dialogActions={
            <Button onClick={handleSuccesChangeNotificationSettingsDialog} color='primary'>
              Aceptar
            </Button>
          }
          dialogClose={handleSuccesChangeNotificationSettingsDialog}
        />
      )}
    </div>
  )
}

export default ChangeNotificationSettings
