import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'

export type Fields = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export type FieldsValidations = {
  currentPasswordVal: string
  newPasswordVal: string
  confirmNewPasswordVal: string
  isValid: boolean
}

function useChangePasswordVal(values: Fields) {
  const [validations, setValidations] = useState({
    currentPasswordVal: '',
    newPasswordVal: '',
    confirmNewPasswordVal: '',
    isValid: false
  })

  useEffect(() => {
    const currentVal: FieldsValidations = {
      currentPasswordVal: '',
      newPasswordVal: '',
      confirmNewPasswordVal: '',
      isValid: false
    }

    if (values.currentPassword !== '' && values.newPassword !== '' && values.currentPassword === values.newPassword) {
      currentVal.newPasswordVal = t`La nueva contraseña debe ser diferente a la contraseña anterior`
      setValidations(currentVal)
      return
    }

    if (values.newPassword !== '' && values.newPassword.length < 6) {
      currentVal.newPasswordVal = t`Su nueva contraseña debe tener una longitud mínima de seis (6) caracteres`
      setValidations(currentVal)
      return
    }

    if (
      values.currentPassword !== '' &&
      values.newPassword !== '' &&
      values.confirmNewPassword !== '' &&
      values.newPassword !== values.confirmNewPassword
    ) {
      currentVal.confirmNewPasswordVal = t`La nueva contraseña debe ser igual a la confirmada`
      setValidations(currentVal)
      return
    }

    if (
      values.currentPassword !== '' &&
      values.newPassword !== '' &&
      values.confirmNewPassword !== '' &&
      values.newPassword === values.confirmNewPassword
    ) {
      currentVal.isValid = true
      setValidations(currentVal)
    }
  }, [values])

  return { validations, setValidations }
}

export default useChangePasswordVal
