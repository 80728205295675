import React from 'react'
import { GetDataError } from 'restful-react'
import { APIError, Schedule, Slot, usePutWebAppointment } from '../../../api/api'
import { getMessageFromStatusCode } from '../Utils/useMessageFromStatusCode'
import { defaultScheduleId, defaultProfessionalId } from './useProfessionalSchedules'

interface IuseWebAppointmentProps {
  setMessageError: (value: string) => void
  listProfessionalsSchedules: Schedule[]
  setProfessionalsSchedules: (value: Schedule[]) => void
}
export const useWebAppointment = ({
  setMessageError,
  listProfessionalsSchedules,
  setProfessionalsSchedules
}: IuseWebAppointmentProps) => {
  const [professionalId, setProfessionalId] = React.useState(defaultProfessionalId)

  const [slotsEdition, setslotsEdition] = React.useState<Slot[]>([])
  const [scheduleSlotEditionId, setScheduleSlotEditionId] = React.useState<number>(defaultScheduleId)

  const putWebAppointment = usePutWebAppointment({
    id: professionalId,
    schedule_id: scheduleSlotEditionId
  })
  const updateSlotLocales = (schedule: Schedule | undefined, slotsResponse: Slot[]) => {
    schedule?.slots.forEach(slot => {
      for (const slotResponse of slotsResponse) {
        if (slot.id === slotResponse.id) {
          slot.web_appointment = slotResponse.web_appointment
          break
        }
      }
    })
  }
  React.useEffect(() => {
    if (slotsEdition.length !== 0 && scheduleSlotEditionId !== defaultScheduleId) {
      putWebAppointment
        .mutate(slotsEdition)
        .then((slotsResponse: Slot[]) => {
          const copySchedules = listProfessionalsSchedules
          const schedule = copySchedules.find(scheduleIterator => scheduleIterator.id === scheduleSlotEditionId)
          updateSlotLocales(schedule, slotsResponse)
          setProfessionalsSchedules([...copySchedules])
        })
        .catch((e: GetDataError<void | APIError>) => {
          setslotsEdition([])
          setScheduleSlotEditionId(defaultScheduleId)
          setMessageError(getMessageFromStatusCode(e.status) || '')
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsEdition, scheduleSlotEditionId])

  const editSlotsWebAppoinment = (professional: number, scheduleId: number, slotsEdit: Slot[], state: boolean) => {
    const editableSlots: Slot[] = JSON.parse(JSON.stringify(slotsEdit)) as Slot[]
    editableSlots.forEach(slotIterator => {
      if (!slotIterator.is_assigned) slotIterator.web_appointment = state
    })
    setProfessionalId(professional)
    setScheduleSlotEditionId(scheduleId)
    setslotsEdition(editableSlots)
    setMessageError('')
  }

  const loadingWebAppoinment = putWebAppointment.loading

  return {
    loadingWebAppoinment,
    editSlotsWebAppoinment
  }
}
