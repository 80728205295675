import { t } from '@lingui/macro'
import { createStyles, Fab, Grid, IconButton, InputAdornment, makeStyles, Theme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React from 'react'
import { ScheduleInput } from '../../../api/api'
import TimePicker from '../Utils/components/TimePicker'
import FormatDate from '../Utils/FormatDate'
import useScheduleRange from './useScheduleRange'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datetime: {
      margin: theme.spacing(0.625),
      width: '100%'
    },
    containerArrowForwardIcon: {
      textAlign: 'center',
      paddingTop: theme.spacing(1.2)
    },
    leftalign: {
      textAlign: 'left'
    },
    containerDeleteIcon: {
      alignSelf: 'flex-end'
    }
  })
)

const emptyHour = ''
const hourIntervalInMinutes = 30
const maxQuantityHoursInputs = 2

export interface ScheduleRangesProps {
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
}

const ScheduleRanges: React.FC<ScheduleRangesProps> = props => {
  const classes = useStyles()
  const { formatDate } = FormatDate()
  const {
    handleHourPicker,
    handleDatePicker,
    parseHourValue,
    parseDateValue,
    startHourField,
    endHourField,
    startDateField,
    endDateField
  } = useScheduleRange(props)

  const addHour = () => {
    if (props.newSchedule.hours.length === 0) {
      props.setNewSchedule({
        ...props.newSchedule,
        hours: [
          { start_hour: emptyHour, end_hour: emptyHour },
          { start_hour: emptyHour, end_hour: emptyHour }
        ]
      })
    } else {
      props.setNewSchedule({
        ...props.newSchedule,
        hours: [...props.newSchedule.hours, { start_hour: emptyHour, end_hour: emptyHour }]
      })
    }
  }

  const removeHour = (index: number) => {
    props.setNewSchedule({
      ...props.newSchedule,
      hours: [...props.newSchedule.hours.filter((_, indexNewScheduleHour) => indexNewScheduleHour !== index)]
    })
  }

  return (
    <>
      <Grid container={true} item={true} xs={11}>
        <Grid container={true} item={true} xs={5} spacing={1}>
          <Grid item={true} xl={6} xs={12} className={classes.leftalign}>
            <KeyboardDatePicker
              disablePast={true}
              data-testid='start-date'
              className={classes.datetime}
              inputVariant='outlined'
              placeholder={t`fecha inicial`}
              cancelLabel={t`Cancelar`}
              okLabel={t`Aceptar`}
              format={formatDate}
              value={parseDateValue(props.newSchedule.start_date)}
              onChange={(date: MaterialUiPickersDate) => handleDatePicker(startDateField, date)}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item={true} xl={6} xs={12} className={classes.leftalign}>
            <TimePicker
              variant='outlined'
              data-testid='start-hour'
              value={parseHourValue(props.newSchedule.hours[0]?.start_hour)}
              size='small'
              className={classes.datetime}
              intervalInMinutes={hourIntervalInMinutes}
              placeholder={t`hora inicial`}
              startInCurrentTime={true}
              onChange={(hour: Date | null) => handleHourPicker(startHourField, 0, hour)}
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={2}>
          <div className={classes.containerArrowForwardIcon}>
            <ArrowForwardIcon />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={5} spacing={1}>
          <Grid item={true} xl={6} xs={12} className={classes.leftalign}>
            <KeyboardDatePicker
              minDate={parseDateValue(props.newSchedule.start_date)}
              data-testid='end-date'
              className={classes.datetime}
              inputVariant='outlined'
              placeholder={t`fecha final`}
              cancelLabel={t`Cancelar`}
              okLabel={t`Aceptar`}
              format={formatDate}
              value={parseDateValue(props.newSchedule.end_date)}
              onChange={(date: MaterialUiPickersDate) => handleDatePicker(endDateField, date)}
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton data-testid='end-date-btn'>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item={true} xl={6} xs={12} className={classes.leftalign}>
            <TimePicker
              variant='outlined'
              data-testid='end-hour'
              value={parseHourValue(props.newSchedule.hours[0]?.end_hour)}
              size='small'
              className={classes.datetime}
              intervalInMinutes={hourIntervalInMinutes}
              placeholder={t`hora final`}
              startInCurrentTime={true}
              onChange={(hour: Date | null) => handleHourPicker(endHourField, 0, hour)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={1} />

      {props.newSchedule.hours.map(
        (hour, index: number) =>
          index !== 0 && (
            <React.Fragment key={index}>
              <Grid container={true} item={true} xs={11}>
                <Grid container={true} item={true} xs={5} spacing={1}>
                  <Grid item={true} xl={6} />
                  <Grid item={true} xl={6} xs={12}>
                    <TimePicker
                      variant='outlined'
                      data-testid={`start-hour-${index}`}
                      value={parseHourValue(hour.start_hour)}
                      size='small'
                      className={classes.datetime}
                      intervalInMinutes={hourIntervalInMinutes}
                      placeholder={t`hora inicial`}
                      startInCurrentTime={true}
                      onChange={(value: Date | null) => handleHourPicker(startHourField, index, value)}
                    />
                  </Grid>
                </Grid>
                <Grid item={true} xs={2} />
                <Grid container={true} item={true} xs={5} spacing={1}>
                  <Grid item={true} xl={6} />
                  <Grid item={true} xl={6} xs={12}>
                    <TimePicker
                      variant='outlined'
                      data-testid={`end-hour-${index}`}
                      value={parseHourValue(hour.end_hour)}
                      size='small'
                      className={classes.datetime}
                      intervalInMinutes={hourIntervalInMinutes}
                      placeholder={t`hora final`}
                      startInCurrentTime={true}
                      onChange={(value: Date | null) => handleHourPicker(endHourField, index, value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={1} className={classes.containerDeleteIcon}>
                <Fab
                  size='small'
                  data-testid={`remove-hour-${index}`}
                  aria-label='delete'
                  color='secondary'
                  onClick={() => removeHour(index)}
                >
                  <DeleteIcon />
                </Fab>
              </Grid>
            </React.Fragment>
          )
      )}

      <Grid container={true} item={true} xs={11} spacing={1}>
        <Grid item={true} xs={5} />
        <Grid item={true} xs={2}>
          {props.newSchedule.hours.length < maxQuantityHoursInputs && (
            <div className={classes.containerArrowForwardIcon}>
              <Fab size='small' data-testid='add-hour' color='primary' aria-label='add' onClick={addHour}>
                <AddIcon />
              </Fab>
            </div>
          )}
        </Grid>
        <Grid item={true} xs={5} />
      </Grid>
    </>
  )
}

export default ScheduleRanges
