/*
 * General utils for managing cookies in Typescript.
 */

import { securityTokensLocalStorageKey } from '../security/APISecurityContext'

let windowNavTabID = ''
const dayInMilliseconds = 24 * 60 * 60 * 1000
export const SessionIDCookieName = 'iMedicalCloudAppTabNavId'

// The value stored in the window.name warns if the page was loaded the first
// time or if it was reloaded. This is how the previous menu found out whether
// or not to calculate a new session id. In our case, the validation of the
// session must be done by the Login.
if (!window.name) {
  sessionStorage.removeItem(SessionIDCookieName)
  sessionStorage.removeItem(securityTokensLocalStorageKey)
  deleteCookie(SessionIDCookieName)
  window.name = Math.floor(Math.random() * 100).toString()
} else {
  const sessionStorageValue = sessionStorage.getItem(SessionIDCookieName)
  windowNavTabID = sessionStorageValue !== null ? sessionStorageValue : ''
}

export function clearWindowNavTabID() {
  windowNavTabID = ''
}

export function getWindowNavTabID() {
  return windowNavTabID
}

export const generateWindowNavTabID = () => {
  clearUserSessionCookies()
  const d = new Date()
  const cookieValue =
    d.getUTCMilliseconds().toString() +
    Math.floor(Math.random() * 9999999).toString() +
    d
      .getUTCMilliseconds()
      .toString()
      .replace('.', '')
      .substring(3) +
    d.getUTCMilliseconds().toString()

  sessionStorage.setItem(SessionIDCookieName, cookieValue)
  setCookie(SessionIDCookieName, cookieValue)
  windowNavTabID = cookieValue
}

export function setCookie(name: string, val: string) {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + 7 * dayInMilliseconds)

  // Set it
  document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/; SameSite=Strict; secure;'
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')

  if (parts.length === 2) {
    const lastPart = parts.pop()
    if (lastPart !== undefined && lastPart.split(';').shift() !== '') {
      return lastPart.split(';').shift()
    }
  }

  return null
}

export function deleteCookie(name: string) {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * dayInMilliseconds)

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/;SameSite=Strict'
}

export function clearUserSessionCookies() {
  clearWindowNavTabID()
  deleteCookie(SessionIDCookieName)
  sessionStorage.removeItem(SessionIDCookieName)
}

export function setSessionInformation() {
  if (getWindowNavTabID() !== '') {
    sessionStorage.setItem(SessionIDCookieName, getWindowNavTabID())
    setCookie(SessionIDCookieName, getWindowNavTabID())
  }
}
