import { Trans } from '@lingui/macro'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { FunctionComponent, useState } from 'react'
import FeaturedPost, { FeaturedPostProps } from './FeaturedPost'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4)
    },
    button: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.light
    }
  })
)

export interface FeaturedPostsProps {
  featuredPosts: FeaturedPostProps[]
}

const FeaturedPosts: FunctionComponent<FeaturedPostsProps> = props => {
  const classes = useStyles()
  const [showedPosts, setShowedPosts] = useState(2)

  const showMoreClick = () => {
    setShowedPosts(showedPosts + 2)
  }

  return (
    <>
      <Grid container={true} spacing={4}>
        {props.featuredPosts.map((post, index) => index < showedPosts && <FeaturedPost key={post.id} {...post} />)}
      </Grid>
      <div className={classes.actions}>
        {props.featuredPosts.length > showedPosts && (
          <Button className={classes.button} variant='contained' onClick={showMoreClick}>
            <Trans>Ver más publicaciones</Trans>
          </Button>
        )}
      </div>
    </>
  )
}

export default FeaturedPosts
