import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: { main: '#1C7FE4' },
    secondary: { main: '#6EAA67' }
  }
})

export default theme
