import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setSessionInformation } from '../../libs/cookies'
import MenuItem from '../../models/MenuItem'
import { useMenuItems } from './contexts/useMenuItems'
import NotFound from './NotFound'

interface URLMenuItemParam {
  menuItemId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100vw',
      height: '95vh',
      overflowY: 'hidden',
      overflowX: 'auto',
      paddingBottom: theme.spacing(8.25),
      position: 'fixed'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2,
      color: '#fff'
    },
    frameContent: {
      width: '100%',
      height: '90vh',
      minWidth: theme.breakpoints.values.lg,
      overflow: 'hidden',
      margin: 0,
      border: 'none',
      backgroundColor: '#fff'
    }
  })
)

const getMenuItembyId = (id: number, items: MenuItem[]): MenuItem | null => {
  let found: MenuItem | null = null

  for (const item of items) {
    if (item.id === id) {
      found = item
      return found
    }

    if (item.items && item.items.length > 0) {
      found = getMenuItembyId(id, item.items)

      if (found !== null) {
        return found
      }
    }
  }

  return found
}

const FrameContent: FunctionComponent = () => {
  const classes = useStyles()
  const [loadingFrame, setLoadingFrame] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState<MenuItem | undefined | null>(null)
  const { menuItemId } = useParams<URLMenuItemParam>()
  const { menuItems, setSelectedMenuItem } = useMenuItems()

  const onLoadFrame = () => {
    setLoadingFrame(false)

    // Fix odd behavior when changing the menu option. The old JS modifies
    // the value of the sessionStorage and this set the correct information.
    setSessionInformation()
  }

  useEffect(() => {
    if (menuItemId === '') {
      setSelectedMenuItem(null)
      return
    }

    const tmpCurrentItem = getMenuItembyId(Number(menuItemId), menuItems)
    setIsLoading(true)
    setCurrentItem(tmpCurrentItem)
    setSelectedMenuItem(tmpCurrentItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemId])

  if (!currentItem || currentItem.url === undefined || currentItem.url === '') {
    if (isLoading) {
      return <NotFound />
    }
    return null
  }

  return (
    <div className={classes.content}>
      {loadingFrame && (
        <Backdrop id='backdrop-frame-content' className={classes.backdrop} open={loadingFrame}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <iframe
        id='frame-content'
        className={classes.frameContent}
        title='Current Option'
        src={currentItem.url}
        onLoad={onLoadFrame}
      />
    </div>
  )
}

export default FrameContent
