import { ChangeSet, EditingState, IntegratedEditing, ResourceInstance, ViewState } from '@devexpress/dx-react-scheduler'
import {
  Appointments,
  AppointmentTooltip,
  ConfirmationDialog,
  CurrentTimeIndicator,
  DateNavigator,
  DayView,
  MonthView,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView
} from '@devexpress/dx-react-scheduler-material-ui'
import { t } from '@lingui/macro'
import { makeStyles, Paper, Theme } from '@material-ui/core'
import classNames from 'clsx'
import { getHours } from 'date-fns'
import format from 'date-fns/format'
import React from 'react'
import { Professional, Schedule as ISchedule, ScheduleSlotObservation, Slot } from '../../../api/api'
import useLocalStorage from '../../../libs/useLocalStorage'
import ScheduleMaintenance from '../scheduleMaintenance/ScheduleMaintenance'
import FormatDate from '../Utils/FormatDate'
import ScheduleConfirmDialog from './ScheduleConfirmDialog'
import ScheduleContent from './ScheduleContent'
import ScheduleHeader from './ScheduleHeader'
import { viewSchedule, viewSlot, useSlotProperties, ISlotProperties } from './SlotProperties'
import SwitchScheduleView from './SwitchScheduleView'
import { viewNameMonth } from './useMedicalScheduler'
import { ISlotsSchedules } from './useProfessionalSchedules'

const locale = window.navigator.language
const startDayHourDefault = 6
const endDayHourDefault = 13
const cellDurationDefault = 30
const cellDurationOneHour = 60
const minuteInMilliseconds = 60 * 1000

const useStyles = makeStyles(theme => ({
  content: {
    width: '99%'
  },
  fullWidth: {
    width: '99%'
  },
  paper: {
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(2)
  },
  topGrid: {
    marginTop: theme.spacing(3)
  },
  gridLeft: {
    marginLeft: theme.spacing(1)
  },
  newAgendaButton: {
    padding: 0,
    width: '95%'
  },
  agendaLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(0)
  },
  list: {
    width: '100%',
    maxWidth: 310,
    backgroundColor: theme.palette.background.paper
  },
  ListItemIcon: {
    minWidth: theme.spacing(5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff'
  }
}))

const useStylesTimeIndicator = makeStyles<Theme, CurrentTimeIndicator.IndicatorProps>(theme => ({
  line: {
    height: theme.spacing(0.25),
    borderTopWidth: theme.spacing(0.25),
    borderTopColor: theme.palette.error.main,
    borderTopStyle: 'solid',
    width: '100%',
    transform: `translate(0, -${theme.spacing(0.125)})`
  },
  circle: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.error.main
  },
  nowIndicator: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    left: 0,
    top: ({ top }) => top
  }
}))

const { FormatDateRequests } = FormatDate()
const getFormattedEndDate = (startDate: string, durationMin: number): string => {
  const endDate = new Date(new Date(startDate).getTime() + durationMin * 60000)
  return format(endDate, FormatDateRequests)
}

export interface IScheduleProps {
  currentProfessional: Professional | null
  currentDate: Date
  setCurrentDate: (value: Date) => void
  currentViewName: string
  setCurrentViewName: (value: string) => void
  deleteSlot: (value: number) => void
  deleteSlots: (scheduleId: number, slotsIds: string) => void
  deleteSchedule: (value: number) => void
  editSlotIsReserved: (slotIdEdit: number, observationId: number) => void
  editSlotsIsReserved: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotEnabled: (slotIdEdit: number, observationId: number) => void
  editSlotsEnabled: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotsWebAppoinment: (professional: number, scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  listProfessionalsSchedules: ISchedule[]
  getObservation: (observationId: number) => string | undefined
  listObservations: ScheduleSlotObservation[]
  editExtraSlotsCount: (id: number, value?: number) => void
  loadingProfessionalsSchedules: boolean
  messageErrorSchedule: string
}

const switchViewLocalStorageKey = 'switchView'

const LayoutTimeIndicator: React.FC<CurrentTimeIndicator.IndicatorProps> = ({ top, ...restProps }) => {
  const classes = useStylesTimeIndicator({ top })
  return (
    <div {...restProps}>
      <div className={classNames(classes.nowIndicator, classes.circle)} />
      <div className={classNames(classes.nowIndicator, classes.line)} />
    </div>
  )
}

// eslint-disable-next-line max-lines-per-function
const Schedule: React.FC<IScheduleProps> = ({
  currentProfessional,
  currentDate,
  setCurrentDate,
  currentViewName,
  setCurrentViewName,
  deleteSlot,
  deleteSchedule,
  editSlotIsReserved,
  editSlotsIsReserved,
  editSlotEnabled,
  editSlotsEnabled,
  editSlotsWebAppoinment,
  listProfessionalsSchedules,
  deleteSlots,
  getObservation,
  listObservations,
  editExtraSlotsCount,
  loadingProfessionalsSchedules,
  messageErrorSchedule
}) => {
  const classes = useStyles()
  const { slotProperties, getStatusSlot } = useSlotProperties() as {
    slotProperties: ISlotProperties
    getStatusSlot: (slot: Slot) => ResourceInstance
  }
  const [slotsSchedules, setSlotsSchedules] = React.useState<ISlotsSchedules[]>([])
  const [switchView, setSwitchView] = useLocalStorage(switchViewLocalStorageKey, viewSchedule)
  const [startDayHour, setStartDayHour] = React.useState<number>(startDayHourDefault)
  const [endDayHour, setEndDayHour] = React.useState<number>(endDayHourDefault)
  const [cellDuration, setCellDuration] = React.useState<number>(cellDurationDefault)
  const [openScheduleMaintenance, setOpenScheduleMaintenance] = React.useState(false)
  const [currentScheduleMaintenance, setCurrentScheduleMaintenace] = React.useState<ISchedule | null>(null)
  const [webAppointmentSettings, setWebAppointmentSettings] = React.useState(false)
  const [showWebAppointmentOption, setShowWebAppointmentOption] = React.useState(false)

  const commitChanges = ({ deleted }: ChangeSet) => {
    const data = slotsSchedules
    if (deleted !== undefined) {
      if (switchView === viewSlot) {
        deleteSlot(+deleted || 0)
      } else {
        deleteSchedule(+deleted || 0)
      }
    }
    return { data }
  }
  const makeScheduleMaintenance = () => {
    setOpenScheduleMaintenance(true)
  }

  const currentSchedule = (idSchedule: number) => {
    const foundSchedule = listProfessionalsSchedules.find(schedule => schedule.id === idSchedule)
    setCurrentScheduleMaintenace(foundSchedule || null)
    const resp = currentScheduleMaintenance?.exams.every(exam => exam.web_appointment === true) || false
    setShowWebAppointmentOption(resp)
  }

  React.useEffect(() => {
    if (switchView === viewSlot) {
      setCellDuration(cellDurationDefault)
    } else {
      setCellDuration(cellDurationOneHour)
    }
  }, [switchView, currentScheduleMaintenance])

  React.useEffect(() => {
    const slots: ISlotsSchedules[] = []
    if (currentViewName !== viewNameMonth) {
      setStartDayHour(getStartDayHour(listProfessionalsSchedules))
      setEndDayHour(getEndDayHour(listProfessionalsSchedules))
    }

    listProfessionalsSchedules?.forEach(schedule => {
      if (switchView === viewSchedule) {
        showSchedule(schedule, slots)
      } else {
        showScheduleSlot(schedule, slots)
      }
    })

    setSlotsSchedules([...slots])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProfessionalsSchedules, switchView, currentViewName])

  const showScheduleSlot = (schedule: ISchedule, slots: ISlotsSchedules[]) => {
    schedule.slots.forEach(slot => {
      slots.push({
        id: slot.id,
        startDate: slot.date,
        endDate: getFormattedEndDate(slot.date, slot.duration_in_min),
        title: schedule?.exams?.map(exam => exam.name).join() || '',
        room: schedule.room?.name || '',
        examens: schedule?.exams?.map(exam => exam.name).join() || '',
        status: Number(getStatusSlot(slot).id),
        observation: getObservation(slot.observation_id || 0),
        enabled: slot.enabled,
        isReserved: slot.is_reserved
      })
    })
  }

  const showSchedule = (schedule: ISchedule, slots: ISlotsSchedules[]) => {
    slots.push({
      id: schedule.id,
      startDate: schedule.start_date,
      endDate: schedule.end_date,
      title: schedule?.exams?.map(exam => exam.name).join() || '',
      room: schedule.room?.name || '',
      examens: schedule?.exams?.map(exam => exam.name).join() || '',
      extraSlotsCount: schedule.extra_slots_count,
      patientQuantity: schedule.patient_quantity,
      creatorName: schedule.creator_name,
      creationDate: schedule.creation_date,
      activationDate: schedule.activation_date
    })
  }

  const SwitchScheduleWrapper: React.FC<Toolbar.FlexibleSpaceProps> = () => {
    return <SwitchScheduleView {...{ switchView, setSwitchView, currentViewName }} />
  }

  const LayoutActionSlotButtons: React.FC<AppointmentTooltip.HeaderProps> = props => {
    return (
      <ScheduleHeader
        {...{
          ...props,
          editSlotIsReserved,
          editSlotEnabled,
          switchView,
          makeScheduleMaintenance,
          setWebAppointmentSettings,
          currentSchedule,
          showWebAppointmentOption,
          listObservations
        }}
      />
    )
  }

  const LayoutScheduleContent: React.FC<AppointmentTooltip.ContentProps> = props => {
    return (
      <ScheduleContent
        {...{
          ...props,
          switchView,
          editExtraSlotsCount,
          loadingProfessionalsSchedules,
          messageErrorSchedule,
          appointmentScheduleSlots: slotsSchedules.find(s => s.id === props.appointmentData?.id)
        }}
      />
    )
  }

  const LayoutMessageConfirmation: React.FC<ConfirmationDialog.LayoutProps> = props => {
    return <ScheduleConfirmDialog {...{ ...props, switchView }} />
  }

  return (
    <React.Fragment>
      {currentScheduleMaintenance && (
        <ScheduleMaintenance
          open={openScheduleMaintenance}
          setOpen={setOpenScheduleMaintenance}
          schedule={currentScheduleMaintenance}
          editSlotsWebAppoinment={editSlotsWebAppoinment}
          {...{
            currentProfessional,
            webAppointmentSettings,
            editSlotsIsReserved,
            editSlotsEnabled,
            deleteSlots,
            getObservation,
            listObservations
          }}
        />
      )}
      <Paper className={classes.paper} data-testid='test-scheduler-main'>
        <Scheduler data={slotsSchedules} locale={locale}>
          <ViewState
            defaultCurrentDate={currentDate === null ? '' : currentDate}
            currentViewName={currentViewName}
            onCurrentViewNameChange={setCurrentViewName}
            currentDate={currentDate === null ? '' : currentDate}
            onCurrentDateChange={setCurrentDate}
          />
          <EditingState onCommitChanges={commitChanges} />
          <MonthView displayName={t`MES`} />
          <WeekView displayName={t`SEMANA`} {...{ startDayHour, endDayHour, cellDuration }} />
          <DayView displayName={t`DÍA`} {...{ startDayHour, endDayHour, cellDuration }} />

          <Toolbar flexibleSpaceComponent={SwitchScheduleWrapper} />
          <DateNavigator />
          <TodayButton messages={{ today: t`Hoy` }} />
          <IntegratedEditing />
          <ViewSwitcher />
          <ConfirmationDialog layoutComponent={LayoutMessageConfirmation} />
          <Appointments />
          <AppointmentTooltip
            headerComponent={LayoutActionSlotButtons}
            contentComponent={LayoutScheduleContent}
            showDeleteButton={true}
            showCloseButton={true}
          />
          {switchView === viewSlot && <Resources data={slotProperties.StatusColor} />}
          <CurrentTimeIndicator
            indicatorComponent={LayoutTimeIndicator}
            shadePreviousCells={true}
            shadePreviousAppointments={true}
            updateInterval={minuteInMilliseconds}
          />
        </Scheduler>
      </Paper>
    </React.Fragment>
  )
}

export const getEndDayHour = (schedules: ISchedule[]) => {
  if (schedules?.length > 0) {
    let endHours = getHours(new Date(schedules[0].end_date))
    schedules.forEach(schedule => {
      const auxEndHours = getHours(new Date(schedule.end_date))
      endHours = endHours <= auxEndHours ? auxEndHours : endHours
    })

    return endHours
  } else {
    return endDayHourDefault
  }
}

export const getStartDayHour = (schedules: ISchedule[]) => {
  if (schedules?.length > 0) {
    let startHours = getHours(new Date(schedules[0].start_date))
    schedules.forEach(schedule => {
      const auxStartHours = getHours(new Date(schedule.start_date))
      startHours = startHours >= auxStartHours ? auxStartHours : startHours
    })

    return startHours
  } else {
    return startDayHourDefault
  }
}

export default Schedule
