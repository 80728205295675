import { Card, CardHeader, IconButton } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import React from 'react'

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  card: {
    borderRadius: '0px',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  icon: {
    color: theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.secondary.light
  },
  text: {
    overflow: 'hidden',
    wordBreak: 'break-word',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: '2.8em'
  }
}))

type Post = {
  id: number
  title: string
  url: string
}

export interface MainSectionProps {
  posts?: Post[]
  title: string
}

const MainSection: React.SFC<MainSectionProps> = props => {
  const classes = useStyles()
  const { posts, title } = props

  if (!posts || posts.length === 0) {
    return null
  }

  return (
    <>
      <Divider variant='middle' />
      <Typography className={classes.title} variant='h4' gutterBottom={true} color='secondary' align={'center'}>
        {title}
      </Typography>
      <Grid container={true} spacing={3}>
        {posts &&
          posts.map(post => (
            <Grid key={post.id} item={true} xs={12} sm={12} lg={6}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={<InsertDriveFileIcon className={classes.icon} aria-label='file' />}
                  action={
                    <IconButton aria-label='download'>
                      <Link key={post.id} rel='noopener' target='_blank' href={post.url} color='textPrimary'>
                        <GetAppIcon className={classes.icon} />
                      </Link>
                    </IconButton>
                  }
                  title={
                    <Link key={post.id} rel='noopener' target='_blank' href={post.url} color='textPrimary'>
                      <Typography className={classes.text}>{post.title}</Typography>
                    </Link>
                  }
                />
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default MainSection
