import { Resource } from '@devexpress/dx-react-scheduler'
import { t } from '@lingui/macro'
import { amber, blue, green, grey, red } from '@material-ui/core/colors/'
import { Slot } from '../../../api/api'

const defaultSlotStatesConfiguration = 0
const Assigned = 0
const Disabled = 1
const Reserved = 2
const Available = 3
const Scheduled = 4
export const WebDisabled = 1
const WebAvailable = 2

export type switchViewType = 'schedule' | 'slot'
export const viewSchedule: switchViewType = 'schedule'
export const viewSlot: switchViewType = 'slot'
const shadeA400Name = 'A400'
const shade300Name = 300
const shade400Name = 400

export const defaultColor = blue[shade300Name]
export interface IStatusSlot {
  id: number
  text: string
  color: string
}
export interface ISlotProperties {
  StatusColor: Resource[]
  Assigned: number
  Disabled: number
  Reserved: number
  Available: number
  Scheduled: number
}
export const useSlotProperties = () => {
  const statusSlots: IStatusSlot[] = [
    { id: Assigned, text: t`Asignado`, color: red[shadeA400Name] },
    { id: Disabled, text: t`Deshabilitado`, color: grey[shade400Name] },
    { id: Reserved, text: t`Reservado`, color: amber[shadeA400Name] },
    { id: Available, text: t`Disponible`, color: green[shadeA400Name] },
    { id: Scheduled, text: t`Agenda`, color: defaultColor }
  ]

  const webStatusSlots = [
    { id: WebDisabled, text: t`Web`, color: defaultColor },
    { id: WebAvailable, text: t`Presencial`, color: defaultColor }
  ]

  const StatusColor: Resource[] = [
    {
      fieldName: 'status',
      title: 'Status',
      instances: [...statusSlots]
    }
  ]

  const slotProperties: ISlotProperties = {
    StatusColor,
    Assigned,
    Disabled,
    Reserved,
    Available,
    Scheduled
  }

  const getStatusSlot = (slot: Slot) => {
    return slot.is_assigned
      ? StatusColor[defaultSlotStatesConfiguration].instances[Assigned]
      : !slot.enabled
      ? StatusColor[defaultSlotStatesConfiguration].instances[Disabled]
      : slot.is_reserved
      ? StatusColor[defaultSlotStatesConfiguration].instances[Reserved]
      : StatusColor[defaultSlotStatesConfiguration].instances[Available]
  }

  return {
    statusSlots,
    slotProperties,
    getStatusSlot,
    webStatusSlots
  }
}
