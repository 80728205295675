import { t, Trans } from '@lingui/macro'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { FunctionComponent, useContext, useState } from 'react'
import ChangeNotificationSettings from '../../forms/ChangeNotificationSettings/ChangeNotificationSettings'
import ChangePassword from '../../forms/ChangePassword/ChangePassword'
import UserInformation from '../../models/UserInformation'
import { useAPISecurity } from '../../security/APISecurityContext'
import { ThemeContext } from '../../themes/ThemeContext'
import ChangeEntityContainer from '../../forms/ChangeEntity/ChangeEntityContainer'
import SimpleDialog from './SimpleDialog'

type SetDrawerClose = () => void

export interface UserInformationProps {
  setDrawerClose: SetDrawerClose
  userInfo?: UserInformation
}

const drawerWidth = 400

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(0, 2, 0, 2)
    },
    avatarSize: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    card: {
      maxWidth: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textDecorationColor: theme.palette.primary.contrastText,
      textEmphasisColor: theme.palette.primary.contrastText,
      borderRadius: 0
    }
  })
)

const UserInformationCard: FunctionComponent<UserInformationProps> = props => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const classes = useStyles()
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openChangeEntity, setOpenChangeEntity] = useState(false)
  const [openChangeNotificationSettings, setOpenChangeNotificationSettings] = useState(false)
  const [closeSession, setCloseSession] = useState(false)
  const { darkMode, setDarkMode } = useContext(ThemeContext)
  const { setIsAuthenticated, setSecurityTokens, setIsValidAuth } = useAPISecurity()

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const handleChangePassword = () => {
    setAnchorMenu(null)
    setOpenChangePassword(true)
  }

  const handleChangeEntity = () => {
    setAnchorMenu(null)
    setOpenChangeEntity(true)
  }

  const handleCloseSession = () => {
    setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
    setIsAuthenticated(false)
    setIsValidAuth(false)
  }

  const handleCloseSessionChange = () => {
    setCloseSession(!closeSession)
  }

  const handleChangeNotificationSettings = () => {
    setAnchorMenu(null)
    setOpenChangeNotificationSettings(true)
  }

  const handleUseDarkModeTheme = () => {
    setDarkMode(!darkMode)
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          classes={{
            subheader: classes.card
          }}
          avatar={
            <Avatar aria-label='recipe' src={props.userInfo && props.userInfo.avatar} className={classes.avatarSize} />
          }
          action={
            <div>
              {props.userInfo && (
                <IconButton aria-label='settings' id='open-menu-button' onClick={handleMenuClick}>
                  <MoreVertIcon className={classes.card} />
                </IconButton>
              )}
              {openMenu && (
                <Menu id='long-menu' anchorEl={anchorMenu} keepMounted={true} open={openMenu} onClose={handleMenuClose}>
                  <MenuItem id='close-session-menu-item' onClick={handleCloseSessionChange}>
                    <Trans>Cerrar sesión</Trans>
                  </MenuItem>
                  <MenuItem id='change-password-menu-item' onClick={handleChangePassword}>
                    <Trans>Cambiar contraseña</Trans>
                  </MenuItem>
                  <MenuItem id='change-entity-menu-item' onClick={handleChangeEntity}>
                    <Trans>Cambiar Entidad o Sede</Trans>
                  </MenuItem>
                  <MenuItem id='user-info-menu-item' onClick={handleChangeNotificationSettings}>
                    <Trans>Mis datos</Trans>
                  </MenuItem>
                  <MenuItem id='change-theme' onClick={handleUseDarkModeTheme}>
                    <Trans>Tema oscuro</Trans>
                    <Switch data-testid='change-theme-switch' checked={darkMode} onClick={handleUseDarkModeTheme} />
                  </MenuItem>
                </Menu>
              )}
              {openChangePassword && <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />}
              {openChangeEntity && <ChangeEntityContainer open={openChangeEntity} setOpen={setOpenChangeEntity} />}
              {openChangeNotificationSettings && (
                <ChangeNotificationSettings
                  open={openChangeNotificationSettings}
                  setOpen={setOpenChangeNotificationSettings}
                  currentEmail={props.userInfo?.email}
                />
              )}
              <IconButton data-testid='close-drawer-button' onClick={props.setDrawerClose}>
                <ChevronLeftIcon className={classes.card} />
              </IconButton>
            </div>
          }
          title={
            <Typography noWrap={false}>
              <b>{props.userInfo && props.userInfo.name}</b>
            </Typography>
          }
        />
        {props.userInfo && (
          <CardContent className={classes.cardContent}>
            <Typography noWrap={true} variant='subtitle1' gutterBottom={true}>
              {props.userInfo && props.userInfo.email}
            </Typography>

            {props.userInfo.company !== '' && (
              <Typography noWrap={true} variant='body1'>
                {props.userInfo.company}
              </Typography>
            )}
            {props.userInfo.branchOffice !== '' && (
              <Typography noWrap={true} variant='subtitle2' gutterBottom={true}>
                {props.userInfo.branchOffice}
              </Typography>
            )}
          </CardContent>
        )}
      </Card>
      {closeSession && (
        <SimpleDialog
          open={closeSession}
          title={t`Cerrar Sesión`}
          contentText={t`La sesión se cerrará de todas las pestañas o ventanas activas. ¿Desea continuar?`}
          dialogActions={
            <>
              <Button onClick={handleCloseSession} color='primary'>
                <Trans>Aceptar</Trans>
              </Button>
              <Button onClick={handleCloseSessionChange}>
                <Trans>Cancelar</Trans>
              </Button>
            </>
          }
          dialogClose={handleCloseSessionChange}
        />
      )}
    </>
  )
}

export default UserInformationCard
