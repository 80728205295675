import format from 'date-fns/format'
import React from 'react'
import { GetDataError } from 'restful-react'
import { APIError, Schedule, ScheduleInputUpdate, useGetProfessionalsSchedules, usePutSchedule } from '../../../api/api'
import { getMessageFromStatusCode } from '../Utils/useMessageFromStatusCode'

export interface ISlotsSchedules {
  id: number
  startDate: string | Date
  endDate: string | Date
  title: string
  room: string
  examens: string
  status?: number
  observation?: string
  enabled?: boolean
  isReserved?: boolean
  patientQuantity?: number
  extraSlotsCount?: number
  creationDate?: string | Date
  creatorName?: string
  activationDate?: string | Date
}
const patterDate = `yyyy-MM-dd'T'HH:mm:ssxxx`
export const defaultProfessionalId = 0
export const defaultScheduleId = 0
export const defaultDate = new Date(0)

export const useProfessionalSchedules = () => {
  const [id, setId] = React.useState<number>(defaultProfessionalId)
  const [messageError, setMessageError] = React.useState<string>('')
  const [startDateSchedule, setStartDateSchedule] = React.useState<Date>(new Date())
  const [endDateSchedule, setEndDateSchedule] = React.useState<Date>(new Date())
  const [listProfessionalsSchedules, setProfessionalsSchedules] = React.useState<Schedule[]>([])
  const [startCreatedDateSchedule, setStartCreatedDateSchedule] = React.useState<Date>(defaultDate)
  const [endCreatedDateSchedule, setEndCreatedDateSchedule] = React.useState<Date>(defaultDate)
  const [updateScheduleId, setUpdateScheduleId] = React.useState<number>(defaultScheduleId)
  const [updateSchedule, setUpdateSchedule] = React.useState<ScheduleInputUpdate | null>(null)
  const [messageErrorSchedule, setMessageErrorSchedule] = React.useState<string>('')

  const professionalsSchedulesApi = useGetProfessionalsSchedules({
    id,
    lazy: id === defaultProfessionalId,
    queryParams: {
      start_date: format(startDateSchedule, patterDate),
      end_date: format(endDateSchedule, patterDate)
    }
  })

  const putScheduleApi = usePutSchedule({ id: updateScheduleId })

  const error = professionalsSchedulesApi.error
  const loadingProfessionalsSchedules = professionalsSchedulesApi.loading || putScheduleApi.loading

  React.useEffect(() => {
    if (id !== defaultProfessionalId) {
      setMessageError('')
      setProfessionalsSchedules(professionalsSchedulesApi.data || [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalsSchedulesApi.data])

  React.useEffect(() => {
    if (!error) {
      setMessageError('')
      return
    }

    setMessageError(getMessageFromStatusCode(error.status) || '')
  }, [error])

  React.useEffect(() => {
    if (
      id !== defaultProfessionalId &&
      (startCreatedDateSchedule !== defaultDate || endCreatedDateSchedule !== defaultDate)
    ) {
      if (
        (startCreatedDateSchedule >= startDateSchedule && startCreatedDateSchedule <= endDateSchedule) ||
        (endCreatedDateSchedule >= startDateSchedule && endCreatedDateSchedule <= endDateSchedule)
      ) {
        void professionalsSchedulesApi.refetch()
      }
      setStartCreatedDateSchedule(defaultDate)
      setEndCreatedDateSchedule(defaultDate)
      setMessageError('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCreatedDateSchedule, endCreatedDateSchedule])

  const editExtraSlotsCount = (scheduleId: number, value?: number) => {
    setUpdateScheduleId(scheduleId)
    setUpdateSchedule({ extra_slots_count: value ?? 0 })
  }

  React.useEffect(() => {
    if (updateScheduleId > 0 && updateSchedule) {
      setMessageError('')
      setMessageErrorSchedule('')
      putScheduleApi
        .mutate(updateSchedule)
        .then(result => {
          const schedule = listProfessionalsSchedules.find(s => s.id === updateScheduleId)
          if (schedule) {
            schedule.extra_slots_count = result.extra_slots_count
          }
          setProfessionalsSchedules([...listProfessionalsSchedules])
          setMessageErrorSchedule('')
        })
        .catch((e: GetDataError<void | APIError>) => {
          setProfessionalsSchedules([...listProfessionalsSchedules])
          setMessageErrorSchedule(e.message)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScheduleId, updateSchedule])

  return {
    setId,
    setProfessionalsSchedules,
    loadingProfessionalsSchedules,
    error,
    messageError,
    setStartDateSchedule,
    setEndDateSchedule,
    listProfessionalsSchedules,
    setMessageError,
    setStartCreatedDateSchedule,
    setEndCreatedDateSchedule,
    editExtraSlotsCount,
    messageErrorSchedule
  }
}
