import React from 'react'
import { useAPISecurity } from '../../../security/APISecurityContext'
import ConfigTwoFactorAuth, { IConfigTwoFactorAuth } from './ConfigTwoFactorAuth/ConfigTwoFactorAuth'

const ContainerTwoFactorAuth: React.FC = () => {
  const { isUse2FA, isAuthenticated, config2FA } = useAPISecurity()

  const paramConfigTwoFactorAuth: IConfigTwoFactorAuth = {
    isUse2FA,
    isAuthenticated,
    config2FA
  }

  return <ConfigTwoFactorAuth {...paramConfigTwoFactorAuth} />
}

export default ContainerTwoFactorAuth
