import { Grid, Typography } from '@material-ui/core'
import { t } from '@lingui/macro'
import { makeStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import React from 'react'
import { Link } from 'react-router-dom'
import { menuRootPath } from './MainMenu'

export const noMatch = '*'

const useLayoutStyles = makeStyles(theme => ({
  container: {
    marginTop: '20%',
    textAlign: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: '10%'
    }
  },
  icon: {
    color: theme.palette.error.main,
    fontSize: '5.5rem'
  },
  titles: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    '@media (min-width:670px)': {
      fontSize: '2.5rem'
    }
  }
}))

const NotFound: React.FunctionComponent = () => {
  const classes = useLayoutStyles()
  return (
    <>
      <div className={classes.container}>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <ErrorOutlineIcon className={classes.icon} />
            <Typography variant='h4' component='h4' className={classes.titles}>
              {t`SITIO NO ENCONTRADO`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' gutterBottom>
              {t`El sitio no existe, o no tiene los permisos necesarios.`}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <Link to={`${menuRootPath}`}>{t`Ir a la página principal.`}</Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
export default NotFound
