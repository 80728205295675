import JSEncrypt from 'jsencrypt'

const utf16LeBytes = (str: string): Iterable<number> => {
  return {
    *[Symbol.iterator]() {
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i)
        // eslint-disable-next-line no-bitwise
        const leastSignificantByte = charCode & 0xff
        yield leastSignificantByte

        // eslint-disable-next-line no-bitwise
        const mostSignificantByte = (charCode >> 8) & 0xff
        yield mostSignificantByte
      }
    }
  }
}

export const encryptObjectWithPublicKey = (object: Record<string, any>, publicKey: string) => {
  try {
    const jsonString = JSON.stringify(object)
    return encryptWithPublicKey(jsonString, publicKey)
  } catch (error) {
    return null
  }
}

export const encryptWithPublicKey = (text: string, publicKey: string) => {
  try {
    const crypt = new JSEncrypt()
    crypt.setKey(publicKey)
    return crypt.encrypt(text).toString()
  } catch (error) {
    return null
  }
}

export const b64EncodeUnicode = (str: string): string => {
  const utf16Bytes: number[] = Array.from(utf16LeBytes(str))
  return btoa(String.fromCharCode(...utf16Bytes))
}
