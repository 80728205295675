import { es, enUS } from 'date-fns/locale'
import { i18n } from '@lingui/core'

const defaultLanguageCode = 'es'
const languageCodeStartIndex = 0
const languageCodeEndIndex = 2

export const getLocale = () => {
  return window.navigator.language.substring(languageCodeStartIndex, languageCodeEndIndex) === defaultLanguageCode
    ? es
    : undefined
}

export const getLocaleLingui = () => {
  return i18n.locale === defaultLanguageCode ? es : enUS
}
