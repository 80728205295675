import { useEffect } from 'react'
import useLocalStorage from './useLocalStorage'

const defaultPage = '/Inicio.aspx'
const useNewMenuLocalStorageKey = 'useNewMenu'

function useNewMenu(): [boolean, (value: boolean) => void] {
  const [newMenu, setNewMenu] = useLocalStorage(useNewMenuLocalStorageKey, true)

  useEffect(() => {
    if (!newMenu) {
      window.location.href = defaultPage
    }
  }, [newMenu])

  return [newMenu, setNewMenu]
}

export default useNewMenu
