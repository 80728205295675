import React from 'react'
import { useGetSchedulesProfessionals } from '../../../api/api'

export const useProfessional = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const listProfessionalsApi = useGetSchedulesProfessionals({ lazy: true })

  if (!loading) {
    void listProfessionalsApi.refetch()
    setLoading(true)
  }
  const error = listProfessionalsApi.error
  const listProfessionals = (listProfessionalsApi.data ? listProfessionalsApi.data : []).sort((a, b) => {
    if (a.full_name > b.full_name) {
      return 1
    }

    if (a.full_name < b.full_name) {
      return -1
    }

    return 0
  })

  const loadingProfessionals = listProfessionalsApi.loading

  return { listProfessionals, loadingProfessionals, error }
}
