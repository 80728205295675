import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React, { Fragment, useState } from 'react'
import Notification from '../../models/Notification'

export interface NotificationsSnackbarProps {
  notifications: Notification[]
}

const NotificationsSnackbar: React.SFC<NotificationsSnackbarProps> = ({ notifications }) => {
  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(notifications.length > 0)

  const closeSnackbar = (_event: React.SyntheticEvent<any>, reason: string) => {
    // The user should see the notification and close it with the button
    if (reason === 'clickaway') {
      return
    }
  }

  const closeMuiAlert = () => {
    setIsOpen(false)
    if (index + 1 < notifications.length) {
      setTimeout(() => {
        setIndex(index + 1)
        setIsOpen(true)
      }, 800)
    }
  }

  return (
    <Fragment>
      {notifications[index] && (
        <Snackbar id='notifications-snackbar' open={isOpen} onClose={closeSnackbar}>
          <MuiAlert elevation={6} variant='filled' onClose={closeMuiAlert} severity='error'>
            <strong>{notifications[index].title}</strong>
            <br />
            <span>{notifications[index].helpText}</span>
          </MuiAlert>
        </Snackbar>
      )}
    </Fragment>
  )
}

export default NotificationsSnackbar
