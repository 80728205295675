import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import { IconButton, ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import { Trans } from '@lingui/macro'
import CheckIcon from '@material-ui/icons/Check'
import BlockIcon from '@material-ui/icons/Block'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { ISlotProperties, useSlotProperties } from './SlotProperties'

export const enabledOption = 'enabledChange'
export const reservedOption = 'reservedChange'
interface ISlotHeaderProps {
  appointmentData: AppointmentModel
  setSlotOptionSelected: (value: string) => void
  setOpenAddObservation: (value: boolean) => void
}

const SlotHeader: React.FC<ISlotHeaderProps> = ({ appointmentData, setSlotOptionSelected, setOpenAddObservation }) => {
  const { slotProperties } = useSlotProperties() as { slotProperties: ISlotProperties }
  const enabledChanged = () => {
    setSlotOptionSelected(enabledOption)
    setOpenAddObservation(true)
  }

  const isReservedChanged = () => {
    setSlotOptionSelected(reservedOption)
    setOpenAddObservation(true)
  }

  return (
    <>
      {(appointmentData.status === slotProperties.Disabled || appointmentData.status === slotProperties.Available) && (
        <MenuItem onClick={enabledChanged}>
          <ListItemIcon>
            <IconButton aria-label='enabled-disabled'>
              {appointmentData.enabled ? <BlockIcon /> : <CheckIcon />}
            </IconButton>
          </ListItemIcon>
          <Typography variant='inherit' noWrap={true}>
            {appointmentData.enabled ? <Trans>Deshabilitar</Trans> : <Trans>Habilitar</Trans>}
          </Typography>
        </MenuItem>
      )}
      {''}
      {(appointmentData.status === slotProperties.Reserved || appointmentData.status === slotProperties.Available) && (
        <MenuItem onClick={isReservedChanged}>
          <ListItemIcon>
            <IconButton aria-label='lock-free'>
              {appointmentData.isReserved ? <LockOpenIcon /> : <LockIcon />}
            </IconButton>
          </ListItemIcon>
          <Typography variant='inherit' noWrap={true}>
            {appointmentData.isReserved ? <Trans>Liberar</Trans> : <Trans>Reservar</Trans>}
          </Typography>
        </MenuItem>
      )}
    </>
  )
}

export default SlotHeader
