import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isValid, parse } from 'date-fns'
import format from 'date-fns/format'
import { ScheduleInput } from '../../../api/api'
import FormatDate from '../Utils/FormatDate'

export interface UseScheduleRangesProps {
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
}

const useScheduleRange = ({ newSchedule, setNewSchedule }: UseScheduleRangesProps) => {
  const { FormatOnlyHourRequests, FormatOnlyDateRequests } = FormatDate()
  const defaultDate = new Date(0)
  const startHourField = 'startHour'
  const endHourField = 'endHour'
  const startDateField = 'startDate'
  const endDateField = 'endDate'

  const handleHourPicker = (field: string, index: number, date: Date | null) => {
    const startEndHour = date ? format(date, FormatOnlyHourRequests) : ''

    if (field === startHourField) {
      newSchedule.hours[index] = { ...newSchedule.hours[index], start_hour: startEndHour }
      setNewSchedule({ ...newSchedule })
    }
    if (field === endHourField) {
      newSchedule.hours[index] = { ...newSchedule.hours[index], end_hour: startEndHour }
      setNewSchedule({ ...newSchedule })
    }
  }

  const handleDatePicker = (field: string, date: MaterialUiPickersDate) => {
    if (isValid(date)) {
      const startEndDate = date ? format(date, FormatOnlyDateRequests) : ''

      if (field === startDateField) {
        setNewSchedule({ ...newSchedule, start_date: startEndDate })
      }
      if (field === endDateField) {
        setNewSchedule({ ...newSchedule, end_date: startEndDate })
      }
    }
  }

  const parseDate = (value: string, dateFormat: string) => {
    const valueDate = parse(value, dateFormat, defaultDate)
    if (value === '' || !isValid(valueDate)) {
      return null
    }

    return valueDate
  }

  const parseHourValue = (value: string) => {
    return parseDate(value, FormatOnlyHourRequests)
  }

  const parseDateValue = (value: string) => {
    return parseDate(value, FormatOnlyDateRequests)
  }

  return {
    handleHourPicker,
    handleDatePicker,
    parseHourValue,
    parseDateValue,
    startHourField,
    endHourField,
    startDateField,
    endDateField
  }
}

export default useScheduleRange
