import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'
import { Trans } from '@lingui/macro'
import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SettingsIcon from '@material-ui/icons/Settings'
import React, { useState } from 'react'
import AddSlotObservation, { withoutObservation } from '../scheduleMaintenance/AddSlotObservation'
import { ScheduleSlotObservation } from '../../../api/api'
import { ISlotProperties, switchViewType, useSlotProperties, viewSchedule, viewSlot } from './SlotProperties'
import SlotHeader, { enabledOption, reservedOption } from './SlotHeader'

const emptyOption = ''

const useStyles = makeStyles(theme => ({
  SlotActionIcons: {
    position: 'relative',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    minHeight: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  line: {
    backgroundColor: theme.palette.action.disabledBackground,
    height: theme.spacing(3.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1.25),
    width: theme.spacing(0.12)
  }
}))

export interface IScheduleHeaderProps extends AppointmentTooltip.HeaderProps {
  editSlotIsReserved: (slotIdEdit: number, observationId: number) => void
  editSlotEnabled: (slotIdEdit: number, observationId: number) => void
  makeScheduleMaintenance: () => void
  switchView: switchViewType
  setWebAppointmentSettings: (value: boolean) => void
  showWebAppointmentOption: boolean
  currentSchedule: (value: number) => void
  listObservations: ScheduleSlotObservation[]
}

const ScheduleHeader: React.FC<IScheduleHeaderProps> = ({
  onDeleteButtonClick,
  onHide,
  appointmentData,
  editSlotIsReserved,
  editSlotEnabled,
  makeScheduleMaintenance,
  switchView,
  setWebAppointmentSettings,
  showWebAppointmentOption,
  currentSchedule,
  listObservations
}) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const classes = useStyles()
  const { slotProperties } = useSlotProperties() as { slotProperties: ISlotProperties }
  const [openAddObservation, setOpenAddObservation] = React.useState(false)
  const [slotOptionSelected, setSlotOptionSelected] = React.useState<string>(emptyOption)
  const [observationId, setObservationId] = React.useState<number>(withoutObservation().id)
  const [isloading, setIsloading] = React.useState<boolean>(false)

  const hideAppointmentTooltip = () => {
    if (onHide) {
      onHide()
    }
  }

  const isReservedChanged = () => {
    editSlotIsReserved(Number(appointmentData?.id), observationId)
  }

  const enabledChanged = () => {
    editSlotEnabled(Number(appointmentData?.id), observationId)
  }

  const openScheduleMaintenance = () => {
    setWebAppointmentSettings(false)
    makeScheduleMaintenance()
    hideAppointmentTooltip()
  }

  const openScheduleMaintenanceTeleconsultation = () => {
    setWebAppointmentSettings(true)
    makeScheduleMaintenance()
    hideAppointmentTooltip()
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  React.useEffect(() => {
    if (appointmentData?.id && switchView === viewSchedule) {
      currentSchedule(Number(appointmentData?.id))
    }
  }, [appointmentData])

  const addSlotObservation = (selectObservationId: number) => {
    setObservationId(selectObservationId)
  }

  React.useEffect(() => {
    if (isloading) {
      changeStatus()
    }
  }, [isloading])

  const handleCancel = () => {
    setOpenAddObservation(false)
  }

  const handleConfirm = () => {
    setOpenAddObservation(false)
    setIsloading(true)
  }

  const changeStatus = () => {
    if (slotOptionSelected === enabledOption) {
      enabledChanged()
    }

    if (slotOptionSelected === reservedOption) {
      isReservedChanged()
    }

    setIsloading(false)
    hideAppointmentTooltip()
  }

  return (
    <>
      <AddSlotObservation
        observations={listObservations}
        open={openAddObservation}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        addObservation={addSlotObservation}
      />
      {appointmentData && (
        <>
          <Menu id='menu-more' anchorEl={anchorMenu} keepMounted={true} open={openMenu} onClose={handleMenuClose}>
            {switchView === viewSchedule && (
              <>
                <MenuItem onClick={openScheduleMaintenance}>
                  <ListItemIcon>
                    <IconButton aria-label='lock-free'>
                      <SettingsIcon />
                    </IconButton>
                  </ListItemIcon>
                  <Typography variant='inherit' noWrap={true}>
                    <Trans>Mantenimiento</Trans>
                  </Typography>
                </MenuItem>
                {showWebAppointmentOption && (
                  <MenuItem onClick={openScheduleMaintenanceTeleconsultation}>
                    <ListItemIcon>
                      <IconButton aria-label='lock-free'>
                        <AddToQueueIcon />
                      </IconButton>
                    </ListItemIcon>
                    <Typography variant='inherit' noWrap={true}>
                      <Trans>Cita web</Trans>
                    </Typography>
                  </MenuItem>
                )}
              </>
            )}
            {switchView === viewSlot && (
              <SlotHeader {...{ appointmentData, setSlotOptionSelected, setOpenAddObservation }} />
            )}
            {new Date(appointmentData.startDate).getTime() > Date.now() && (
              <MenuItem onClick={onDeleteButtonClick}>
                <ListItemIcon>
                  <IconButton aria-label='lock-free'>
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
                <Typography variant='inherit' noWrap={true}>
                  <Trans>Eliminar</Trans>
                </Typography>
              </MenuItem>
            )}
          </Menu>
          <div className={classes.SlotActionIcons}>
            {new Date(appointmentData.endDate).getTime() > Date.now() &&
              appointmentData.status !== slotProperties.Assigned && (
                <IconButton
                  id='open-menu-more'
                  data-testid='test-open-menu-more'
                  aria-label='more'
                  onClick={handleMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            <div className={classes.line} />
            <IconButton aria-label='close' data-testid='test-close' onClick={onHide}>
              <CloseIcon />
            </IconButton>
          </div>
        </>
      )}
    </>
  )
}

export default ScheduleHeader
