import { t } from '@lingui/macro'
import { InputAdornment, List, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import React, { FunctionComponent, useEffect, useState } from 'react'
import useDebounce from '../../libs/useDebounce'
import MenuItem from '../../models/MenuItem'
import { useMenuItems } from './contexts/useMenuItems'
import MainMenuItem from './MainMenuItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    find: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1, 1, 1, 1)
    },
    input: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flex: 1
    }
  })
)

const NormalizationFormDecomposition = 'NFD'
const DiatricalCharacters = /[\u0300-\u036f]/g
const ArrowDownEvent = 'ArrowDown'
const ArrowUpEvent = 'ArrowUp'

export const removeAccentsAndConvertToLowerCase = (titleSearch: string) => {
  return titleSearch
    .normalize(NormalizationFormDecomposition)
    .replace(DiatricalCharacters, '')
    .toLocaleLowerCase()
}

const validateFilterInMenuItemTitle = (filter: string, item: MenuItem) => {
  return item.url && item.url !== '' && removeAccentsAndConvertToLowerCase(item.title).includes(filter)
}

export const MenuPanel: FunctionComponent = () => {
  const classes = useStyles()
  const [filterOption, setFilterOption] = useState('')
  const { menuItems } = useMenuItems()
  const [refs, setRefs] = useState(
    Array.from({ length: menuItems.length + 1 }).map(() => React.createRef<HTMLDivElement>())
  )
  const [filteredItems, setFilteredItems] = useState(menuItems)
  const debouncedfilterOption = useDebounce(filterOption, 500)

  useEffect(() => {
    setFilteredItems(getFilteredList(filterOption, menuItems))
    // eslint-disable-next-line
  }, [debouncedfilterOption, menuItems])

  useEffect(() => {
    setRefs(
      [...(Array(filteredItems.length + 1) as React.SetStateAction<React.RefObject<HTMLDivElement>[]>[])].map(() =>
        React.createRef()
      )
    )
  }, [filteredItems])

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOption(event.target.value)
  }

  const getFilteredList = (filter: string, items: MenuItem[]) => {
    if (!filter || filter === '') {
      return items
    }

    let filteredList: MenuItem[] = []
    const filterWithoutAccent = removeAccentsAndConvertToLowerCase(filter)
    items.forEach(item => {
      if (validateFilterInMenuItemTitle(filterWithoutAccent, item)) {
        filteredList.push(item)
      }

      if (item.items && item.items.length > 0) {
        const subItems = getFilteredList(filter, item.items)
        filteredList = filteredList.concat(subItems)
      }
    })

    return filteredList
  }

  const changeFocus = (event: string, index: number) => {
    if (!refs || (event !== ArrowDownEvent && event !== ArrowUpEvent)) {
      return
    }

    let node: HTMLDivElement | null = null

    if (event === ArrowDownEvent && index < refs.length - 1) {
      node = refs[index + 1].current
    }

    if (event === ArrowUpEvent && index !== 0) {
      node = refs[index - 1].current
    }

    if (node) {
      node.focus()
    }
  }

  const getMainMenuItems = () => {
    return (
      <>
        <div className={classes.find}>
          <TextField
            data-testid='menu-panel-filter'
            autoFocus={true}
            inputRef={refs[0]}
            className={classes.input}
            value={filterOption}
            placeholder={t`Buscar Opción`}
            inputProps={{ 'aria-label': 'buscar opción' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={onChangeFilter}
            onKeyDown={event => {
              changeFocus(event.key, 0)
            }}
          />
        </div>
        <List component='nav' disablePadding={true} data-testid='menu-panel-root-list'>
          {filteredItems.map((item, index) => (
            <MainMenuItem
              item={item}
              key={index}
              level={1}
              innerRef={refs[index + 1]}
              onKeyDown={event => {
                changeFocus(event.key, index + 1)
              }}
            />
          ))}
        </List>
      </>
    )
  }

  return <>{getMainMenuItems()}</>
}

export default MenuPanel
