import { Trans } from '@lingui/macro'
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React from 'react'
import SelectEntityForm, { SelectEntityFormProps } from '../Login/SelectEntityForm'

export interface ChangeEntityFormProps {
  open: boolean
  setOpen: (value: boolean) => void
  changeEntity: {
    response: string
    error: boolean
    loading: boolean
  }
  entitySelect: SelectEntityFormProps
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFormChange: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    dialogPaper: {
      minWidth: '450px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.error.contrastText
    }
  })
)

const ChangeEntityForm: React.FC<ChangeEntityFormProps> = props => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby='form-dialog-title'
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
      <Backdrop
        id='change-notifications-settings-backdrop-load'
        open={props.changeEntity.loading}
        className={classes.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <DialogTitle id='form-dialog-title'>
        <Trans>Cambiar Entidad - Sede</Trans>
      </DialogTitle>
      <DialogContent className={classes.rootFormChange}>
        <SelectEntityForm
          {...{
            isAuthenticated: props.entitySelect.isAuthenticated,
            postSelectCompany: props.entitySelect.postSelectCompany,
            entityServices: props.entitySelect.entityServices,
            handleCloseSession: handleClose
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ChangeEntityForm
