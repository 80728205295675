import React from 'react'
import AddTwoFactorAuthGoogle from './AddTwoFactorAuthGoogle'
import { useAddTwoFactorGoogle } from './useAddTwoFactorGoogle'

export interface IAddTwoFactorAuthGoogleContainer {
  setErrorMessage: (value: string) => void
}
const AddTwoFactorAuthGoogleContainer: React.FC<IAddTwoFactorAuthGoogleContainer> = ({ setErrorMessage }) => {
  const {
    handleOnChangeCode,
    handleValidate,
    handleOnChangeEmail,
    code,
    email,
    configGoogleAuth,
    isLoading,
    handValidateEmail,
    isMailValidated,
    handleCloseSession
  } = useAddTwoFactorGoogle(setErrorMessage)
  return (
    <AddTwoFactorAuthGoogle
      {...{
        handleOnChangeCode,
        handleValidate,
        handleOnChangeEmail,
        code,
        email,
        configGoogleAuth,
        isLoading,
        handValidateEmail,
        isMailValidated,
        handleCloseSession
      }}
    />
  )
}

export default AddTwoFactorAuthGoogleContainer
