import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'
import { Grid, makeStyles } from '@material-ui/core'
import { Room } from '@material-ui/icons'
import React from 'react'
import format from 'date-fns/format'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PeopleIcon from '@material-ui/icons/People'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { Trans } from '@lingui/macro'
import FormatDate from '../Utils/FormatDate'
import EditableNumberLabel from '../Utils/components/EditableNumberLabel'
import { switchViewType, viewSchedule } from './SlotProperties'
import { ISlotsSchedules } from './useProfessionalSchedules'

export interface IScheduleContentExtraInfoProps extends AppointmentTooltip.ContentProps {
  switchView: switchViewType
  editExtraSlotsCount: (id: number, value?: number) => void
  loadingProfessionalsSchedules: boolean
  messageErrorSchedule: string
  appointmentScheduleSlots?: ISlotsSchedules
}

const useStyles = makeStyles(theme => ({
  textTitle: {
    color: theme.palette.action.active,
    fontWeight: 'bold',
    marginLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(0.2)
  },
  gridContent: {
    marginTop: theme.spacing(2)
  },
  gridScheduleContent: {
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    padding: 0
  },
  textDate: {
    marginLeft: theme.spacing(2.5)
  },
  textCenter: {
    textAlign: 'center'
  },
  icon: {
    color: theme.palette.action.active
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const ScheduleContentExtraInfo: React.FC<IScheduleContentExtraInfoProps> = ({
  appointmentData,
  switchView,
  editExtraSlotsCount,
  loadingProfessionalsSchedules,
  messageErrorSchedule,
  appointmentScheduleSlots
}) => {
  const classes = useStyles()
  const { formatHourSchedule } = FormatDate()
  const [extraSlotsCount, setExtraSlotsCount] = React.useState<number>(appointmentScheduleSlots?.extraSlotsCount ?? 0)

  const validateExtraSlotsCount = (newValue?: number) => {
    return (newValue ?? 0) >= 0
  }

  const updateExtraSlotsCount = (newValue?: number) => {
    setExtraSlotsCount(newValue ?? 0)
    editExtraSlotsCount((appointmentData?.id ?? 0) as number, newValue)
  }

  React.useEffect(() => {
    if (!loadingProfessionalsSchedules && messageErrorSchedule !== '') {
      setExtraSlotsCount(appointmentScheduleSlots?.extraSlotsCount ?? 0)
    }
  }, [loadingProfessionalsSchedules, messageErrorSchedule])

  return (
    <>
      <Grid container alignItems='center' className={classes.gridContent}>
        <Grid container item alignItems='center' xs={6}>
          <Grid item xs={4} className={classes.textCenter}>
            <AccessTimeIcon className={classes.icon} />
          </Grid>
          <Grid item xs={8}>
            <span>{`${format(new Date(appointmentData?.startDate || 0), formatHourSchedule)} - ${format(
              new Date(appointmentData?.endDate || 0),
              formatHourSchedule
            )}`}</span>
          </Grid>
        </Grid>
        {switchView === viewSchedule && (
          <Grid container item alignItems='center' xs={6}>
            <Grid item xs={4} className={classes.textCenter}>
              <PeopleIcon className={classes.icon} />
            </Grid>
            <Grid item xs={8}>
              <span>
                <Trans>Cantidad</Trans>: {appointmentData?.patientQuantity}
              </span>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container alignItems='center'>
        <Grid container item alignItems='center'>
          <Grid item xs={2} className={classes.textCenter}>
            <Room className={classes.icon} />
          </Grid>
          <Grid item xs={10}>
            <span>{appointmentData?.room}</span>
          </Grid>
        </Grid>
      </Grid>
      {switchView === viewSchedule && (
        <Grid container alignItems='center'>
          <Grid item xs={2} className={classes.textCenter}>
            <ControlPointIcon className={classes.icon} />
          </Grid>
          <Grid item xs={3}>
            <span>
              <Trans>Citas extra</Trans>:{' '}
            </span>
          </Grid>
          <Grid item xs={3} data-testid='extra-slots-count'>
            <EditableNumberLabel
              onChange={updateExtraSlotsCount}
              value={extraSlotsCount}
              validateSaved={validateExtraSlotsCount}
              allowEdition={new Date(appointmentData?.endDate ?? 0).getTime() > Date.now()}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ScheduleContentExtraInfo
