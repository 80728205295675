import DateFnsUtils from '@date-io/date-fns'
import { t } from '@lingui/macro'
import { Backdrop, Button, CircularProgress, Divider, Grid, Hidden, makeStyles, Typography } from '@material-ui/core'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import { Alert } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import React from 'react'
import { useHistory } from 'react-router-dom'
import { Exam, Professional, Room, ScheduleInput } from '../../../api/api'
import { getLocale } from '../Utils/getLocale'
import ScheduleAvailability from './ScheduleAvailability'
import ScheduleDetails, { ScheduleDetailsProps } from './ScheduleDetails'
import useCreateSchedule from './useCreateSchedule'

const locale = getLocale()
const useStyles = makeStyles(theme => ({
  content: {
    width: '99%',
    backgroundColor: theme.palette.background.default
  },
  topGrid: {
    marginTop: theme.spacing(3)
  },
  agendaLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(0)
  },
  createSchedule: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(0)
  },
  actionButtons: {
    margin: theme.spacing(0, 0.5)
  },
  optionButtons: {
    paddingRight: theme.spacing(0, 0.5)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff'
  }
}))

export interface CreateScheduleProps {
  currentProfessional: Professional
  exams: Exam[]
  rooms: Room[]
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
  createSchedule: () => void
  isLoading: boolean
  messageError: string
  setMessageError: (value: string) => void
  isCreated: boolean
}

const CreateSchedule: React.FC<CreateScheduleProps> = ({
  currentProfessional,
  exams,
  rooms,
  newSchedule,
  setNewSchedule,
  createSchedule,
  isLoading,
  isCreated,
  messageError,
  setMessageError
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { validHours, validExams } = useCreateSchedule({ newSchedule, setNewSchedule, exams, setMessageError })

  const formIsValid = () => {
    return (
      newSchedule.room_id !== 0 &&
      newSchedule.slot_duration_in_min > 0 &&
      newSchedule.start_date.length > 0 &&
      newSchedule.end_date.length > 0 &&
      (newSchedule.extra_slots_count ?? 0) >= 0 &&
      (newSchedule.days_of_week?.length ?? 0) > 0 &&
      validHours() &&
      validExams()
    )
  }

  const scheduleDetailsProps: ScheduleDetailsProps = {
    exams,
    rooms,
    newSchedule,
    setNewSchedule
  }

  React.useEffect(() => {
    if (isCreated) {
      history.goBack()
    }
  })

  const clearErrorMessage = () => {
    setMessageError('')
  }

  return (
    <div className={classes.content}>
      <Backdrop id='backdrop-frame-content' className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <Grid container={true} spacing={2}>
          <Grid container={true} item={true} xs={12} className={classes.topGrid}>
            <Grid item={true} xs={6} md={9} className={classes.agendaLabel}>
              <PermContactCalendarIcon fontSize={'large'} />
              <Hidden smDown={true}>
                <Typography variant='h5' component='p' align={'center'} data-testid='create-schedule-title'>
                  {t`Nueva Agenda`}
                </Typography>
              </Hidden>
            </Grid>
            <Grid
              container={true}
              justifyContent='flex-end'
              item={true}
              xs={6}
              md={3}
              className={classes.optionButtons}
            >
              <Button
                className={classes.actionButtons}
                variant='contained'
                color='primary'
                data-testid='button-new-agenda'
                disabled={!formIsValid()}
                onClick={createSchedule}
              >
                {t`Crear`}
              </Button>
              <Button
                className={classes.actionButtons}
                variant='outlined'
                data-testid={'test-button-back'}
                onClick={() => history.goBack()}
              >
                {t`Cerrar`}
              </Button>
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            <Divider variant='middle' />
            {messageError !== '' && (
              <Alert onClose={clearErrorMessage} severity='error'>
                {messageError}
              </Alert>
            )}
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Divider variant='middle' />
        </Grid>
        <Grid container={true} spacing={3} className={classes.createSchedule}>
          <Grid container={true} item={true} xs={12} md={10} lg={7}>
            <Grid item={true} xs={1} />
            <Grid item={true} xs={4}>
              <Typography>{t`Médico`}</Typography>
            </Grid>
            <Grid item={true} xs={7}>
              <Typography>
                <strong>{currentProfessional.full_name}</strong>
              </Typography>
            </Grid>

            <Grid item={true} xs={1} />
            <Grid item={true} xs={10}>
              <ScheduleAvailability {...{ newSchedule, setNewSchedule }} />
            </Grid>
            <Grid item={true} xs={1} />

            <Grid item={true} xs={1} />
            <Grid item={true} xs={10}>
              <ScheduleDetails {...scheduleDetailsProps} />
            </Grid>
            <Grid item={true} xs={1} />
          </Grid>
          <Grid item={true} md={4} />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default CreateSchedule
