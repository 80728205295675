interface WindowConfig extends Window {
  ENV_KEY_PUBLIC_CAPTCHA?: string
  ENV_KEY_PUBLIC?: string
}

const windowConfig = window as WindowConfig
const KEY_PUBLIC_CAPTCHA = windowConfig.ENV_KEY_PUBLIC_CAPTCHA ?? ''
const KEY_PUBLIC = windowConfig.ENV_KEY_PUBLIC ?? ''

export { KEY_PUBLIC_CAPTCHA, KEY_PUBLIC }
