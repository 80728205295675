import React from 'react'
import { ScheduleSlotObservation } from '../../../api/api'
import AddSlotObservation from './AddSlotObservation'
import ScheduleMaintenanceConfirmDialog, { nameOptionDelete } from './ScheduleMaintenanceConfirmDialog'

interface IScheduleMaintenanceObservation {
  openConfirmDialog: boolean
  setOpenAddObservation(open: boolean): void
  addSlotObservation: (value: number) => void
  listObservations: ScheduleSlotObservation[]
  handleConfirm: () => void
  confirmationMessageContent: string
  slotOptionSelected: string
}
const ScheduleMaintenanceObservation: React.FC<IScheduleMaintenanceObservation> = ({
  openConfirmDialog,
  setOpenAddObservation,
  listObservations,
  addSlotObservation,
  handleConfirm,
  confirmationMessageContent,
  slotOptionSelected
}) => {
  const handleCancel = () => {
    setOpenAddObservation(false)
  }
  return (
    <>
      {slotOptionSelected === nameOptionDelete ? (
        <ScheduleMaintenanceConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          confirmationMessageContent={confirmationMessageContent}
          slotOptionSelected={slotOptionSelected}
        />
      ) : (
        <AddSlotObservation
          observations={listObservations}
          open={openConfirmDialog}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          addObservation={addSlotObservation}
        />
      )}
    </>
  )
}

export default ScheduleMaintenanceObservation
