import { t } from '@lingui/macro'
import { Exam, ScheduleInput } from '../../../api/api'
import useScheduleRange from './useScheduleRange'

export interface UseCreateScheduleProps {
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
  exams: Exam[]
  setMessageError: (value: string) => void
}

const maxQuantityOfGroupExams = 1
const maxQuantityOfHours = 2

const useCreateSchedule = ({ newSchedule, setNewSchedule, exams, setMessageError }: UseCreateScheduleProps) => {
  const scheduleRange = useScheduleRange({ newSchedule, setNewSchedule })

  const validHours = () => {
    for (const hour of newSchedule.hours) {
      const startHour = scheduleRange.parseHourValue(hour.start_hour)
      const startDate = new Date(`${newSchedule.start_date}${hour.start_hour}`)

      if (!startHour || !scheduleRange.parseHourValue(hour.end_hour)) {
        return false
      }
      if (startDate === null) {
        return false
      }

      if (startDate < new Date()) {
        setMessageError(t`No se puede crear la agenda con hora menor a la hora actual.`)
        return false
      }
    }

    return newSchedule.hours.length > 0 && newSchedule.hours.length < maxQuantityOfHours
  }

  const validExams = () => {
    let groupAppointment = false
    for (const examId of newSchedule.exam_ids) {
      const exist = exams.find(e => e.id === examId)
      if (exist) {
        if (exist.group_appointment) {
          groupAppointment = true
          break
        }
      } else {
        return false
      }
    }
    if (groupAppointment && newSchedule.exam_ids.length > maxQuantityOfGroupExams) {
      return false
    }

    if (groupAppointment && (newSchedule.quantity_patients ?? 0) <= 0) {
      return false
    }

    return newSchedule.exam_ids.length > 0
  }

  return {
    validHours,
    validExams
  }
}

export default useCreateSchedule
