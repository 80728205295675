import { useEffect, useState } from 'react'

export interface Fields {
  newEmail: string
  confirmNewEmail: string
}

export interface FieldsValidations {
  newEmailVal: string
  confirmNewEmailVal: string
  isValid: boolean
}

function useChangeNotificationSettingsVal(values: Fields) {
  const [validations, setValidations] = useState<FieldsValidations>({
    newEmailVal: '',
    confirmNewEmailVal: '',
    isValid: false
  })

  const isValidEmail = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  useEffect(() => {
    const currentVal: FieldsValidations = {
      newEmailVal: '',
      confirmNewEmailVal: '',
      isValid: false
    }

    if (values.newEmail !== '' && !isValidEmail(values.newEmail)) {
      currentVal.newEmailVal = 'Debe ingresar un correo valido'
      setValidations(currentVal)
      return
    }

    if (values.confirmNewEmail !== '' && !isValidEmail(values.confirmNewEmail)) {
      currentVal.confirmNewEmailVal = 'Debe ingresar un correo valido'
      setValidations(currentVal)
      return
    }

    if (values.newEmail !== '' && values.confirmNewEmail !== '' && values.newEmail !== values.confirmNewEmail) {
      currentVal.confirmNewEmailVal = 'El nuevo correo debe ser igual al correo confirmado'
      setValidations(currentVal)
      return
    }

    if (values.newEmail !== '' && values.confirmNewEmail !== '' && values.newEmail === values.confirmNewEmail) {
      currentVal.isValid = true
      setValidations(currentVal)
      return
    }

    setValidations(currentVal)
  }, [values])

  return validations
}

export default useChangeNotificationSettingsVal
