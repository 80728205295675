import React, { createContext, FC, ReactNode, useContext, useState } from 'react'
import MenuItem from '../../../models/MenuItem'

interface MenuItemsContextProps {
  menuItems: MenuItem[]
  setMenuItems(menuItems: MenuItem[]): void
  selectedMenuItem: MenuItem | null
  setSelectedMenuItem(selectedMenuItem: MenuItem | null): void
}

export const MenuItemsContext = createContext<MenuItemsContextProps>({
  menuItems: [],
  // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
  setMenuItems: () => {},
  selectedMenuItem: null,
  // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
  setSelectedMenuItem: () => {}
})

interface Props {
  children?: ReactNode
  menuItems?: MenuItem[]
  selectedMenuItem?: MenuItem
}

export const MenuItemsProvider: FC<Props> = props => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>(props.menuItems ? props.menuItems : [])
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(
    props.selectedMenuItem ? props.selectedMenuItem : null
  )

  const ctxValue: MenuItemsContextProps = {
    menuItems,
    setMenuItems,
    selectedMenuItem,
    setSelectedMenuItem
  }

  return <MenuItemsContext.Provider value={ctxValue}>{props.children}</MenuItemsContext.Provider>
}

export const useMenuItems = () => useContext(MenuItemsContext)
