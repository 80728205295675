import DateFnsUtils from '@date-io/date-fns'
import { t } from '@lingui/macro'
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Snackbar,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import MuiAlert from '@material-ui/lab/Alert'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { FunctionComponent, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Professional, Schedule as ISchedule, ScheduleSlotObservation, Slot } from '../../../api/api'
import { getLocale } from '../Utils/getLocale'
import Schedule, { IScheduleProps } from './Schedule'
import SearchProfessionals from './SearchProfessionals'

const locale = getLocale()

const useStyles = makeStyles(theme => ({
  content: {
    width: '99%'
  },
  fullWidth: {
    width: '99%'
  },
  paper: {
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(2)
  },
  topGrid: {
    marginTop: theme.spacing(3)
  },
  gridLeft: {
    marginLeft: theme.spacing(1)
  },
  newAgendaButton: {
    padding: 0,
    width: '95%'
  },
  agendaLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      alignItems: 'left'
    }
  },
  list: {
    width: '100%',
    maxWidth: 310,
    backgroundColor: theme.palette.background.paper
  },
  ListItemIcon: {
    minWidth: theme.spacing(5)
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 2,
    color: '#fff'
  }
}))

export interface URLMedicalSchedulerParam {
  professionalId?: string
}

export interface IMedicalSchedulerProps {
  currentProfessional: Professional | null
  listProfessionals: Professional[]
  setCurrentProfessional: (value: Professional | null) => void
  messageError: string
  loading: boolean
  currentDate: Date
  setCurrentDate: (value: Date) => void
  currentViewName: string
  setCurrentViewName: (value: string) => void
  setCurrentProfessionalFromId: (value: number) => void
  deleteSlot: (value: number) => void
  deleteSlots: (scheduleId: number, slotsIds: string) => void
  deleteSchedule: (value: number) => void
  editSlotIsReserved: (slotIdEdit: number, observationId: number) => void
  editSlotEnabled: (slotIdEdit: number, observationId: number) => void
  editSlotsEnabled: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotsIsReserved: (scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  editSlotsWebAppoinment: (professional: number, scheduleId: number, slotsEdit: Slot[], state: boolean) => void
  listProfessionalsSchedules: ISchedule[]
  setStartCreatedDateSchedule: (value: Date) => void
  setEndCreatedDateSchedule: (value: Date) => void
  getObservation: (observationId: number) => string | undefined
  listObservations: ScheduleSlotObservation[]
  editExtraSlotsCount: (id: number, value?: number) => void
  messageErrorSchedule: string
}

const scheduleConfigWindowTitle = t`Configuración de Agenda - iMedical`

const MedicalScheduler: FunctionComponent<IMedicalSchedulerProps> = props => {
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false)
  const history = useHistory()
  const [isLoading, setIsLoading] = React.useState(false)
  const { professionalId } = useParams<URLMedicalSchedulerParam>()
  const { url } = useRouteMatch()

  const openCreate = () => {
    history.push(`${url}create`)
  }

  useEffect(() => {
    document.title = scheduleConfigWindowTitle
  }, [])

  useEffect(() => {
    if (!isLoading && professionalId && props.listProfessionals.length > 0) {
      setIsLoading(true)
      props.setCurrentProfessionalFromId(Number(professionalId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, professionalId, props.listProfessionals])

  const paramsSchedule: IScheduleProps = {
    currentProfessional: props.currentProfessional,
    currentDate: props.currentDate,
    setCurrentDate: props.setCurrentDate,
    currentViewName: props.currentViewName,
    setCurrentViewName: props.setCurrentViewName,
    deleteSlot: props.deleteSlot,
    deleteSlots: props.deleteSlots,
    deleteSchedule: props.deleteSchedule,
    editSlotIsReserved: props.editSlotIsReserved,
    editSlotsIsReserved: props.editSlotsIsReserved,
    editSlotEnabled: props.editSlotEnabled,
    editSlotsEnabled: props.editSlotsEnabled,
    editSlotsWebAppoinment: props.editSlotsWebAppoinment,
    listProfessionalsSchedules: props.listProfessionalsSchedules,
    getObservation: props.getObservation,
    listObservations: props.listObservations,
    editExtraSlotsCount: props.editExtraSlotsCount,
    loadingProfessionalsSchedules: props.loading,
    messageErrorSchedule: props.messageErrorSchedule
  }

  const classes = useStyles()

  const closedSnackbar = () => {
    setOpenErrorMessage(false)
  }

  React.useEffect(() => {
    if (props.messageError !== '') {
      setOpenErrorMessage(true)
    }
  }, [props.messageError, setOpenErrorMessage])

  return (
    <div className={classes.content}>
      <Backdrop id='backdrop-frame-content' className={classes.backdrop} open={props.loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar
        open={openErrorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => closedSnackbar}
        data-testid={'test-error-messages'}
      >
        <MuiAlert elevation={6} variant='filled' onClose={closedSnackbar} severity='error'>
          <span>{props.messageError}</span>
        </MuiAlert>
      </Snackbar>
      <Grid container={true} spacing={2}>
        <Grid container={true} item={true} xs={12} className={classes.topGrid}>
          <Grid item={true} xs={1} md={3} className={classes.agendaLabel}>
            <PermContactCalendarIcon fontSize={'large'} />
            <Hidden smDown={true}>
              <Typography variant='h5' component='p' align={'center'}>
                {t`Agenda Médica`}
              </Typography>
            </Hidden>
          </Grid>
          <Grid item={true} xs={true}>
            <SearchProfessionals
              listProfessionals={props.listProfessionals}
              setCurrentProfessional={props.setCurrentProfessional}
              currentProfessional={props.currentProfessional}
            />
          </Grid>
          <Grid item={true} md={2}>
            <Button
              className={classes.newAgendaButton}
              variant='contained'
              color='primary'
              data-testid={'test-button-new-agenda'}
              onClick={openCreate}
              {...(props.currentProfessional ? { disabled: false } : { disabled: true })}
            >
              <AddIcon fontSize={'large'} />
              <Hidden smDown={true}> {t`Nueva Agenda`}</Hidden>
            </Button>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Divider variant='middle' />
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <Hidden smDown={true}>
            <Grid container={true} justifyContent='center' className={classes.gridLeft}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                  autoOk={true}
                  variant='static'
                  color={'secondary'}
                  value={props.currentDate}
                  onChange={value => (value !== null ? props.setCurrentDate(value) : props.setCurrentDate(new Date()))}
                  disableToolbar={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item={true} sm={12} md={9}>
          <Schedule {...paramsSchedule} />
        </Grid>
      </Grid>
    </div>
  )
}

export default MedicalScheduler
