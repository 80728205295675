import { t } from '@lingui/macro'
import { CardActions, Collapse, IconButton } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { grey } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import useDebounce from '../../libs/useDebounce'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderTop: '0.7em solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '0px',
      backgroundColor: theme.palette.type === 'light' ? grey[300] : theme.palette.background.paper,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(0)
    },
    cardDetails: {
      paddingBottom: '0px'
    },
    title: {
      paddingBottom: theme.spacing(4)
    },
    content: {
      paddingBottom: theme.spacing(0)
    },
    titleText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      height: '1.2em'
    },
    descriptionText: {
      overflow: 'hidden',
      wordBreak: 'break-word',
      display: '-webkit-box',
      lineClamp: 3,
      WebkitBoxOrient: 'vertical',
      minHeight: '4.5em'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    none: {}
  })
)

export interface FeaturedPostProps {
  id: number
  title: string
  content: string
  createdAt: Date
  creator: string
  imageURL: string
  imageTitle: string
}

const transitionTime = 250

const FeaturedPost: FunctionComponent<FeaturedPostProps> = props => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const debouncedExpanded = useDebounce(expanded, transitionTime)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const isExpanded = () => {
    if (expanded) {
      return true
    }

    return debouncedExpanded
  }

  return (
    <Grid item={true} xs={12} md={6}>
      <Card className={classes.card}>
        <CardContent id='cardContent' className={classes.cardDetails}>
          <div className={classes.title}>
            <Collapse in={expanded} collapsedSize={'2em'}>
              <Typography className={isExpanded() ? classes.none : classes.titleText} variant='h5' noWrap={!expanded}>
                {props.title}
              </Typography>
            </Collapse>
          </div>
          <div className={classes.content}>
            <Collapse in={expanded} collapsedSize={'6em'}>
              <Typography className={isExpanded() ? classes.none : classes.descriptionText} paragraph={true}>
                {props.content}
              </Typography>
            </Collapse>
          </div>
        </CardContent>
        <CardActions disableSpacing={true}>
          <IconButton
            color={'secondary'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={t`Mostrar más...`}
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default FeaturedPost
