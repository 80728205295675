import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import React from 'react'
import { Professional } from '../../../api/api'
import useLocalStorage from '../../../libs/useLocalStorage'
import { getMessageFromStatusCode } from '../Utils/useMessageFromStatusCode'
import { IMedicalSchedulerProps } from './MedicalScheduler'
import { useCurrentDate } from './useCurrentDate'
import { useProfessional } from './useProfessional'
import { defaultProfessionalId, useProfessionalSchedules } from './useProfessionalSchedules'
import { useSchedule } from './useSchedule'
import { useSlot } from './useSlot'
import { useObservations } from './useObservations'
import { useWebAppointment } from './useWebAppointment'

const viewNameLocalStorageKey = 'viewName'
const viewNameDay = 'Day'
const viewNameWeek = 'Week'
export const viewNameMonth = 'Month'

export const useMedicalScheduler = () => {
  const [currentProfessional, setCurrentProfessional] = React.useState<Professional | null>(null)
  const { listProfessionals, loadingProfessionals, error } = useProfessional()
  const { currentDate, setCurrentDate } = useCurrentDate()
  const [currentViewName, setCurrentViewName] = useLocalStorage(viewNameLocalStorageKey, viewNameMonth)
  const {
    setProfessionalsSchedules,
    setId: setIdProfessionalSchedules,
    loadingProfessionalsSchedules,
    setStartDateSchedule,
    setEndDateSchedule,
    messageError: messageProfessionalError,
    setMessageError: setMessageProfessionalError,
    listProfessionalsSchedules,
    setStartCreatedDateSchedule,
    setEndCreatedDateSchedule,
    editExtraSlotsCount,
    messageErrorSchedule
  } = useProfessionalSchedules()
  const { deleteSchedule, loadingSchedule } = useSchedule({
    listProfessionalsSchedules,
    setProfessionalsSchedules,
    setMessageError: setMessageProfessionalError
  })
  const {
    deleteSlot,
    deleteSlots,
    editSlotIsReserved,
    editSlotsIsReserved,
    editSlotEnabled,
    editSlotsEnabled,
    loadingSlot
  } = useSlot({
    listProfessionalsSchedules,
    setProfessionalsSchedules,
    setMessageError: setMessageProfessionalError
  })
  const { loadingWebAppoinment, editSlotsWebAppoinment } = useWebAppointment({
    listProfessionalsSchedules,
    setProfessionalsSchedules,
    setMessageError: setMessageProfessionalError
  })
  const { listObservations, getObservation, loadingObservations } = useObservations()
  const messageError = error?.status ? getMessageFromStatusCode(error?.status) : messageProfessionalError || ''

  const loading =
    loadingProfessionalsSchedules ||
    loadingProfessionals ||
    loadingSchedule ||
    loadingSlot ||
    loadingWebAppoinment ||
    loadingObservations

  React.useEffect(() => {
    if (currentProfessional !== null && currentProfessional !== undefined && currentProfessional.id !== undefined) {
      setIdProfessionalSchedules(currentProfessional?.id)
    } else {
      setIdProfessionalSchedules(defaultProfessionalId)
      setProfessionalsSchedules([])
    }
  }, [currentProfessional, setIdProfessionalSchedules, setProfessionalsSchedules])

  React.useEffect(() => {
    switch (currentViewName) {
      case viewNameDay:
        setStartDateSchedule(startOfDay(currentDate))
        setEndDateSchedule(endOfDay(currentDate))
        break
      case viewNameWeek:
        setStartDateSchedule(startOfWeek(currentDate))
        setEndDateSchedule(endOfWeek(currentDate))
        break
      case viewNameMonth:
        setStartDateSchedule(startOfMonth(currentDate))
        setEndDateSchedule(endOfMonth(currentDate))
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewName, currentDate])

  const setCurrentProfessionalFromId = (id: number) => {
    const professionalAux = listProfessionals.find((professional: Professional) => {
      return professional.id === id
    })
    if (professionalAux) {
      setCurrentProfessional(professionalAux)
    }
  }

  const paramsMedicalScheduler: IMedicalSchedulerProps = {
    currentProfessional,
    setCurrentProfessional,
    listProfessionals,
    messageError,
    loading,
    currentDate,
    setCurrentDate,
    currentViewName,
    setCurrentViewName,
    setCurrentProfessionalFromId,
    deleteSlot,
    deleteSlots,
    deleteSchedule,
    editSlotIsReserved,
    editSlotsIsReserved,
    editSlotEnabled,
    editSlotsEnabled,
    editSlotsWebAppoinment,
    listProfessionalsSchedules,
    setStartCreatedDateSchedule,
    setEndCreatedDateSchedule,
    getObservation,
    listObservations,
    editExtraSlotsCount,
    messageErrorSchedule
  }

  return { paramsMedicalScheduler }
}
