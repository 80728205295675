import React, { FunctionComponent } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { RestfulProvider } from 'restful-react'
import { MenuItemsProvider } from './components/MainMenu/contexts/useMenuItems'
import MainMenu from './components/MainMenu/MainMenu'
import SignInSide from './forms/Login/SignSide'
import ContainerTwoFactorAuth from './forms/Login/TwoFactorAuth/ContainerTwoFactorAuth'
import useNewMenu from './libs/useNewMenu'
import { useAPISecurity } from './security/APISecurityContext'
import getAccessTokenAuthorizationHeader from './security/getAccessTokenAuthorizationHeader'
import { withThemeProvider } from './themes/ThemeContext'
import ChangePasswordPublicFormContainer from './forms/ChangePasswordPublic/ChangePasswordPublicFormContainer'

const rootPath = '/'
const prefixPath = '/main'
const mainPath = `${prefixPath}/`
const menuPath = `${prefixPath}/menu`
const loginPath = `${prefixPath}/login`
const RecoveryPasswordPath = `${prefixPath}/password`
const beginTwoFactorAuthPath = `${prefixPath}/2fa`
const RedirectQueryParam = 'redirect'

interface RouterProps {
  isAuthenticated: boolean
  isUse2FA: boolean
}

// A custom hook that builds on useLocation to parse the query string.
function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const extractParamsFromRedirect = (redirectQueryParam: string) => {
  const queryStartIndex = redirectQueryParam.indexOf('?')
  return queryStartIndex !== -1 ? redirectQueryParam.substring(queryStartIndex + 1) : ''
}

interface StateType {
  from: string
  pathname: string
  search: string
}

const PathsRouter: FunctionComponent<RouterProps> = props => {
  const query = useQuery()
  const statePath = useLocation<StateType>()

  // The redirect query param is created for the K8s Ingress when a URL is visited directly
  // React Router does not know about this and we need to translate to the Router manager.
  const redirectQueryParam = query.get(RedirectQueryParam) || statePath.pathname + statePath.search

  const paramsString = extractParamsFromRedirect(redirectQueryParam)

  return (
    <Switch>
      {/* The root path "/" is only for development environment*/}
      <Route exact={true} path={rootPath}>
        <Redirect to={{ pathname: mainPath, state: { from: { rootPath } } }} />
      </Route>

      {/* The main path "/main" does not have a user option, 
          so it is redirected to "login" or "menu" option*/}
      <Route exact={true} path={mainPath}>
        {props.isAuthenticated ? (
          props.isUse2FA ? (
            <Redirect to={{ pathname: beginTwoFactorAuthPath, state: { from: mainPath } }} />
          ) : (
            <Redirect to={{ pathname: menuPath, state: { from: redirectQueryParam } }} />
          )
        ) : redirectQueryParam.includes('password?') ? (
          <Redirect to={{ pathname: RecoveryPasswordPath, search: paramsString }} />
        ) : (
          <Redirect to={{ pathname: loginPath, state: { from: mainPath } }} />
        )}
      </Route>
      <Route path={loginPath}>
        {props.isAuthenticated ? (
          props.isUse2FA ? (
            <Redirect to={{ pathname: beginTwoFactorAuthPath }} />
          ) : (
            <Redirect to={{ pathname: menuPath, state: { from: loginPath } }} />
          )
        ) : (
          <SignInSide />
        )}
      </Route>
      <Route path={beginTwoFactorAuthPath}>
        {props.isAuthenticated ? (
          props.isUse2FA ? (
            <ContainerTwoFactorAuth />
          ) : (
            <Redirect to={{ pathname: menuPath, state: { from: redirectQueryParam } }} />
          )
        ) : (
          <SignInSide />
        )}
      </Route>
      <Route path={menuPath}>
        {props.isAuthenticated ? (
          props.isUse2FA ? (
            <Redirect to={{ pathname: beginTwoFactorAuthPath }} />
          ) : (
            <MenuItemsProvider>
              <MainMenu />
            </MenuItemsProvider>
          )
        ) : (
          <Redirect to={{ pathname: loginPath, state: { from: redirectQueryParam } }} />
        )}
      </Route>
      <Route exact path={RecoveryPasswordPath}>
        <ChangePasswordPublicFormContainer />
      </Route>
    </Switch>
  )
}

interface AppPropsType {
  isLoggedIn?: boolean
  baseURL: string
}

function requestOptions() {
  return { headers: getAccessTokenAuthorizationHeader() }
}

const App: FunctionComponent<AppPropsType> = ({ isLoggedIn = false, baseURL }) => {
  const { isAuthenticated, isUse2FA } = useAPISecurity()

  useNewMenu()

  if (process.env.NODE_ENV === 'development' && baseURL === '') {
    baseURL = 'http://127.0.0.1:4010'
  }

  return (
    <RestfulProvider base={baseURL} requestOptions={requestOptions}>
      <PathsRouter isAuthenticated={isAuthenticated || isLoggedIn} isUse2FA={isUse2FA} />
    </RestfulProvider>
  )
}

export default withThemeProvider(App) as FunctionComponent<AppPropsType>
