import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { useState } from 'react'
import jwtDecode from 'jwt-decode'
import Notification from '../../models/Notification'
import { SecurityTokens, securityTokensLocalStorageKey } from '../../security/APISecurityContext'

export interface decodeToken {
  EntityId: string
  UserId: string
  LocationId: string
  BusinessGroupId: string
}

export const UseSignalRNotification = () => {
  const [NotificationSignalR, setNotificationSignalR] = useState<Notification>()

  const callNotification = () => {
    let hostAppAdminHubSignalR = 'https://application-admin.imedicalservices.dev/signalrhub'
    const hostPortalWeb = window.location.origin

    const hostDomainPortalWeb = 'imedicalcloud.net'

    if (hostPortalWeb.includes(hostDomainPortalWeb)) {
      hostAppAdminHubSignalR = 'https://application-admin.imedicalcloud.net/signalrhub'
    }

    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(hostAppAdminHubSignalR)
      .build()

    const localStoragetokens = window.sessionStorage.getItem(securityTokensLocalStorageKey)
    const securityTokens: SecurityTokens = localStoragetokens ? (JSON.parse(localStoragetokens) as SecurityTokens) : {}
    const accessToken: string = securityTokens ? securityTokens.accessToken || '' : ''

    if (accessToken !== '') {
      return
    }

    const decoded: decodeToken = jwtDecode(accessToken)

    connection
      .start()
      .then(() => {
        connection.on('notificationMessage', (urlMessage, entityId, userId, message, title) => {
          if (hostPortalWeb === urlMessage && decoded.EntityId === entityId && decoded.UserId === userId) {
            setNotificationSignalR({
              checked: false,
              helpText: message as string,
              title: title as string,
              type: 'Important',
              id: 0
            })
          }
        })
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  return { NotificationSignalR, callNotification }
}
