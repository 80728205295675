import { ChangeEvent, useEffect, useState } from 'react'
import { EntitiesModel, SelectEntityModel } from '../../api/api'
import { useGetUserCompanies } from './useGetUserCompanies'

const getLoginFromCompanies = (selectEntity: SelectEntityModel, companies: EntitiesModel[]): SelectEntityModel => {
  const newlogin = selectEntity

  if (companies.length === 1) {
    newlogin.entity_id = companies[0].id

    if (companies[0].locations.length === 1) {
      newlogin.location_id = companies[0].locations[0].id
    }
  }

  return newlogin
}

export const useSelectEntity = (setErrorMessage: (message: string) => void, IsValidAuth: boolean) => {
  const getCompaniesApi = useGetUserCompanies(setErrorMessage, IsValidAuth)
  const [selectedCompany, setSelectedCompany] = useState(null as EntitiesModel | null)
  const [selectEntityRequest, setSelectEntityRequest] = useState({ entity_id: 0, location_id: 0 } as SelectEntityModel)

  const loading = getCompaniesApi.loading

  useEffect(() => {
    setSelectedCompany(null)
    getCompaniesApi.setData([])
  }, [])

  useEffect(() => {
    setSelectedCompany(getCompaniesApi.data.length === 1 ? getCompaniesApi.data[0] : null)
    setSelectEntityRequest(getLoginFromCompanies(selectEntityRequest, getCompaniesApi.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompaniesApi.data])

  const handleChangeEntity = (event: ChangeEvent<{ value: unknown }>) => {
    const newlogin = { location_id: 0, entity_id: Number(event.target.value) }
    const selected = getCompaniesApi.data.find(e => e.id === Number(event.target.value))
    setSelectedCompany(selected ?? null)
    const newlocations = selected?.locations ?? []

    if (newlocations.length === 1) {
      newlogin.location_id = newlocations[0].id
    }

    setSelectEntityRequest(newlogin)
  }

  const handleChangeLocation = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectEntityRequest({ ...selectEntityRequest, location_id: Number(event.target.value) })
  }

  return {
    companiesData: getCompaniesApi.data,
    loading,
    selectedCompany,
    selectEntityRequest,
    handleChangeEntity,
    handleChangeLocation
  }
}
