import React, { FunctionComponent, useState } from 'react'
import useLocalStorage from '../../libs/useLocalStorage'
import useNewMenu from '../../libs/useNewMenu'
import { useAPISecurity } from '../../security/APISecurityContext'
import SignSideForm, { SignSideFormProps } from './SignSideForm'
import { useCheckActiveSession } from './useCheckActiveSession'
import { useLogin } from './useLogin'
import { useRecoveryPassword } from './useRecoveryPassword'
import { useSelectEntity } from './useSelectEntity'

const firstTimeLocalStorageKey = 'firstNewMenu'

export const useSignSide = (): SignSideFormProps => {
  const [errorMessage, setErrorMessage] = useState('')

  const login = useLogin(setErrorMessage)

  const { securityTokens, isAuthenticated, isValidAuth, handleCloseSession } = useAPISecurity()
  const entityServices = useSelectEntity(setErrorMessage, isValidAuth)
  const checkActiveSessionLoading = useCheckActiveSession(setErrorMessage)
  const recoveryPassword = useRecoveryPassword(setErrorMessage)
  const [newMenu, setNewMenu] = useNewMenu()
  const [firstTime, setFirstTime] = useLocalStorage(firstTimeLocalStorageKey, true)

  const haveSecurityTokens = securityTokens.accessToken !== '' && securityTokens.refreshToken !== ''

  return {
    errorMessage,
    setErrorMessage,
    loading: login.loading || checkActiveSessionLoading || recoveryPassword.loading,
    login: {
      postLogin: login.postLogin,
      postSelectCompany: login.postSelectCompany
    },
    entityServices: { ...entityServices },
    recoveryPassword,
    newMenu,
    setNewMenu,
    firstTime,
    setFirstTime,
    isAuthenticated,
    haveSecurityTokens,
    isValidAuth,
    handleCloseSession
  }
}

const SignSide: FunctionComponent = () => {
  const signSideFormProps = useSignSide()

  return <SignSideForm {...signSideFormProps} />
}

export default SignSide
