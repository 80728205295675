import { createStyles, makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import Scrollbars from 'react-scrollbars-custom'
import UserInformation from '../../models/UserInformation'
import UserInformationCard from './UserInformationCard'

type SetOpenFunction = (open: boolean) => void
type OnMouseLeave = () => void

export interface MainNavigationProps {
  open: boolean
  setOpen: SetOpenFunction
  onMouseLeave: OnMouseLeave
  userInfo?: UserInformation
}

const drawerWidth = 400
const swipeAreaWidth = 10

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerOpen: {},
    drawerClose: {}
  })
)

const MainNavigation: FunctionComponent<MainNavigationProps> = props => {
  const classes = useStyles()

  const handleDrawerClose = () => {
    props.setOpen(false)
  }

  return (
    <SwipeableDrawer
      data-testid='drawer-menu'
      swipeAreaWidth={swipeAreaWidth}
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      onOpen={() => props.setOpen(true)}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open
        })
      }}
    >
      <UserInformationCard userInfo={props.userInfo} setDrawerClose={handleDrawerClose} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '80%'
        }}
      >
        <Scrollbars>{props.children}</Scrollbars>
      </div>
    </SwipeableDrawer>
  )
}

export default MainNavigation
