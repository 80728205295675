import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import CreateScheduleContainer from '../CreateSchedule/CreateScheduleContainer'
import MedicalScheduler from './MedicalScheduler'
import { useMedicalScheduler } from './useMedicalScheduler'

const MedicalSchedulerContainer: React.FC = () => {
  const { paramsMedicalScheduler } = useMedicalScheduler()
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact={true} path={`${path}/:professionalId/create`}>
        {paramsMedicalScheduler.currentProfessional ? (
          <CreateScheduleContainer
            currentProfessional={paramsMedicalScheduler.currentProfessional}
            setStartCreatedDateSchedule={paramsMedicalScheduler.setStartCreatedDateSchedule}
            setEndCreatedDateSchedule={paramsMedicalScheduler.setEndCreatedDateSchedule}
          />
        ) : (
          <Redirect push={true} to={path} />
        )}
      </Route>
      <Route exact={true} path={`${path}/:professionalId?`}>
        <MedicalScheduler {...paramsMedicalScheduler} />
      </Route>
    </Switch>
  )
}

export default MedicalSchedulerContainer
