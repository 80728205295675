import { t } from '@lingui/macro'
import { Button } from '@material-ui/core'
import React from 'react'
import SimpleDialog from '../../../components/MainMenu/SimpleDialog'

export const nameOptionDelete = 'delete'

interface ScheduleMaintenanceConfirmDialogProps {
  openConfirmDialog: boolean
  confirmationMessageContent: string
  handleCancel: () => void
  handleConfirm: () => void
  slotOptionSelected: string
}

const ScheduleMaintenanceConfirmDialog: React.FC<ScheduleMaintenanceConfirmDialogProps> = ({
  openConfirmDialog,
  confirmationMessageContent,
  handleCancel,
  handleConfirm,
  slotOptionSelected
}: ScheduleMaintenanceConfirmDialogProps) => {
  const titleConfirmationMessage = t`Confirmar acción`

  return (
    <SimpleDialog
      open={openConfirmDialog}
      title={titleConfirmationMessage}
      contentText={confirmationMessageContent}
      dialogActions={
        <>
          <Button onClick={handleCancel} color='primary' data-testid={'test-cancel-action-slot'}>
            {t`Cancelar`}
          </Button>
          <Button onClick={handleConfirm} color='primary' autoFocus={true} data-testid={'test-accept-action-slot'}>
            {slotOptionSelected === nameOptionDelete ? t`Eliminar` : t`Aceptar`}
          </Button>
        </>
      }
      dialogClose={handleCancel}
    />
  )
}
export default ScheduleMaintenanceConfirmDialog
