import React from 'react'
import { Professional, ScheduleInput, ScheduleInputHours } from '../../../api/api'
import CreateSchedule, { CreateScheduleProps } from './CreateSchedule'
import { daysCode } from './ScheduleAvailability'
import useCreateScheduleApi from './useCreateScheduleApi'
import useExamsApi from './useExamsApi'
import useRoomsApi from './useRoomsApi'

export const defaultScheduleInput: ScheduleInput = {
  activation_date: '',
  end_date: '',
  start_date: '',
  exam_ids: [] as number[],
  room_id: 0,
  extra_slots_count: 0,
  slot_duration_in_min: 20,
  observations: '',
  quantity_patients: 1,
  web_appointment: false,
  professional_id: 0,
  hours: [] as ScheduleInputHours[],
  days_of_week: [daysCode.monday, daysCode.tuesday, daysCode.wednesday, daysCode.thursday, daysCode.friday]
}

export interface ICreateScheduleContainer {
  currentProfessional: Professional
  setStartCreatedDateSchedule: (date: Date) => void
  setEndCreatedDateSchedule: (date: Date) => void
}

const CreateScheduleContainer: React.FC<ICreateScheduleContainer> = ({
  currentProfessional,
  setStartCreatedDateSchedule,
  setEndCreatedDateSchedule
}) => {
  const [messageError, setMessageError] = React.useState<string>('')
  const [newSchedule, setNewSchedule] = React.useState<ScheduleInput>(defaultScheduleInput)
  const { examsList } = useExamsApi(currentProfessional.id, setMessageError)
  const { roomsList } = useRoomsApi(setMessageError)
  const { loading, createScheduleApi, isCreated } = useCreateScheduleApi(currentProfessional.id, setMessageError)
  const createSchedule = () => {
    createScheduleApi(newSchedule)
  }

  React.useEffect(() => {
    if (
      !loading &&
      newSchedule.start_date !== defaultScheduleInput.start_date &&
      newSchedule.end_date !== defaultScheduleInput.end_date
    ) {
      setStartCreatedDateSchedule(new Date(newSchedule.start_date))
      setEndCreatedDateSchedule(new Date(newSchedule.end_date))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, newSchedule.start_date, newSchedule.end_date])

  const props: CreateScheduleProps = {
    currentProfessional,
    exams: examsList,
    rooms: roomsList,
    newSchedule,
    setNewSchedule,
    createSchedule,
    isLoading: loading,
    messageError,
    setMessageError,
    isCreated
  }

  return (
    <>
      {' '}
      <CreateSchedule {...props} />
    </>
  )
}
export default CreateScheduleContainer
