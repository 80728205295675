import { t } from '@lingui/macro'
import React from 'react'
import { ScheduleInput, usePostProfessionalsSchedules } from '../../../api/api'

const useCreateScheduleApi = (id: number, setMessageError: (value: string) => void) => {
  const [isCreated, setIsCreated] = React.useState(false)

  const postScheduleApi = usePostProfessionalsSchedules({ id })

  const loading = postScheduleApi.loading
  const createScheduleApi = (newSchedule: ScheduleInput) => {
    postScheduleApi
      .mutate(newSchedule)
      .then(() => {
        setIsCreated(true)
      })
      .catch(() => {
        setMessageError(
          t`No se pudo crear la agenda. Puede que ya existan agendas creadas para este médico y este Consultorio en las fechas seleccionadas.`
        )
      })
  }

  return { loading, isCreated, createScheduleApi }
}

export default useCreateScheduleApi
