import { Trans } from '@lingui/macro'
import { Divider, Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import React, { FunctionComponent } from 'react'
import UserInformation from '../../models/UserInformation'
import Sidebar from './Sidebar'

function Copyright() {
  return (
    <Typography variant='body2' align='left'>
      {'Derechos reservados © '}
      <Link rel='noopener' target='_blank' color='inherit' href='https://www.imedicalservices.com/'>
        iMedicalServices {new Date().getFullYear()}
      </Link>{' '}
    </Typography>
  )
}

const social = [
  { name: 'LinkedIn', icon: LinkedInIcon, url: 'https://www.linkedin.com/company/imedical-services/' },
  { name: 'Twitter', icon: TwitterIcon, url: 'https://twitter.com/iMedicalServic' },
  { name: 'Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/iMedicalServicesLATAM/' },
  { name: 'Instagram', icon: InstagramIcon, url: 'https://www.instagram.com/imedical_services/' }
]

const useStyles = makeStyles(theme => ({
  mainSection: {
    paddingBottom: theme.spacing(4)
  },
  footer: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(6)
  },
  divider: {
    backgroundColor: theme.palette.primary.contrastText
  },
  dividerContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  logo: {
    width: '170px'
  }
}))

export interface FooterProps {
  description: string
  title: string
  userInformation?: UserInformation
}

const Footer: FunctionComponent<FooterProps> = props => {
  const classes = useStyles()
  const { description, title } = props

  return (
    <footer className={classes.footer}>
      <Container maxWidth='lg'>
        <Grid
          className={classes.mainSection}
          container={true}
          direction='row'
          justifyContent='space-around'
          alignItems='center'
        >
          <Grid item={true} xs={12} sm={6}>
            <img className={classes.logo} src='images/login/logo-transparente.png' alt={title} />
            <Typography variant='body2' align='left'>
              {description}
            </Typography>
            <Typography variant='body2' align='left'>
              <Trans>Centro de soporte</Trans>:{' '}
              <Link rel='noopener' target='_blank' color='inherit' href='mailto:soporte@imedicalservices.com'>
                soporte@imedicalservices.com{' '}
              </Link>
            </Typography>
            <Typography variant='body2' align='left'>
              <Trans>Teléfono</Trans>: 60 (1) 917 22 26
            </Typography>
            <Typography variant='body2' align='left'>
              Bogotá(Colombia)
            </Typography>
            <Typography variant='body2' align='left'>
              <Trans>Todos los horarios 7x24</Trans>
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={3}>
            <Sidebar social={social} />
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
