import { SvgIconProps } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles(theme => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200]
  },
  sidebarSection: {
    marginTop: theme.spacing(3)
  }
}))

type SocialMedia = {
  name: string
  icon: (props: SvgIconProps) => JSX.Element
  url: string
}

export interface SidebarProps {
  social: SocialMedia[]
}

const Sidebar: FunctionComponent<SidebarProps> = props => {
  const classes = useStyles()
  const { social } = props

  return (
    <>
      <Typography variant='body2' gutterBottom={true} className={classes.sidebarSection}>
        Siguenos en:
      </Typography>
      <Typography>
        {social.map(network => (
          <Link variant='body1' rel='noopener' target='_blank' color='inherit' href={network.url} key={network.name}>
            <network.icon />
          </Link>
        ))}
      </Typography>
    </>
  )
}

export default Sidebar
