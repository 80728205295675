import React from 'react'
import { useValidateTwoFactorGoogle } from './useValidateTwoFactorAuth'
import ValidateTwoFactorAuth, { IValidateTwoFactorAuth } from './ValidateTwoFactorAuth'

export interface IValidateTwoFactorAuthContainer {
  setErrorMessage: (value: string) => void
}

const ValidateTwoFactorAuthContainer: React.FC<IValidateTwoFactorAuthContainer> = ({ setErrorMessage }) => {
  const propsValidate: IValidateTwoFactorAuth = useValidateTwoFactorGoogle(setErrorMessage)

  return <ValidateTwoFactorAuth {...propsValidate} />
}

export default ValidateTwoFactorAuthContainer
