import { useEffect } from 'react'
import { useGetProfessionalExams } from '../../../api/api'
import { getMessageFromStatusCode } from '../Utils/useMessageFromStatusCode'

const useExamsApi = (id: number, setMessageError: (value: string) => void) => {
  const getProfessionalExamsApi = useGetProfessionalExams({
    id,
    lazy: true
  })

  const examsError = getProfessionalExamsApi.error
  const examsList = getProfessionalExamsApi.data ? getProfessionalExamsApi.data : []
  const isLoadingExams = getProfessionalExamsApi.loading

  useEffect(() => {
    if (!examsError) {
      setMessageError('')
      return
    }

    setMessageError(getMessageFromStatusCode(examsError.status) || '')
  }, [examsError])

  useEffect(() => {
    if (id > 0) {
      void getProfessionalExamsApi.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return { examsList, isLoadingExams, examsError }
}

export default useExamsApi
