import { t } from '@lingui/macro'
import { IconButton, InputBase, makeStyles, Paper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import React, { ChangeEvent, useEffect } from 'react'
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Professional } from '../../../api/api'
import { URLMedicalSchedulerParam } from './MedicalScheduler'

const useStyles = makeStyles(theme => ({
  searchPaper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(0.5)
  },
  iconButton: {
    padding: theme.spacing(0.5)
  },
  fullWidth: {
    width: '99%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: theme.spacing(0.5),
    width: '99%'
  }
}))

const slash = '/'

function addTrailingSlash(url: string) {
  url += url.endsWith(slash) ? '' : slash
  return url
}

export interface ISearchProfessionalsProps {
  listProfessionals: Professional[]
  currentProfessional: Professional | null
  setCurrentProfessional: (value: Professional | null) => void
  elevationOff?: boolean
}

const SearchProfessionals: React.FC<ISearchProfessionalsProps> = ({
  listProfessionals,
  setCurrentProfessional,
  currentProfessional,
  elevationOff
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { path } = useRouteMatch()
  const inputRef = React.useRef<HTMLInputElement>()
  const { professionalId } = useParams<URLMedicalSchedulerParam>()

  useEffect(() => {
    const invalidProfessionalId = !professionalId && isNaN(Number(professionalId))

    if (invalidProfessionalId && inputRef.current) {
      inputRef.current.focus()
    }
  }, [professionalId])

  const OnChange = (_: ChangeEvent<any>, newValue: Professional | null) => {
    setCurrentProfessional(newValue)

    let pathname = ''
    if (newValue) {
      pathname = addTrailingSlash(generatePath(`${path}`, { professionalId: newValue.id }))
    } else {
      pathname = addTrailingSlash(generatePath(`${path}`))
    }

    const searchParams = new URLSearchParams(window.location.search)
    history.replace({
      pathname,
      search: searchParams.toString()
    })
  }

  function getSearchProfessionalInputBase(params: AutocompleteRenderInputParams) {
    const { InputProps, ...rest } = params
    return (
      <InputBase
        ref={InputProps.ref}
        inputRef={input => {
          inputRef.current = input as HTMLInputElement
        }}
        endAdornment={InputProps.endAdornment}
        inputProps={rest.inputProps}
        className={classes.input}
        placeholder={t`Buscar Médico`}
        data-testid={'test-search-input-doctor'}
      />
    )
  }

  return (
    <Paper className={classes.searchPaper} {...(elevationOff ? { ...{ elevation: 0, variant: 'outlined' } } : '')}>
      <IconButton type='submit' className={classes.iconButton} aria-label='search'>
        <SearchIcon />
      </IconButton>
      <Autocomplete
        className={classes.fullWidth}
        value={currentProfessional}
        options={listProfessionals}
        onChange={OnChange}
        getOptionLabel={option => (option.full_name ? option.full_name : '')}
        renderInput={params => getSearchProfessionalInputBase(params)}
      />
    </Paper>
  )
}

export default SearchProfessionals
