import React from 'react'
import ChangeEntityForm from './ChangeEntityForm'
import { useChangeEntity } from './useChangeEntity'

export interface IChangeEntityContainer {
  open: boolean
  setOpen: (values: boolean) => void
}

const ChangeEntityContainer: React.FC<IChangeEntityContainer> = props => {
  const propsChangeEntity = useChangeEntity(props)

  return <ChangeEntityForm {...propsChangeEntity} />
}

export default ChangeEntityContainer
