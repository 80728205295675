import { t } from '@lingui/macro'
import { Grid, makeStyles, MenuItem, TextField } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import React from 'react'
import { IStatusSlot, useSlotProperties } from '../doctoragenda/SlotProperties'

const emptyFilter = ''

const useStyles = makeStyles(theme => ({
  filter: {
    '& .MuiOutlinedInput-input': {
      paddingBottom: theme.spacing(0.2),
      paddingTop: theme.spacing(0.2),
      minWidth: theme.spacing(12)
    }
  }
}))
interface IScheduleMaintenanceFilterProps {
  filterScheduleStatus: string | number
  setFilterScheduleStatus: (value: string | number) => void
  webAppointmentSettings: boolean
}

const ScheduleMaintenanceFilter: React.FC<IScheduleMaintenanceFilterProps> = ({
  filterScheduleStatus,
  setFilterScheduleStatus,
  webAppointmentSettings
}) => {
  const classes = useStyles()
  const { statusSlots, webStatusSlots } = useSlotProperties()
  const [filterOptions, setFilterOptions] = React.useState<IStatusSlot[]>([])

  React.useEffect(() => {
    if (webAppointmentSettings) {
      setFilterOptions(webStatusSlots)
    } else {
      setFilterOptions(statusSlots)
    }
  }, [webAppointmentSettings])

  return (
    <>
      <Grid item={true}>
        <FilterListIcon />
      </Grid>
      <Grid item={true}>
        <TextField
          id='outlined-size-small'
          defaultValue='Small'
          variant='outlined'
          select={true}
          value={filterScheduleStatus}
          onChange={event => setFilterScheduleStatus(event.target.value)}
          className={classes.filter}
          data-testid={`test-filter-schedule-status`}
        >
          <MenuItem value={emptyFilter}>{t`Ninguno`}</MenuItem>
          {filterOptions.map(status => {
            return (
              <MenuItem key={status.id} value={status.id} data-testid={`test-filter-item-${status.id}`}>
                {status.text}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
    </>
  )
}
export default ScheduleMaintenanceFilter
