import { t } from '@lingui/macro'
import React from 'react'
import { GetDataError } from 'restful-react'
import { APIError, Schedule, Slot, useDeleteSchedulesSlotsMultiple, usePutSchedulesSlots } from '../../../api/api'
import { getMessageFromStatusCode, statusCode } from '../Utils/useMessageFromStatusCode'
import { defaultScheduleId } from './useProfessionalSchedules'

const slotSeparator = ','

interface IUseSlot {
  listProfessionalsSchedules: Schedule[]
  setProfessionalsSchedules: (value: Schedule[]) => void
  setMessageError: (value: string) => void
}

export const useSlot = ({ listProfessionalsSchedules, setProfessionalsSchedules, setMessageError }: IUseSlot) => {
  const [slotsEdition, setslotsEdition] = React.useState<Slot[]>([])
  const [slotDeletionIds, setSlotDeletionIds] = React.useState<string>('')
  const [scheduleSlotDeletionId, setScheduleSlotDeletionId] = React.useState<number>(defaultScheduleId)
  const [scheduleSlotEditionId, setScheduleSlotEditionId] = React.useState<number>(defaultScheduleId)

  const deleteSlotApi = useDeleteSchedulesSlotsMultiple({
    id: scheduleSlotDeletionId,
    queryParams: {
      ids: slotDeletionIds
    }
  })

  const updateScheduleSlotApi = usePutSchedulesSlots({
    id: scheduleSlotEditionId
  })

  const loadingSlot = deleteSlotApi.loading || updateScheduleSlotApi.loading

  React.useEffect(() => {
    if (slotDeletionIds !== '' && scheduleSlotDeletionId !== defaultScheduleId) {
      deleteSlotApi
        .mutate()
        .then(() => {
          updateProfessionalSchedulesAfterSlotDelete(scheduleSlotDeletionId, slotDeletionIds)
          setSlotDeletionIds('')
          setScheduleSlotDeletionId(defaultScheduleId)
        })
        .catch((e: GetDataError<void | APIError>) => {
          setSlotDeletionIds('')
          setScheduleSlotDeletionId(defaultScheduleId)
          setMessageError(
            e.status === statusCode.badRequest
              ? t`No se puede eliminar este elemento.`
              : getMessageFromStatusCode(e.status) || ''
          )
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotDeletionIds, scheduleSlotDeletionId])

  React.useEffect(() => {
    if (slotsEdition.length !== 0 && scheduleSlotEditionId !== defaultScheduleId) {
      updateScheduleSlotApi
        .mutate(slotsEdition)
        .then((slotsResponse: Slot[]) => {
          const aux = listProfessionalsSchedules
          const schedule = aux.find(scheduleAux => scheduleAux.id === scheduleSlotEditionId)
          schedule?.slots.forEach(slot => {
            for (const slotResponse of slotsResponse) {
              if (slot.id === slotResponse.id) {
                slot.enabled = slotResponse.enabled
                slot.is_reserved = slotResponse.is_reserved
                break
              }
            }
          })
          setProfessionalsSchedules([...aux])
        })
        .catch((e: GetDataError<void | APIError>) => {
          setslotsEdition([])
          setScheduleSlotEditionId(defaultScheduleId)
          setMessageError(getMessageFromStatusCode((e.data as APIError).error_code || e.status))
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsEdition, scheduleSlotEditionId])

  const updateProfessionalSchedulesAfterSlotDelete = (scheduleId: number, slotIds: string) => {
    const listSlotIds = slotIds.split(slotSeparator)
    const listProfessionalsSchedulesAux = listProfessionalsSchedules
    listProfessionalsSchedulesAux.forEach(schedule => {
      if (schedule.id === scheduleId) {
        const slotsAux = schedule.slots.filter(slot => {
          return !listSlotIds.includes(slot.id.toString())
        })
        schedule.slots = slotsAux
      }
    })
    setProfessionalsSchedules([...listProfessionalsSchedulesAux])
  }

  const deleteSlot = (slotIdDelete: number) => {
    listProfessionalsSchedules.forEach(scheduleAux => {
      scheduleAux.slots.forEach(slotAux => {
        if (slotAux.id === slotIdDelete) {
          setScheduleSlotDeletionId(scheduleAux.id)
          setSlotDeletionIds(String(slotIdDelete))
        }
      })
    })
    setMessageError('')
  }

  const deleteSlots = (scheduleId: number, slotsIdDelete: string) => {
    setScheduleSlotDeletionId(scheduleId)
    setSlotDeletionIds(slotsIdDelete)
    setMessageError('')
  }

  const editSlotIsReserved = (slotIdEdit: number, observationId: number) => {
    listProfessionalsSchedules.forEach(scheduleAux => {
      scheduleAux.slots.forEach(slotAux => {
        if (slotAux.id === slotIdEdit) {
          slotAux.observation_id = observationId
          editSlotsIsReserved(scheduleAux.id, [slotAux], !slotAux.is_reserved)
        }
      })
    })
    setMessageError('')
  }
  const editSlotsIsReserved = (scheduleId: number, slotsEdit: Slot[], state: boolean) => {
    const editableSlots: Slot[] = JSON.parse(JSON.stringify(slotsEdit)) as Slot[]
    editableSlots.forEach(slotAux => {
      if (!slotAux.is_assigned) slotAux.is_reserved = state
    })
    setScheduleSlotEditionId(scheduleId)
    setslotsEdition(editableSlots)
    setMessageError('')
  }

  const editSlotEnabled = (slotIdEdit: number, observationId: number) => {
    listProfessionalsSchedules.forEach(scheduleAux => {
      scheduleAux.slots.forEach(slotAux => {
        if (slotAux.id === slotIdEdit) {
          slotAux.observation_id = observationId
          editSlotsEnabled(scheduleAux.id, [slotAux], !slotAux.enabled)
        }
      })
    })
  }

  const editSlotsEnabled = (scheduleId: number, slotsEdit: Slot[], state: boolean) => {
    const editableSlots: Slot[] = JSON.parse(JSON.stringify(slotsEdit)) as Slot[]
    editableSlots.forEach(slotAux => {
      if (!slotAux.is_assigned) slotAux.enabled = state
    })
    setScheduleSlotEditionId(scheduleId)
    setslotsEdition(editableSlots)
    setMessageError('')
  }

  return {
    deleteSlot,
    deleteSlots,
    editSlotIsReserved,
    editSlotsIsReserved,
    editSlotEnabled,
    editSlotsEnabled,
    loadingSlot,
    setProfessionalsSchedules,
    listProfessionalsSchedules
  }
}
