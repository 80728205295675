const FormatDate = () => {
  const FormatDateDisplay = 'dd/MM/yyyy hh:mm aa'
  const FormatDateDisplayMaintenance = 'dd MMMM yyyy'
  const FormatDateDisplaySchedule = 'EEEE, dd MMMM yyyy'
  const FormatDateHourDisplaySchedule = 'EEEE, dd MMMM yyyy H:mm'
  const FormatDateRequests = `yyyy-MM-dd'T'HH:mm:ss`
  const FormatOnlyDateRequests = `yyyy-MM-dd`
  const FormatOnlyHourRequests = `'T'HH:mm:ss`
  const formatDate = 'dd/MM/yyyy'
  const formatHour = 'hh:mm aa'
  const formatHourSchedule = 'H:mm'

  return {
    FormatDateDisplay,
    FormatDateDisplaySchedule,
    FormatDateRequests,
    FormatDateDisplayMaintenance,
    formatDate,
    formatHour,
    formatHourSchedule,
    FormatOnlyDateRequests,
    FormatOnlyHourRequests,
    FormatDateHourDisplaySchedule
  }
}
export default FormatDate
