import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { setSessionInformation } from './libs/cookies'
import { I18nIMedical } from './locales/I18nIMedical'
import { APISecurityProvider } from './security/APISecurityContext'

window.onfocus = () => {
  setSessionInformation()
}

let baseURL = '/'

if (process.env.NODE_ENV === 'development') {
  baseURL = ''
}

ReactDOM.render(
  <I18nIMedical>
    <BrowserRouter>
      <APISecurityProvider>
        <App baseURL={baseURL} />
      </APISecurityProvider>
    </BrowserRouter>
  </I18nIMedical>,
  document.getElementById('root')
)
