import { Box, Button, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'
import React, { ChangeEvent } from 'react'
import { Trans, t } from '@lingui/macro'
import red from '@material-ui/core/colors/red'
import { EntitiesModel, SelectEntityModel } from '../../api/api'
const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(1, 0.5, 1, 0),
    flex: 1
  },
  outlineInputLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  typography: {
    padding: theme.spacing(2)
  },
  newsFrame: {
    height: '100vh',
    width: '100%',
    border: 0
  },
  cancel: {
    margin: theme.spacing(1, 0, 1, 0.5),
    flex: 1,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: red[900]
    }
  }
}))

export interface SelectEntityFormProps {
  entityServices: {
    loading: boolean
    selectedCompany: EntitiesModel | null
    selectEntityRequest: SelectEntityModel
    companiesData: EntitiesModel[]
    handleChangeEntity: (event: ChangeEvent<{ value: unknown }>) => void
    handleChangeLocation: (event: ChangeEvent<{ value: unknown }>) => void
  }
  handleCloseSession: () => void
  isAuthenticated: boolean
  postSelectCompany: (value: SelectEntityModel) => void
}

const SelectEntityForm: React.FC<SelectEntityFormProps> = ({
  entityServices,
  postSelectCompany,
  handleCloseSession
}) => {
  const classes = useStyles()

  const handleLogin = () => {
    postSelectCompany(entityServices.selectEntityRequest)
  }

  return (
    <>
      <FormControl fullWidth={true} variant='outlined' style={{ marginTop: '12px' }}>
        <InputLabel className={classes.outlineInputLabel} id='select-entity-label' variant='outlined'>
          {entityServices.loading ? t`Buscando entidades...` : t`Entidad *`}
        </InputLabel>
        <Select
          variant='outlined'
          required={true}
          labelId='select-entity-label'
          id='select-entity'
          onChange={entityServices.handleChangeEntity}
          fullWidth={true}
          disabled={entityServices.companiesData.length === 0}
          value={entityServices.selectEntityRequest.entity_id === 0 ? '' : entityServices.selectEntityRequest.entity_id}
          inputProps={{ 'data-testid': 'select-entity-input' }}
        >
          {entityServices.companiesData.map(company => (
            <MenuItem key={company.id.toString()} value={company.id.toString()}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth={true} variant='outlined' style={{ marginTop: '25px' }}>
        <InputLabel className={classes.outlineInputLabel} variant='outlined' id='select-location-label'>
          <Trans>Sede *</Trans>
        </InputLabel>
        <Select
          required={true}
          fullWidth={true}
          labelId='select-location-label'
          id='select-location'
          onChange={entityServices.handleChangeLocation}
          disabled={(entityServices.selectedCompany?.locations?.length ?? 0) === 0}
          value={
            entityServices.selectEntityRequest.location_id === 0 ? '' : entityServices.selectEntityRequest.location_id
          }
          variant='outlined'
        >
          {entityServices.selectedCompany?.locations.map(branchOffice => (
            <MenuItem key={branchOffice.id.toString()} value={branchOffice.id.toString()}>
              {branchOffice.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <Button
          id='enter-button'
          onClick={handleLogin}
          variant='contained'
          color='primary'
          className={classes.submit}
          disabled={
            entityServices.selectEntityRequest.entity_id === 0 || entityServices.selectEntityRequest.location_id === 0
          }
        >
          <Trans>Entrar</Trans>
        </Button>
        <Button
          id='cancel-button'
          data-testid='button-cancel'
          onClick={handleCloseSession}
          variant='contained'
          color='secondary'
          className={classes.cancel}
        >
          <Trans>Cancelar</Trans>
        </Button>
      </Box>
    </>
  )
}

export default SelectEntityForm
