import {
  Checkbox,
  Chip,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import { format } from 'date-fns'
import React from 'react'
import { Slot } from '../../../api/api'
import { defaultColor, useSlotProperties } from '../doctoragenda/SlotProperties'
import FormatDate from '../Utils/FormatDate'

const { formatHour } = FormatDate()
const millisecondsForMinute = 60000
const notFound = -1

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingBottom: theme.spacing(0.1),
    paddingTop: theme.spacing(0.1)
  },
  list: {
    width: '100%',
    minHeight: theme.spacing(30),
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(0.2),
    marginTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingTop: theme.spacing(0.2)
  },
  itemList: {
    padding: theme.spacing(0)
  },
  chip: {
    minWidth: theme.spacing(12),
    color: theme.palette.primary.contrastText
  },
  chipIcon: {
    color: theme.palette.primary.contrastText
  },
  chipContainer: {
    alignSelf: 'center'
  }
}))

const showHour = (date: string) => {
  return format(new Date(date), formatHour)
}

const showSlotRange = (slot: Slot) => {
  const startTime = showHour(slot.date)
  const endTime = format(new Date(slot.date).getTime() + slot.duration_in_min * millisecondsForMinute, formatHour)
  return `${startTime} - ${endTime}`
}

export interface IScheduleMaintenanceContentProps {
  slotsDisplayed: Slot[]
  checkedSlots: Slot[]
  handleToggle: (value: Slot) => () => void
  webAppointmentSettings: boolean
  getObservation: (observationId: number) => string | undefined
}

const ScheduleMaintenanceContent: React.FC<IScheduleMaintenanceContentProps> = ({
  slotsDisplayed,
  handleToggle,
  checkedSlots,
  webAppointmentSettings,
  getObservation
}) => {
  const classes = useStyles()
  const { getStatusSlot } = useSlotProperties()

  return (
    <DialogContent dividers={true} className={classes.dialogContent}>
      <List className={classes.list}>
        {slotsDisplayed.map(slot => {
          const labelId = `checkbox-list-label-${slot.id}`
          const colorChip = getStatusSlot(slot).color as string
          const statusName = getStatusSlot(slot).text as string
          return (
            <ListItem
              key={slot.id}
              role={undefined}
              dense={true}
              button={true}
              onClick={handleToggle(slot)}
              className={classes.itemList}
              data-testid={`test-check-item-slot-${slot.id}`}
            >
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checkedSlots.indexOf(slot) !== notFound}
                  tabIndex={notFound}
                  disableRipple={true}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={showSlotRange(slot)} />
              {webAppointmentSettings ? (
                slot?.web_appointment && (
                  <Chip
                    icon={<AddToQueueIcon className={classes.chipIcon} />}
                    label='Web'
                    className={classes.chip}
                    size='small'
                    style={{ background: defaultColor }}
                  />
                )
              ) : (
                <Grid container={true} spacing={2} item={true} xs={7} justifyContent={'space-between'}>
                  <Grid item={true} xs={7}>
                    <Typography data-testid={`test-slot-observation-${slot.id}`} noWrap={false}>
                      {getObservation(slot.observation_id ?? 0)}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={5} className={classes.chipContainer}>
                    <Chip style={{ background: colorChip }} className={classes.chip} size='small' label={statusName} />
                  </Grid>
                </Grid>
              )}
            </ListItem>
          )
        })}
      </List>
    </DialogContent>
  )
}
export default ScheduleMaintenanceContent
