import { useState } from 'react'
import { RequestChangePassword, usePostChangePassword } from '../../api/api'
import { KEY_PUBLIC } from '../../config'
import { encryptWithPublicKey } from '../../security/UtilEncode'

const useChangePasswordPublic = () => {
  const [isCompleted, setIsCompleted] = useState(false)
  const [responseApiError, setResponseApiError] = useState(false)
  const changePasswordApi = usePostChangePassword({})

  const handleChangePassword = (passwordRecoverytoken: string, newPassword: string) => {
    let newPasswordUser = newPassword
    if (KEY_PUBLIC !== '' && KEY_PUBLIC !== null) {
      newPasswordUser = encryptWithPublicKey(newPassword, KEY_PUBLIC) ?? ''
    }

    const changePassword: RequestChangePassword = { passwordRecoverytoken, newPassword: newPasswordUser }
    changePasswordApi
      .mutate(changePassword)
      .then(() => {
        setResponseApiError(false)
        setIsCompleted(true)
      })
      .catch(() => setResponseApiError(true))
  }

  return { handleChangePassword, responseApiError, isCompleted }
}

export default useChangePasswordPublic
