import { CSRFToken } from '../api/api'
import { SecurityTokens, securityTokensLocalStorageKey } from './APISecurityContext'
import { b64EncodeUnicode } from './UtilEncode'
export const csrfTokensLocalStorageKey = 'csrfTokens'
export const csrfTokenInitial: CSRFToken = { csrfToken: '' }

const getAccessTokenAuthorizationHeader = () => {
  const localStoragetokens = window.sessionStorage.getItem(securityTokensLocalStorageKey)
  const localStorageCsrftokens = window.sessionStorage.getItem(csrfTokensLocalStorageKey)
  const securityTokens: SecurityTokens = localStoragetokens ? (JSON.parse(localStoragetokens) as SecurityTokens) : {}
  const csrfTokens: CSRFToken = localStorageCsrftokens
    ? (JSON.parse(localStorageCsrftokens) as CSRFToken)
    : csrfTokenInitial
  const accessToken: string = securityTokens ? securityTokens.accessToken || '' : ''

  return { Authorization: `Bearer ${accessToken}`, csrfToken: b64EncodeUnicode(csrfTokens.csrfToken) }
}

export default getAccessTokenAuthorizationHeader
