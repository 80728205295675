import { useGetSchedulesSlotsObservations } from '../../../api/api'

export const useObservations = () => {
  const listObservationsApi = useGetSchedulesSlotsObservations({})

  const errorObservations = listObservationsApi.error
  const loadingObservations = listObservationsApi.loading
  const listObservations = listObservationsApi.data || []
  const getObservation = (observationId: number) => {
    return listObservations?.find(observationIterator => observationIterator.id === observationId)?.observation
  }

  return { listObservations, loadingObservations, errorObservations, getObservation }
}
