import { CardActions, CardContent, Collapse, Grid, IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import format from 'date-fns/format'
import PersonIcon from '@material-ui/icons/Person'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EventIcon from '@material-ui/icons/Event'
import { Trans } from '@lingui/macro'
import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import FormatDate from '../Utils/FormatDate'
import { getLocaleLingui } from '../Utils/getLocale'

export interface IScheduleContentAuditProps {
  appointmentData?: AppointmentModel
}

const useStyles = makeStyles(theme => ({
  gridContent: {
    marginTop: theme.spacing(2)
  },
  cardContent: {
    padding: 0
  },
  textCenter: {
    textAlign: 'center'
  },
  icon: {
    color: theme.palette.action.active
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const ScheduleContentAudit: React.FC<IScheduleContentAuditProps> = ({ appointmentData }) => {
  const classes = useStyles()
  const locale = getLocaleLingui()
  const { FormatDateHourDisplaySchedule } = FormatDate()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const formatAuditDates = (value?: Date | string) => {
    return format(new Date(value || 0), FormatDateHourDisplaySchedule, {
      locale
    })
  }

  return (
    <>
      <CardActions className={classes.cardContent}>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
          data-testid='show-audit-info'
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            <Grid container alignItems='center' className={classes.gridContent}>
              <Grid item xs={2} className={classes.textCenter}>
                <PersonIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <span>
                  <Trans>Creador</Trans>: {appointmentData?.creatorName}
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid item xs={2} className={classes.textCenter}>
                <EventIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <span>
                  <Trans>Fecha de creación</Trans>: {formatAuditDates(appointmentData?.creationDate as string)}
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid item xs={2} className={classes.textCenter}>
                <EventIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <span>
                  <Trans>Fecha de activación</Trans>: {formatAuditDates(appointmentData?.activationDate as string)}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </>
  )
}

export default ScheduleContentAudit
