import { t } from '@lingui/macro'
import React from 'react'
import { GetDataError } from 'restful-react'
import { APIError, Schedule, useDeleteSchedules } from '../../../api/api'
import { getMessageFromStatusCode, statusCode } from '../Utils/useMessageFromStatusCode'
import { defaultScheduleId } from './useProfessionalSchedules'

interface IUseSchedule {
  listProfessionalsSchedules: Schedule[]
  setProfessionalsSchedules: (value: Schedule[]) => void
  setMessageError: (value: string) => void
}
export const useSchedule = ({
  listProfessionalsSchedules,
  setProfessionalsSchedules,
  setMessageError
}: IUseSchedule) => {
  const [scheduleDeletionId, setScheduleDeletionId] = React.useState<number>(defaultScheduleId)

  const deleteScheduleApi = useDeleteSchedules({})

  const loadingSchedule = deleteScheduleApi.loading

  const deleteSchedule = (scheduleIdDelete: number) => {
    listProfessionalsSchedules.forEach(scheduleAux => {
      if (scheduleAux.id === scheduleIdDelete) {
        setScheduleDeletionId(scheduleAux.id)
      }
    })
    setMessageError('')
  }

  React.useEffect(() => {
    if (scheduleDeletionId !== defaultScheduleId) {
      deleteScheduleApi
        .mutate(scheduleDeletionId)
        .then(() => {
          updateProfessionalSchedulesAfterScheduleDelete(scheduleDeletionId)
          setScheduleDeletionId(defaultScheduleId)
        })
        .catch((e: GetDataError<void | APIError>) => {
          setScheduleDeletionId(defaultScheduleId)
          setMessageError(
            e.status === statusCode.badRequest
              ? t`No se puede eliminar este elemento.`
              : getMessageFromStatusCode(e.status) || ''
          )
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDeletionId])

  const updateProfessionalSchedulesAfterScheduleDelete = (scheduleId: number) => {
    const listProfessionalsSchedulesAux = listProfessionalsSchedules
    const scheduleAux = listProfessionalsSchedulesAux.filter(schedule => {
      return schedule.id !== scheduleId
    })
    setProfessionalsSchedules([...scheduleAux])
  }

  return {
    deleteSchedule,
    loadingSchedule,
    setProfessionalsSchedules,
    listProfessionalsSchedules
  }
}
