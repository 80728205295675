import { t, Trans } from '@lingui/macro'
import {
  Backdrop,
  CircularProgress,
  createStyles,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React, { FunctionComponent, useEffect, useState } from 'react'
import SimpleDialog from '../../components/MainMenu/SimpleDialog'
import useChangePasswordVal, { Fields, FieldsValidations } from './useChangePasswordVal'

type SetOpenFunction = (open: boolean) => void

export interface ChangePasswordFormProps {
  open: boolean
  setOpen: SetOpenFunction
  closeSession: () => void
  handleOk: (currentPassword: string, newPassword: string) => void
  changePassword: {
    response: string
    error: boolean
    loading: boolean
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFormChangePassword: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 2,
      color: theme.palette.error.contrastText
    }
  })
)

const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = props => {
  const [values, setValues] = useState<Fields>({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
  const vals = useChangePasswordVal(values)
  const [showPassword, setShowPassword] = useState(false)
  const [successChangePassword, setSuccessChangePassword] = useState(false)
  const [changePasswordError, setChangePasswordError] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()

  useEffect(
    () => {
      if (props.changePassword.loading) {
        return
      }

      if (props.changePassword.error && !changePasswordError) {
        setChangePasswordError(true)
        return
      }

      if (
        props.changePassword.response !== '' &&
        props.changePassword.response !== 'ok' &&
        !props.changePassword.error
      ) {
        const currentVal: FieldsValidations = {
          currentPasswordVal: '',
          newPasswordVal: props.changePassword.response,
          confirmNewPasswordVal: '',
          isValid: false
        }
        vals.setValidations(currentVal)

        return
      }

      if (props.changePassword.response === 'ok' && !props.changePassword.error) {
        setSuccessChangePassword(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.changePassword.response, props.changePassword.error, props.changePassword.loading]
  )

  const handleClose = () => {
    props.setOpen(false)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (prop: keyof Fields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleCloseErrorDialog = () => {
    setChangePasswordError(false)
  }

  const handleSuccesChangePasswordDialog = () => {
    props.closeSession()
  }

  const handleOk = () => {
    props.handleOk(values.currentPassword, values.newPassword)
  }

  return (
    <div>
      <Dialog open={props.open && !successChangePassword} aria-labelledby='form-dialog-title' fullScreen={fullScreen}>
        {props.changePassword.loading && (
          <Backdrop
            id='change-notifications-settings-backdrop-load'
            open={props.changePassword.loading}
            className={classes.backdrop}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <DialogTitle id='form-dialog-title'>
          <Trans>Cambiar Contraseña</Trans>
        </DialogTitle>
        <DialogContent className={classes.rootFormChangePassword}>
          <FormControl fullWidth={true}>
            <div>
              <TextField
                id='input-password'
                data-testid='input-password'
                type={showPassword ? 'text' : 'password'}
                fullWidth={true}
                autoFocus={true}
                value={values.currentPassword}
                onChange={handleChange('currentPassword')}
                error={vals.validations.currentPasswordVal !== ''}
                helperText={vals.validations.currentPasswordVal}
                label={t`Contraseña Anterior`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        data-testid='toggle-password-visibility'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              id='input-new-password'
              data-testid='input-new-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth={true}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              error={vals.validations.newPasswordVal !== ''}
              helperText={vals.validations.newPasswordVal}
              label={t`Nueva Contraseña`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              id='input-confirm-new-password'
              data-testid='input-confirm-new-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth={true}
              value={values.confirmNewPassword}
              onChange={handleChange('confirmNewPassword')}
              error={vals.validations.confirmNewPasswordVal !== ''}
              helperText={vals.validations.confirmNewPasswordVal}
              label={t`Confirmar Nueva Contraseña`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button id='change-pass-button-ok' onClick={handleOk} color='primary' disabled={!vals.validations.isValid}>
            <Trans>Aceptar</Trans>
          </Button>
          <Button id='change-pass-button-cancel' onClick={handleClose} color='primary'>
            <Trans>Cancelar</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      {changePasswordError && (
        <SimpleDialog
          open={changePasswordError}
          title={t`Error al comunicarse con el servicio de iMedicalCloud`}
          contentText={t`En este momento no podemos comunicarnos con el servicio. Por favor verifique la conexión y reintente nuevamente.`}
          dialogActions={
            <Button data-testid='dialog-error-ok-button' onClick={handleCloseErrorDialog} color='primary'>
              <Trans>Aceptar</Trans>
            </Button>
          }
          dialogClose={handleCloseErrorDialog}
        />
      )}
      {successChangePassword && (
        <SimpleDialog
          open={successChangePassword}
          title={t`¡Su contraseña ha cambiado!`}
          contentText={t`La aplicación le pedira nuevamente sus datos de inicio.`}
          dialogActions={
            <Button onClick={handleSuccesChangePasswordDialog} color='primary'>
              <Trans>Aceptar</Trans>
            </Button>
          }
          dialogClose={handleSuccesChangePasswordDialog}
        />
      )}
    </div>
  )
}

export default ChangePasswordForm
