import { Grid, Paper, Box, makeStyles, Theme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import { Copyright, NewsFrame } from '../../SignSideForm'
import AddTwoFactorAuthGoogleContainer from '../AddTwoFactorAuthGoogle/AddTwoFactorAuthGoogleContainer'
import ValidateTwoFactorAuthContainer from '../ValidateTwoFactorAuth/ValidateTwoFactorAuthContainer'
import theme from '../../../../themes/default'

const useStyles = makeStyles((myTheme: Theme) => ({
  root: {
    height: '100vh'
  },
  paper: {
    margin: myTheme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: myTheme.spacing(1),
    width: '230px'
  },
  form: {
    width: '100%',
    marginTop: myTheme.spacing(1)
  },
  submit: {
    margin: myTheme.spacing(3, 0, 2)
  },
  submitRecovery: {
    margin: myTheme.spacing(3, 1, 2)
  },
  outlineInputLabel: {
    backgroundColor: myTheme.palette.background.paper,
    paddingLeft: myTheme.spacing(1),
    paddingRight: myTheme.spacing(1)
  },
  fullWidth: {
    width: '100%'
  },
  typography: {
    padding: myTheme.spacing(2)
  },
  newsFrame: {
    height: '100vh',
    width: '100%',
    border: 0
  }
}))

export interface IConfigTwoFactorAuth {
  isUse2FA: boolean
  isAuthenticated: boolean
  config2FA: string
}

const ConfigTwoFactorAuth: React.FC<IConfigTwoFactorAuth> = props => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <>
      <Grid container={true} component='main' className={classes.root}>
        <NewsFrame />
        <Grid item={true} xs={12} sm={12} md={5} lg={4} xl={3} component={Paper} elevation={6} square={true}>
          <div className={classes.paper}>
            {theme.palette.type === 'light' ? (
              <img className={classes.avatar} alt='' src='images/login/imedicalcloud-logo-color.png' />
            ) : (
              <img className={classes.avatar} alt='' src='images/login/logo-transparente.png' />
            )}
            {errorMessage !== '' && (
              <Alert id='errorMessage' variant='outlined' className={classes.fullWidth} severity='error'>
                {errorMessage}
              </Alert>
            )}
            {props.config2FA === '' ? (
              <AddTwoFactorAuthGoogleContainer {...{ setErrorMessage }} />
            ) : (
              <ValidateTwoFactorAuthContainer {...{ setErrorMessage }} />
            )}

            <Box mt={2}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ConfigTwoFactorAuth
