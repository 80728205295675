import { t } from '@lingui/macro'
import { Badge, createStyles, makeStyles, Switch, Theme } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useLocalStorage from '../../libs/useLocalStorage'
import MenuItem from '../../models/MenuItem'
import { useMenuItems } from './contexts/useMenuItems'

const developmentEnvironment = 'imedicalservices.dev'
const developmentEnvironmentLocal = 'localhost'
const newMenuOptionsSettings = 'NewMenuOptionsSettings'
const newPathOption = '/main/menu/option/'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      margin: theme.spacing.length * 2,
      marginRight: theme.spacing(2)
    }
  })
)

const newOptionsPaths = [
  {
    oldPath: 'Prestacion/Citas/MedicosAgenda.aspx',
    newPath: '/main/menu/doctor/agenda/'
  }
]

function includesLowerCaseString(source: string, target: string) {
  return source.toLowerCase().includes(target.toLowerCase())
}

function isValidEnvironment(path: string) {
  return (
    includesLowerCaseString(path, developmentEnvironment) || includesLowerCaseString(path, developmentEnvironmentLocal)
  )
}

function isValidMenuPath(path: string) {
  for (const newOptionsPath of newOptionsPaths) {
    const validOldPath = includesLowerCaseString(path, newOptionsPath.oldPath)
    const validNewPath = includesLowerCaseString(path, newOptionsPath.newPath)

    if (validNewPath || validOldPath) {
      return true
    }
  }

  return false
}

const isValidEnvironmentAndMenuOption = (menuItem: MenuItem | null) => {
  return menuItem !== null && menuItem.url !== '' && isValidEnvironment(menuItem.url) && isValidMenuPath(menuItem.url)
}

function getMenuOptionPaths(selectedMenuItem: MenuItem) {
  for (const newOptionsPath of newOptionsPaths) {
    if (includesLowerCaseString(selectedMenuItem.url, newOptionsPath.oldPath)) {
      return {
        newPath: newOptionsPath.newPath,
        oldPath: `${newPathOption}${selectedMenuItem.id}`,
        found: true
      }
    }
  }

  return {
    oldPath: '',
    newPath: '',
    found: false
  }
}

interface MenuOptionsSettings {
  path: string
  active: boolean
}

function getNewMenuOptionSettingsActive(allNewMenuOptionSettings: MenuOptionsSettings[], url: string): boolean {
  for (const newMenuOptionSettings of allNewMenuOptionSettings) {
    if (url === newMenuOptionSettings.path) {
      return newMenuOptionSettings.active
    }
  }

  return false
}

function getNewMenuOptionSettings(
  menuOptionsSettings: MenuOptionsSettings[],
  selectedMenuItem: MenuItem,
  activeSetting: boolean
) {
  const allMenuOptionSettings = menuOptionsSettings
  let found = false
  const paths = getMenuOptionPaths(selectedMenuItem)

  for (const currentMenuOptionSettings of allMenuOptionSettings) {
    if (includesLowerCaseString(paths.newPath, currentMenuOptionSettings.path)) {
      currentMenuOptionSettings.active = activeSetting
      found = true
      break
    }
  }

  if (!found) {
    allMenuOptionSettings.push({
      path: paths.newPath,
      active: activeSetting
    })
  }

  return allMenuOptionSettings
}

// eslint-disable-next-line no-shadow
enum ActionMenuOption {
  GoToNew,
  GoToOld,
  Stay
}

function calculateOptionToShow(newFunctionality: boolean, actualPath: string, newPath: string, oldPath: string) {
  if (!newFunctionality && actualPath.startsWith(newPath)) {
    return ActionMenuOption.GoToOld
  }

  if (newFunctionality && actualPath.startsWith(oldPath)) {
    return ActionMenuOption.GoToNew
  }

  return ActionMenuOption.Stay
}

const SwitchNewFunctionality: FunctionComponent = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [menuOptionsSettings, setMenuOptionsSettings] = useLocalStorage<MenuOptionsSettings[]>(
    newMenuOptionsSettings,
    []
  )
  const [newFunctionality, setNewFunctionality] = useState<boolean | null>(null)
  const { selectedMenuItem } = useMenuItems()
  const [isVisible, setIsVisible] = React.useState(false)

  useEffect(() => {
    if (!selectedMenuItem || newFunctionality === null) {
      return
    }

    const menuOptionPaths = getMenuOptionPaths(selectedMenuItem)

    if (!menuOptionPaths.found) {
      return
    }

    const changeOption = calculateOptionToShow(
      newFunctionality,
      location.pathname,
      menuOptionPaths.newPath,
      menuOptionPaths.oldPath
    )

    switch (changeOption) {
      case ActionMenuOption.GoToOld: {
        history.push(menuOptionPaths.oldPath)
        break
      }
      case ActionMenuOption.GoToNew: {
        history.push(menuOptionPaths.newPath)
        break
      }
    }
  }, [newFunctionality])

  useEffect(() => {
    if (!selectedMenuItem) {
      setIsVisible(false)
      setNewFunctionality(null)

      return
    }

    setIsVisible(isValidEnvironmentAndMenuOption(selectedMenuItem))

    const paths = getMenuOptionPaths(selectedMenuItem)

    setNewFunctionality(getNewMenuOptionSettingsActive(menuOptionsSettings, paths.newPath))
  }, [selectedMenuItem])

  const saveNewMenuOptionSettings = (activeSetting: boolean) => {
    if (!selectedMenuItem) {
      return
    }

    const paths = getMenuOptionPaths(selectedMenuItem)
    const actualValue = getNewMenuOptionSettingsActive(menuOptionsSettings, paths.newPath)

    if (actualValue !== activeSetting) {
      setMenuOptionsSettings(getNewMenuOptionSettings(menuOptionsSettings, selectedMenuItem, activeSetting))
    }
  }

  const handleChange = () => {
    saveNewMenuOptionSettings(!newFunctionality)
    setNewFunctionality(!newFunctionality)
  }

  return (
    <>
      {isVisible && (
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          badgeContent={t`Nuevo`}
          color='error'
          className={classes.badge}
          data-testid='appbar-switch-badget'
        >
          <Switch
            checked={newFunctionality ? newFunctionality : false}
            onChange={handleChange}
            data-testid='switch-change-view'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Badge>
      )}
    </>
  )
}

export default SwitchNewFunctionality
