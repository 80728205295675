import { t } from '@lingui/macro'

export const statusCode = {
  badRequest: 400,
  unAuthorized: 401,
  forbidden: 403,
  notFound: 404,
  internalServer: 500,
  badRequestObservations: 5004
}

export const getMessageFromStatusCode = (statusCodeReceived: number | undefined) => {
  switch (statusCodeReceived) {
    case statusCode.badRequest:
      return t`Los datos proporcionados son incorrectos.`

    case statusCode.unAuthorized:
      return t`No se ha autenticado o su sesión ya terminó.`

    case statusCode.forbidden:
      return t`No está autorizado para acceder desde esta ubicación.`

    case statusCode.notFound:
      return t`Recurso no encontrado.`

    case statusCode.internalServer:
      return t`Error interno. En este momento no podemos atender su solicitud.`

    case statusCode.badRequestObservations:
      return t`Algunas de las observaciones enviadas no son válidas o están vacías.`

    default:
      return t`No es posible comunicarse con el servicio, puede intentarlo de nuevo más tarde.`
  }
}
