import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import React, { useContext } from 'react'
import useLocalStorage from '../libs/useLocalStorage'
import darkTheme from './dark'
import defaultTheme from './default'

interface ThemeContextProps {
  darkMode: boolean
  setDarkMode(darkMode: boolean): void
}

const ThemeContext = React.createContext<ThemeContextProps>({
  darkMode: false,
  // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
  setDarkMode: () => {}
})

interface Props {
  children?: React.ReactNode
}

const ThemeContextProvider: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false)
  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        setDarkMode
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useStore = () => useContext(ThemeContext)

export function withProvider(Component: React.FC<any>) {
  return function WrapperComponent(props: any) {
    return (
      <ThemeContextProvider>
        <Component {...props} />
      </ThemeContextProvider>
    )
  }
}

export { ThemeContext, ThemeContextProvider }

export const useApp = () => {
  const { darkMode, setDarkMode } = useStore()
  return {
    darkMode,
    setDarkMode
  }
}

export function withThemeProvider(Component: React.FunctionComponent<any>) {
  const WrapperComponent = (props: any) => {
    const { darkMode } = useApp()
    const theme = darkMode ? darkTheme : defaultTheme
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    )
  }
  return withProvider(WrapperComponent)
}
