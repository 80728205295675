import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { EntitiesModel, useGetUsersMyCompanies } from '../../api/api'

let debounceTime = 1000
if (process.env.NODE_ENV === 'test') {
  debounceTime = 0
}

export const useGetUserCompanies = (setErrorMessage: (message: string) => void, IsValidAuth: boolean) => {
  const [companies, setCompanies] = useState<EntitiesModel[]>([])
  const getUsersCompaniesApi = useGetUsersMyCompanies({
    debounce: debounceTime,
    lazy: !IsValidAuth
  })

  const instanceOfEntitiesModel = (data: any): data is EntitiesModel[] => {
    return data instanceof Array && data.length > 0 && 'id' in data[0]
  }

  useEffect(() => {
    setErrorMessage('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!getUsersCompaniesApi.data || getUsersCompaniesApi.data.length === 0) {
      // Avoid giving clues to an attacker it will not be warned that the user does not exist
      return
    }

    if (!instanceOfEntitiesModel(getUsersCompaniesApi.data)) {
      setErrorMessage(t`Ocurrió un problema al intentar conectar con el servidor.`)

      return
    }

    setCompanies(getUsersCompaniesApi.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersCompaniesApi.data])

  useEffect(() => {
    if (getUsersCompaniesApi.error !== null) {
      setErrorMessage(t`Ocurrió un problema al intentar conectar con el servidor.`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersCompaniesApi.error])

  return { loading: getUsersCompaniesApi.loading, data: companies, setData: setCompanies }
}
