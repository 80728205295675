import { t } from '@lingui/macro'
import { Button, DialogActions, Hidden, makeStyles } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import React from 'react'

const useStyles = makeStyles(theme => ({
  iconActions: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-1)
    }
  },
  button: {
    textTransform: 'none',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0)
    }
  },
  dialogActions: {
    justifyContent: 'center'
  }
}))

export const slotShares = {
  delete: 'delete',
  disable: 'disable',
  enable: 'enable',
  reserve: 'reserve',
  available: 'available',
  enableWebAppointments: 'enableWebAppointments',
  disableWebAppointments: 'disableWebAppointments'
}

interface ScheduleMaintenanceActionsProps {
  setConfirmationMessageContent: (value: string) => void
  setOpenConfirmDialog: (value: boolean) => void
  setSlotOptionSelected: (value: string) => void
  webAppointmentSettings: boolean
}
const ScheduleMaintenanceActions: React.FC<ScheduleMaintenanceActionsProps> = ({
  setConfirmationMessageContent,
  setOpenConfirmDialog,
  setSlotOptionSelected,
  webAppointmentSettings
}) => {
  const classes = useStyles()
  const confirmationMessageContentToDelete = t`¿Esta seguro que desea eliminar los espacio de la agenda?`
  const confirmationMessageContentToUpdate = t`¿Está seguro que desea modificar los espacio de la agenda?`

  const openConfirmationMessage = (actionOption: string) => {
    if (actionOption === slotShares.delete) {
      setConfirmationMessageContent(confirmationMessageContentToDelete)
    } else {
      setConfirmationMessageContent(confirmationMessageContentToUpdate)
    }

    setSlotOptionSelected(actionOption)
    setOpenConfirmDialog(true)
  }
  return webAppointmentSettings ? (
    <DialogActions disableSpacing={true} className={classes.dialogActions}>
      <Button
        size='small'
        variant='outlined'
        startIcon={<CheckIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.enableWebAppointments)}
        data-testid='test-button-enable-web-appointment'
      >
        <Hidden smDown={true}>{t`Habilitar Cita Web`}</Hidden>
      </Button>
      <Button
        size='small'
        variant='outlined'
        startIcon={<BlockIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.disableWebAppointments)}
        data-testid='test-button-disable-web-appointment'
      >
        <Hidden smDown={true}>{t`Deshabilitar Cita Web`}</Hidden>
      </Button>
    </DialogActions>
  ) : (
    <DialogActions disableSpacing={true} className={classes.dialogActions}>
      <Button
        size='small'
        variant='outlined'
        startIcon={<CheckIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.enable)}
        data-testid='test-button-enable'
      >
        <Hidden smDown={true}>{t`Habilitar`}</Hidden>
      </Button>
      <Button
        size='small'
        variant='outlined'
        startIcon={<BlockIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.disable)}
        data-testid='test-button-disable'
      >
        <Hidden smDown={true}>{t`Deshabilitar`}</Hidden>
      </Button>
      <Button
        size='small'
        variant='outlined'
        startIcon={<LockIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.reserve)}
        data-testid='test-button-reserve'
      >
        <Hidden smDown={true}>{t`Reservar`}</Hidden>
      </Button>
      <Button
        size='small'
        variant='outlined'
        startIcon={<LockOpenIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.available)}
        data-testid='test-button-available'
      >
        <Hidden smDown={true}>{t`Liberar`}</Hidden>
      </Button>
      <Button
        size='small'
        variant='outlined'
        startIcon={<DeleteIcon className={classes.iconActions} />}
        className={classes.button}
        onClick={() => openConfirmationMessage(slotShares.delete)}
        data-testid='test-button-delete'
      >
        <Hidden smDown={true}>{t`Eliminar`}</Hidden>
      </Button>
    </DialogActions>
  )
}
export default ScheduleMaintenanceActions
