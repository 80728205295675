import { t, Trans } from '@lingui/macro'
import { CircularProgress, Popover, Switch, useMediaQuery } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { Alert } from '@material-ui/lab'
import React, { ChangeEvent, FunctionComponent, lazy, Suspense, useEffect, useState } from 'react'
import { EntitiesModel, LoginModel, SelectEntityModel } from '../../api/api'
import { LoginForm } from './LoginForm'

const TermsAndConditions = lazy(() => import('../../components/TermsAndConditions/TermsAndConditions'))

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: '230px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  submitRecovery: {
    margin: theme.spacing(3, 1, 2)
  },
  outlineInputLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: theme.palette.error.contrastText
  },
  fullWidth: {
    width: '100%'
  },
  typography: {
    padding: theme.spacing(2)
  },
  newsFrame: {
    height: '100vh',
    width: '100%',
    border: 0
  },
  captcha: {
    marginRight: 'auto',
    marginLeft: 'auto'
  }
}))

export const Copyright: React.FC = () => {
  const isXSSize = useMediaQuery('(max-width:600px)')
  const classes = useStyles()
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

  const handleShowTermsAndConditions = () => {
    setShowTermsAndConditions(true)
  }

  return (
    <>
      <Typography variant='body2' color='textSecondary' align='center'>
        <Link onClick={handleShowTermsAndConditions} color='inherit' data-testid='terms-condition-link'>
          <Trans>Términos y Condiciones</Trans>
        </Link>
      </Typography>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://www.imedicalservices.com/'>
          <Trans>iMedical Services</Trans>
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      {showTermsAndConditions && (
        <Suspense
          fallback={
            <Backdrop open={true} className={classes.backdrop} data-testid='backdrop-terms-and-conditions'>
              <CircularProgress color='inherit' />
            </Backdrop>
          }
        >
          <TermsAndConditions setOpenTermsAndConditions={setShowTermsAndConditions} fullScreen={isXSSize} />
        </Suspense>
      )}
    </>
  )
}

const newsBlogUrl = 'https://www.imedicalservices.com/ultimas-noticias-imedical'
const frameTitle = 'iMedical News'

export const NewsFrame: FunctionComponent = () => {
  const classes = useStyles()
  const [hasError, setHasError] = useState(true)

  const handleFrameError = () => {
    setHasError(true)
  }
  return (
    <Hidden smDown={true}>
      <Grid item={true} xs={false} sm={false} md={7} lg={8} xl={9}>
        {hasError ? (
          <img className={classes.newsFrame} src='images/wallpaper.jpg' alt={'error notice'} />
        ) : (
          <iframe
            id='frame-imedical-news'
            className={classes.newsFrame}
            title={frameTitle}
            src={newsBlogUrl}
            scrolling='no'
            onError={handleFrameError}
          />
        )}
      </Grid>
    </Hidden>
  )
}
export interface Companies {
  loading: boolean
  data: EntitiesModel[]
  setData: (data: EntitiesModel[]) => void
}

export interface LoginFormProps {
  setErrorMessage: (value: string) => void
  changeForm: () => void
  login: {
    postLogin: (loginData: LoginModel) => void
    postSelectCompany: (value: SelectEntityModel) => void
  }
  entityServices: {
    loading: boolean
    selectedCompany: EntitiesModel | null
    selectEntityRequest: SelectEntityModel
    handleChangeEntity: (event: ChangeEvent<{ value: unknown }>) => void
    handleChangeLocation: (event: ChangeEvent<{ value: unknown }>) => void
    companiesData: EntitiesModel[]
  }
  handleCloseSession: () => void
  isAuthenticated: boolean
  isValidAuth: boolean
}

export interface RecoveryPassword {
  username: string
  setUsername: (username: string) => void
  recoveryPassword: () => void
  loading: boolean
  isRequestSent: boolean
}

export interface RecoveryPasswordFormProps {
  changeForm: () => void
  recoveryPassword: RecoveryPassword
}

export const RecoveryPasswordForm: FunctionComponent<RecoveryPasswordFormProps> = props => {
  const classes = useStyles()
  const [username, setUsername] = useState('')
  const [successMessage, setSuccessMessage] = React.useState('')

  useEffect(() => {
    setSuccessMessage(
      props.recoveryPassword.isRequestSent
        ? t`Las instrucciones para recuperar su contraseña han sido enviadas a su correo registrado. Si es un usuario activo y no recibe el correo, contacte al administrador del sistema.`
        : ''
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recoveryPassword.isRequestSent])

  const handleUserName = (event: ChangeEvent<{ value: unknown }>) => {
    setUsername(event.target.value as string)
    props.recoveryPassword.setUsername(event.target.value as string)
  }

  const handleRecoveryPassword = () => {
    props.recoveryPassword.recoveryPassword()
  }

  const handleBackButton = () => {
    props.recoveryPassword.setUsername('')
    props.changeForm()
  }

  return (
    <form className={classes.form} noValidate={true}>
      {successMessage !== '' && (
        <Alert id='successMessage' severity='success' variant='outlined' data-testid='recovery-password-alert-success'>
          <Typography variant='body1' gutterBottom={true}>
            {successMessage}
          </Typography>
          <Typography variant='caption'>
            <Trans>
              Si no encuentra el mensaje, por favor recuerde revisar la carpeta de correo no deseado o spam.
            </Trans>
          </Typography>
        </Alert>
      )}
      {successMessage === '' && (
        <TextField
          variant='outlined'
          margin='normal'
          required={true}
          fullWidth={true}
          id='username'
          label={t`Usuario`}
          name='username'
          autoComplete='user'
          autoFocus={true}
          value={username}
          onChange={handleUserName}
        />
      )}
      <Grid container={true} item={true} alignItems='flex-end' justifyContent='flex-end'>
        <Button
          id='back-button'
          variant='contained'
          color='secondary'
          className={classes.submitRecovery}
          onClick={handleBackButton}
        >
          <Trans>Regresar</Trans>
        </Button>
        {successMessage === '' && (
          <Button
            id='enter-button'
            onClick={handleRecoveryPassword}
            variant='contained'
            color='primary'
            className={classes.submitRecovery}
            disabled={username === ''}
          >
            <Trans>Recuperar</Trans>
          </Button>
        )}
      </Grid>
    </form>
  )
}

export interface UseOldMenuProps {
  newMenu: boolean
  setNewMenu: (value: boolean) => void
  firstTime: boolean
  setFirstTime: (value: boolean) => void
}

export const UseOldMenu: FunctionComponent<UseOldMenuProps> = props => {
  const classes = useStyles()

  const divRef = React.useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    setAnchorEl(divRef.current)
  }, [divRef])

  const handleUseNewMenuSettings = () => {
    props.setNewMenu(!props.newMenu)
  }

  const handleClose = () => {
    props.setFirstTime(false)
  }

  const open = props.firstTime && anchorEl !== null

  return (
    <Box className={classes.fullWidth} mt={1} display='flex' justifyContent='flex-end'>
      <Box>
        <Trans>Usar menú anterior</Trans>
        <Switch ref={divRef} checked={!props.newMenu} onClick={handleUseNewMenuSettings} />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Typography className={classes.typography}>
            <NotificationsActiveIcon color='primary' /> <Trans>Para regresar a la vista clásica oprima aqui!</Trans>
          </Typography>
        </Popover>
      </Box>
    </Box>
  )
}

export interface SignSideFormProps {
  errorMessage: string
  setErrorMessage: (values: string) => void
  loading: boolean
  login: {
    postLogin: (loginData: LoginModel) => void
    postSelectCompany: (value: SelectEntityModel) => void
  }
  entityServices: {
    loading: boolean
    selectedCompany: EntitiesModel | null
    selectEntityRequest: SelectEntityModel
    handleChangeEntity: (event: ChangeEvent<{ value: unknown }>) => void
    handleChangeLocation: (event: ChangeEvent<{ value: unknown }>) => void
    companiesData: EntitiesModel[]
  }
  recoveryPassword: RecoveryPassword
  showLogin?: boolean
  newMenu: boolean
  setNewMenu: (value: boolean) => void
  firstTime: boolean
  setFirstTime: (value: boolean) => void
  isAuthenticated: boolean
  haveSecurityTokens: boolean
  isValidAuth: boolean
  handleCloseSession: () => void
}

const loginWindowTitle = t`Entrar` + ' - iMedical'

export const SignSideForm: FunctionComponent<SignSideFormProps> = props => {
  const theme = useTheme()
  const classes = useStyles()

  const [showLogin, setShowLogin] = useState(props.showLogin === undefined ? true : props.showLogin)

  useEffect(() => {
    document.title = loginWindowTitle
  }, [])

  const changeForm = () => {
    setShowLogin(!showLogin)
  }

  return (
    <>
      <Backdrop id='main-backdrop-load' open={props.loading} className={classes.backdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <>
        <Grid container={true} component='main' className={classes.root}>
          <NewsFrame />
          <Grid item={true} xs={12} sm={12} md={5} lg={4} xl={3} component={Paper} elevation={6} square={true}>
            <div className={classes.paper}>
              {theme.palette.type === 'light' ? (
                <img className={classes.avatar} alt='' src='images/login/imedicalcloud-logo-color.png' />
              ) : (
                <img className={classes.avatar} alt='' src='images/login/logo-transparente.png' />
              )}
              {props.errorMessage !== '' && (
                <Alert id='errorMessage' variant='outlined' className={classes.fullWidth} severity='error'>
                  {props.errorMessage}
                </Alert>
              )}
              {showLogin ? (
                <LoginForm
                  setErrorMessage={props.setErrorMessage}
                  login={props.login}
                  changeForm={changeForm}
                  isAuthenticated={props.haveSecurityTokens}
                  isValidAuth={props.isValidAuth}
                  entityServices={props.entityServices}
                  handleCloseSession={props.handleCloseSession}
                />
              ) : (
                <RecoveryPasswordForm changeForm={changeForm} recoveryPassword={props.recoveryPassword} />
              )}
              <Box mt={2}>
                <Copyright />
              </Box>
            </div>
          </Grid>
        </Grid>
        {!props.isValidAuth && props.haveSecurityTokens && (
          <Backdrop className={classes.backdrop} open={true}>
            <Snackbar open={true} autoHideDuration={0}>
              <Alert severity='warning'>
                <Trans>Usted se conectó desde otra pestaña o ventana.</Trans>
                <br />
                <Trans>
                  Recargue la página para refrescar su sesión u
                  <Link href='.' onClick={() => window.location.reload()}>
                    oprima aquí para continuar
                  </Link>
                </Trans>
              </Alert>
            </Snackbar>
          </Backdrop>
        )}
      </>
    </>
  )
}

export default SignSideForm
