import { t } from '@lingui/macro'
import { Checkbox, createStyles, FormControlLabel, Grid, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { ScheduleInput } from '../../../api/api'
import ScheduleRanges from './ScheduleRanges'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conatinerScheduleAvailability: {
      marginTop: theme.spacing(2.5)
    },
    header: {
      margin: '5px'
    },
    checkboxesgrid: {
      paddingLeft: '-30px',
      paddingRight: '-40px',
      marginTop: '10px',
      textAlign: 'center'
    }
  })
)

export interface ScheduleAvailabilityProps {
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
}

export const daysCode = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6
}

const ScheduleAvailability: React.FC<ScheduleAvailabilityProps> = ({ newSchedule, setNewSchedule }) => {
  const classes = useStyles()

  const handleChecks = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const index = newSchedule.days_of_week?.indexOf(Number(event.target.name)) ?? -1
      if (index < 0) {
        const days = [...(newSchedule.days_of_week ?? []), Number(event.target.name)]
        setNewSchedule({ ...newSchedule, days_of_week: days })
      }
    } else {
      const index = newSchedule.days_of_week?.indexOf(Number(event.target.name)) ?? -1
      if (index >= 0) {
        const days = newSchedule.days_of_week?.filter(n => n !== Number(event.target.name))
        setNewSchedule({ ...newSchedule, days_of_week: days })
      }
    }
  }

  return (
    <Grid container={true} spacing={1} className={classes.conatinerScheduleAvailability}>
      <ScheduleRanges {...{ newSchedule, setNewSchedule }} />

      <Grid item={true} xs={12} className={classes.checkboxesgrid}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid='monday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.monday) ?? -1) >= 0}
              name={daysCode.monday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`L`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='tuesday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.tuesday) ?? -1) >= 0}
              name={daysCode.tuesday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`Ma`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='wednesday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.wednesday) ?? -1) >= 0}
              name={daysCode.wednesday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`Mi`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='thursday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.thursday) ?? -1) >= 0}
              name={daysCode.thursday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`J`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='friday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.friday) ?? -1) >= 0}
              name={daysCode.friday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`V`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='saturday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.saturday) ?? -1) >= 0}
              name={daysCode.saturday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`S`}
        />
        <FormControlLabel
          control={
            <Checkbox
              data-testid='sunday-check'
              checked={(newSchedule.days_of_week?.indexOf(daysCode.sunday) ?? -1) >= 0}
              name={daysCode.sunday.toString()}
              color='secondary'
              onChange={handleChecks}
            />
          }
          label={t`D`}
        />
      </Grid>
    </Grid>
  )
}

export default ScheduleAvailability
