import { Breadcrumbs, createStyles, Hidden, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FunctionComponent } from 'react'

export interface MainBreadcrumbsProps {
  levels: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: '100%',
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
      }
    }
  })
)

const MainBreadcrumbs: FunctionComponent<MainBreadcrumbsProps> = props => {
  const classes = useStyles()

  if (props.levels.length === 0) {
    return null
  }

  return (
    <div className={classes.breadcrumbs}>
      {/* Hide when is a small device */}
      <Hidden smDown={true}>
        <Breadcrumbs aria-label='breadcrumb' style={{ color: '#fff' }}>
          {props.levels.map((level, index) => (
            <Typography key={index}> {level}</Typography>
          ))}
        </Breadcrumbs>
      </Hidden>
    </div>
  )
}

export default MainBreadcrumbs
