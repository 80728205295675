import { AppBar, Box, Chip, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ApartmentIcon from '@material-ui/icons/Apartment'
import MenuItem from '../../models/MenuItem'
import Notification from '../../models/Notification'
import { useMenuItems } from './contexts/useMenuItems'
import MainBreadcrumbs from './MainBreadcrumbs'
import NotificationBadget from './NotificationBadget'
import SwitchNewFunctionality from './SwitchNewFunctionality'

type SetOpenFunction = (open: boolean) => void

export interface MainAppBarProps {
  open: boolean
  setOpen: SetOpenFunction
  shortNameCompany?: string
  branchOffice?: string
  subtitle: string
  notifications: Notification[]
}

const drawerWidth = 400

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    logo: {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1)
    },
    infoEntity: {
      marginRight: theme.spacing(0.8),
      marginButton: 'auto',
      width: '20%'
    },
    subtitle: {
      marginTop: 'auto',
      marginButton: 'auto',
      // marginLeft: theme.spacing(2),
      fontSize: 9,
      height: theme.spacing(2),
      overflow: 'auto',
      borderRadius: theme.spacing(1),
      color: theme.palette.secondary.contrastText,
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, ' +
        'rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, ' +
        'rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
    },
    toolbar: {
      minHeight: theme.spacing(6),
      paddingRight: theme.spacing(0)
    },
    chipContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    sectionDesktop: {
      display: 'center',
      marginRight: theme.spacing(1)
    }
  })
)

const MainAppBar: FunctionComponent<MainAppBarProps> = props => {
  const classes = useStyles()
  const [breadcrums, setBreadcrums] = useState<string[]>([])
  const history = useHistory()
  const { path } = useRouteMatch()
  const { menuItems, selectedMenuItem } = useMenuItems()

  useEffect(() => {
    setBreadcrums(getBreadCrums(selectedMenuItem, menuItems))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem, menuItems])

  const handleMenuOpen = () => {
    props.setOpen(true)
  }

  const preventDefault = () => {
    if (history.location.pathname !== path) {
      history.push(path)
    }
  }

  const getBreadCrums = (findItem: MenuItem | null, items: MenuItem[]): string[] => {
    if (!items || !findItem) {
      return []
    }

    for (const item of items) {
      if (findItem.id === item.id) {
        return [item.title]
      } else {
        if (item.items) {
          const childItem = getBreadCrums(findItem, item.items)

          if (childItem.length !== 0) {
            return [item.title, ...childItem]
          }
        }
      }
    }

    return []
  }

  return (
    <AppBar position='fixed' color='primary'>
      <Toolbar className={classes.toolbar}>
        <IconButton
          data-testid='open-drawer-button'
          color='inherit'
          aria-label='open drawer'
          onClick={handleMenuOpen}
          edge='start'
          className={clsx(classes.menuButton, {
            [classes.hide]: props.open
          })}
        >
          <MenuIcon />
        </IconButton>
        <Grid container={true} justifyContent={'flex-start'} alignItems='center' direction='row'>
          <Link href='#' onClick={preventDefault} className={classes.logo}>
            <img src='images/login/logo-transparente.png' alt='logo imedical' width='120' height='40' id='main-logo' />
          </Link>
          <MainBreadcrumbs levels={breadcrums} />
        </Grid>
        <Box display='flex' alignItems='center' className={classes.infoEntity}>
          <Box display='flex' ml={'auto'}>
            <ApartmentIcon fontSize='small' />
          </Box>
          <Box display='flex' flexDirection='column'>
            <Typography style={{ fontSize: '10px' }}>{props.shortNameCompany}</Typography>
            <Typography style={{ fontSize: '10px' }}>{props.branchOffice}</Typography>
            {props.subtitle && props.subtitle !== '' && (
              <div>
                <Chip
                  className={classes.subtitle}
                  id='main-title-subtitle'
                  size='medium'
                  label={props.subtitle}
                  color='secondary'
                />
              </div>
            )}
          </Box>
        </Box>
        <SwitchNewFunctionality />
        <NotificationBadget notifications={props.notifications} />
      </Toolbar>
    </AppBar>
  )
}

export default MainAppBar
