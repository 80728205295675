import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui'
import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import format from 'date-fns/format'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import FormatDate from '../Utils/FormatDate'
import { getLocaleLingui } from '../Utils/getLocale'
import { switchViewType, useSlotProperties, viewSchedule, viewSlot } from './SlotProperties'
import ScheduleContentAudit from './ScheduleContentAudit'
import ScheduleContentExtraInfo from './ScheduleContentExtraInfo'
import { ISlotsSchedules } from './useProfessionalSchedules'

export interface IScheduleHeaderProps extends AppointmentTooltip.ContentProps {
  switchView: switchViewType
  editExtraSlotsCount: (id: number, value?: number) => void
  loadingProfessionalsSchedules: boolean
  messageErrorSchedule: string
  appointmentScheduleSlots?: ISlotsSchedules
}

const useStyles = makeStyles(theme => ({
  textTitle: {
    color: theme.palette.action.active,
    fontWeight: 'bold',
    marginLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(0.2)
  },
  cardContent: {
    padding: 0
  },
  textDate: {
    marginLeft: theme.spacing(2.5)
  },
  textCenter: {
    textAlign: 'center'
  }
}))

const ScheduleContent: React.FC<IScheduleHeaderProps> = ({ appointmentData, switchView, ...restProps }) => {
  const classes = useStyles()
  const { statusSlots } = useSlotProperties()
  const { FormatDateDisplaySchedule } = FormatDate()
  const locale = getLocaleLingui()
  const date = format(new Date(appointmentData?.startDate || 0), FormatDateDisplaySchedule, { locale }) || ''

  return (
    <>
      {appointmentData && (
        <>
          <Card>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={0}>
                <Grid container alignItems='center' spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom className={classes.textTitle}>
                      {`${appointmentData.title || ''}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textDate}>
                    <span>{`${date}`}</span>
                  </Grid>
                </Grid>
                <ScheduleContentExtraInfo {...{ appointmentData, switchView, ...restProps }} />
                {switchView === viewSlot && (
                  <Grid container alignItems='center'>
                    <Grid item xs={2} className={classes.textCenter}>
                      <Brightness1Icon style={{ color: statusSlots[Number(appointmentData?.status)].color }} />
                    </Grid>
                    <Grid item xs={10}>
                      <span>{`${statusSlots[Number(appointmentData?.status)].text} `}</span>
                      {appointmentData.observation && <span>{`(${appointmentData.observation as string})`}</span>}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            {switchView === viewSchedule && <ScheduleContentAudit {...{ appointmentData }} />}
          </Card>
        </>
      )}
    </>
  )
}

export default ScheduleContent
