import { Grid, IconButton, TextField, makeStyles } from '@material-ui/core'
import React from 'react'
import SaveIcon from '@material-ui/icons/Save'
import CreateIcon from '@material-ui/icons/Create'

export interface IEditableNumberLabelProps {
  value?: number
  onChange: (newValue?: number) => void
  validateSaved?: (newValue?: number) => boolean
  allowEdition: boolean
}

const useStyles = makeStyles(theme => ({
  textCenter: {
    alignSelf: 'center',
    alignText: 'left'
  },
  textBorder: {
    paddingTop: theme.spacing(1.3125),
    paddingRight: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.3125),
    paddingLeft: theme.spacing(1.75)
  }
}))

const EditableNumberLabel: React.FC<IEditableNumberLabelProps> = ({ value, onChange, validateSaved, allowEdition }) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [newValue, setNewValue] = React.useState<number | undefined>(value)

  const handleNewValue = (event: React.ChangeEvent<{ value: any }>) => {
    setNewValue(Number(event.target.value))
  }

  const handleOnSaved = () => {
    if (newValue !== value) {
      if (validateSaved && !validateSaved(newValue)) {
        return
      }

      onChange(newValue)
    }

    setIsEditing(false)
  }

  const handleOnEdit = () => {
    setIsEditing(true)
  }

  React.useEffect(() => {
    if (value !== newValue) {
      setNewValue(value)
    }
  }, [value])

  return (
    <Grid container>
      <Grid item xs={8} className={classes.textCenter}>
        {!isEditing && (
          <span className={classes.textBorder} data-testid='value-text'>
            {newValue}
          </span>
        )}
        {isEditing && (
          <TextField
            variant='outlined'
            size='small'
            type='number'
            value={newValue}
            onChange={handleNewValue}
            data-testid='value-input'
          />
        )}
      </Grid>
      <Grid item xs={4} className={classes.textCenter}>
        {!isEditing && allowEdition && (
          <IconButton aria-label='edit' onClick={handleOnEdit} size='small' data-testid='edit-button'>
            <CreateIcon />
          </IconButton>
        )}
        {isEditing && (
          <IconButton aria-label='save' onClick={handleOnSaved} size='small' data-testid='save-button'>
            <SaveIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default EditableNumberLabel
