import { t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React from 'react'
import { ScheduleSlotObservation } from '../../../api/api'

interface IAddSlotObservation {
  open: boolean
  handleCancel: () => void
  handleConfirm: () => void
  observations: ScheduleSlotObservation[]
  addObservation: (value: number) => void
}

export const withoutObservation = () => {
  return {
    id: 0,
    observation: t`Sin observación`
  } as ScheduleSlotObservation
}

const AddSlotObservation: React.FC<IAddSlotObservation> = ({
  open,
  handleCancel,
  handleConfirm,
  observations,
  addObservation
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [observationId, setObservationId] = React.useState<number>(withoutObservation().id)
  const radioGroupRef = React.useRef<HTMLElement>(null)
  const [buttonAddDisabled, setButtonAddDisabled] = React.useState(true)

  const handleOk = () => {
    addObservation(observationId)
    handleConfirm()
    setObservationId(withoutObservation().id)
  }

  const handleCancelAddObservation = () => {
    setObservationId(withoutObservation().id)
    handleCancel()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservationId(Number((event.target as HTMLInputElement).value))
  }

  React.useEffect(() => {
    if (observationId !== withoutObservation().id) {
      setButtonAddDisabled(false)
    } else {
      setButtonAddDisabled(true)
    }
  }, [observationId])

  return (
    <>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleCancelAddObservation}
        data-testid='add-slot-observation'
      >
        <DialogTitle id='confirmation-dialog-title'>{t`Agregar Observación`}</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label='observation'
            name='observation'
            value={observationId}
            onChange={handleChange}
            color='primary'
          >
            {observations.map(observationIterator => (
              <FormControlLabel
                value={observationIterator.id}
                key={observationIterator.id}
                control={<Radio />}
                label={observationIterator.observation}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancelAddObservation}
            color='primary'
            data-testid={'test-cancel-observation'}
          >
            {t`Cancelar`}
          </Button>
          <Button
            onClick={handleOk}
            color='primary'
            data-testid={'test-accept-observation'}
            disabled={buttonAddDisabled}
          >
            {t`Aceptar`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddSlotObservation
