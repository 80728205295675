import { t, Trans } from '@lingui/macro'
import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { DateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { format } from 'date-fns'
import * as React from 'react'
import { Exam, Room, ScheduleInput } from '../../../api/api'
import FormatDate from '../Utils/FormatDate'
import ScheduleDetailsExams from './ScheduleDetailsExams'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerScheduleDetail: {
      marginTop: theme.spacing(2.5)
    },
    containerObservations: {
      marginy: theme.spacing(1.2)
    },
    fullwidth: {
      width: '100%'
    },
    selectedRoom: {
      minHeight: theme.spacing(3)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300
    },
    noLabel: {
      marginTop: theme.spacing(3)
    },
    textField: {
      width: theme.spacing(3)
    },
    margin: {
      margin: theme.spacing(1)
    },
    paddingSelect: {
      paddingBottom: theme.spacing(1.25),
      paddingTop: theme.spacing(1.25),
      paddingRight: theme.spacing(3.25),
      paddingLeft: theme.spacing(1.5)
    }
  })
)

export interface ScheduleDetailsProps {
  exams: Exam[]
  rooms: Room[]
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
}

const ScheduleDetails: React.FC<ScheduleDetailsProps> = ({ exams, rooms, newSchedule, setNewSchedule }) => {
  const classes = useStyles()
  const { FormatDateRequests, FormatDateDisplay } = FormatDate()
  const [selectedRoom, setSelectedRoom] = React.useState<Room>({})
  const activationDateField = 'activationDate'
  const handleChangeRoom = (event: React.ChangeEvent<{ value: any }>) => {
    const selected = rooms.find(e => e.id === event.target.value) as Room
    setSelectedRoom(selected)
    setNewSchedule({ ...newSchedule, room_id: selected.id as number })
  }

  const handleChangeDuration = (event: React.ChangeEvent<{ value: any }>) => {
    const duration = Number(event.target.value)
    setNewSchedule({ ...newSchedule, slot_duration_in_min: duration })
  }

  const handleExtras = (event: React.ChangeEvent<{ value: any }>) => {
    const extras = Number(event.target.value)
    setNewSchedule({ ...newSchedule, extra_slots_count: extras })
  }

  const handleObservations = (event: React.ChangeEvent<{ value: string }>) => {
    const obs = event.target.value
    setNewSchedule({ ...newSchedule, observations: obs })
  }

  const handleDatePicker = (field: string, date: MaterialUiPickersDate) => {
    if (field === activationDateField) {
      setNewSchedule({ ...newSchedule, activation_date: format(date as Date, FormatDateRequests) })
    }
  }

  React.useEffect(() => {
    if (newSchedule.activation_date === '') {
      setNewSchedule({ ...newSchedule, activation_date: format(new Date(), FormatDateRequests) })
    }
  }, [])

  return (
    <Grid container={true} spacing={2} className={classes.containerScheduleDetail}>
      <Grid item={true} xs={1} />
      <Grid item={true} xs={4}>
        <Typography>
          <Trans>Fecha de Activación</Trans>*
        </Typography>
      </Grid>
      <Grid item={true} xs={7}>
        <DateTimePicker
          disablePast={true}
          className={classes.fullwidth}
          inputVariant='outlined'
          placeholder={t`Fecha de Activación`}
          format={FormatDateDisplay}
          data-testid='activation-date'
          okLabel={t`Aceptar`}
          value={newSchedule.activation_date === '' ? null : newSchedule.activation_date}
          onChange={(date: MaterialUiPickersDate) => handleDatePicker(activationDateField, date)}
          size='small'
          cancelLabel={t`Cancelar`}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <CalendarTodayIcon data-testid='calendar-icon' />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <ScheduleDetailsExams {...{ newSchedule, setNewSchedule, exams }} />
      <Grid item={true} xs={1} />
      <Grid item={true} xs={4}>
        <Typography>
          <Trans>Consultorio</Trans>*
        </Typography>
      </Grid>
      <Grid item={true} xs={7}>
        <Select
          className={classes.fullwidth}
          id='rooms-select'
          variant='outlined'
          data-testid='rooms-select'
          SelectDisplayProps={{ 'data-testid': 'rooms-select-btn' } as React.HTMLAttributes<HTMLDivElement>}
          value={selectedRoom.id ?? ''}
          onChange={handleChangeRoom}
          inputProps={{ className: classes.paddingSelect }}
        >
          {rooms.map(e => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item={true} xs={1} />
      <Grid item={true} xs={4}>
        <Typography>
          <Trans>Duración de citas</Trans>*
        </Typography>
      </Grid>
      <Grid item={true} xs={7}>
        <TextField
          type='number'
          className={classes.fullwidth}
          data-testid='duration-textfield'
          name='duration'
          variant='outlined'
          size='small'
          value={newSchedule.slot_duration_in_min}
          onChange={handleChangeDuration}
        />
      </Grid>
      <Grid item={true} xs={1} />
      <Grid item={true} xs={4}>
        <Typography>
          <Trans>Citas extras</Trans>
        </Typography>
      </Grid>
      <Grid item={true} xs={7}>
        <TextField
          className={classes.fullwidth}
          type='number'
          name='extras'
          data-testid='extras-textfield'
          onChange={handleExtras}
          value={newSchedule.extra_slots_count}
          variant='outlined'
          size='small'
        />
      </Grid>
      <Grid className={classes.containerObservations} item={true} xs={1} />
      <Grid className={classes.containerObservations} item={true} xs={11}>
        <TextField
          label={t`Observaciones`}
          className={classes.fullwidth}
          data-testid='observations-textfield'
          name='description'
          variant='outlined'
          size='small'
          value={newSchedule.observations}
          onChange={handleObservations}
        />
      </Grid>
    </Grid>
  )
}

export default ScheduleDetails
