import { t } from '@lingui/macro'
import TextField from '@material-ui/core/TextField'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { FilterOptionsState } from '@material-ui/lab'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { format, isValid, parse, roundToNearestMinutes } from 'date-fns'
import React from 'react'
import FormatDate from '../FormatDate'

export interface ITimePickerProps {
  intervalInMinutes?: number
  startInCurrentTime: boolean
  className?: string
  placeholder?: string
  size?: 'small' | 'medium'
  variant?: 'standard' | 'filled' | 'outlined'
  onChange: (value: Date | null) => void
  value: Date | null
}

interface ITimeElement {
  title: string
}

const minutesInHour = 60
const hoursOfDay = 24
const minutesOfDay = hoursOfDay * minutesInHour
const minuteInMiliseconds = 60 * 1000
const roundDateMin = 1
const roundDateMax = 30
const defaultValue = ''
export const defaultIntervalInMinutes = minutesInHour
export const defaultDate = new Date(0)

const TimePicker: React.FC<ITimePickerProps> = ({
  intervalInMinutes,
  startInCurrentTime,
  onChange,
  value,
  ...restProps
}) => {
  const { formatHour } = FormatDate()
  const filter = createFilterOptions<string>()
  const [selectedHour, setSelectedHour] = React.useState<string>(defaultValue)
  const [selectedHourDate, setSelectedHourDate] = React.useState<Date | null>(null)
  const [isloading, setIsloading] = React.useState<boolean>(false)
  const [search, setSearch] = React.useState<string>(defaultValue)

  React.useEffect(() => {
    const parseSelectedHour = parse(selectedHour, formatHour, defaultDate)
    if (selectedHour === defaultValue || !isValid(parseSelectedHour)) {
      onChange(null)
      setSelectedHourDate(null)
      setSelectedHour(defaultValue)
      return
    }
    onChange(parseSelectedHour)
    setSelectedHourDate(parseSelectedHour)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHour])

  React.useEffect(() => {
    if (selectedHourDate !== value) {
      if (value) {
        setSelectedHourDate(value)
        setSelectedHour(dateToTimeElement(value).title)
      } else {
        setSelectedHourDate(null)
        setSelectedHour(defaultValue)
      }
    }
  }, [value])

  React.useEffect(() => {
    if (!isloading) {
      setSelectedHourDate(null)
      setSelectedHour(defaultValue)
      setIsloading(true)
    }
  }, [isloading])

  const showHours = () => {
    if (!intervalInMinutes) {
      intervalInMinutes = defaultIntervalInMinutes
    }

    return getHoursMinutes(intervalInMinutes)
  }

  const getHoursMinutes = (interval: number) => {
    let startTime = new Date()

    if (!startInCurrentTime) {
      startTime.setHours(0, 0, 0, 0)
    } else if (interval >= roundDateMin && interval <= roundDateMax) {
      startTime = roundToNearestMinutes(startTime, { nearestTo: interval })
    } else {
      startTime.setMinutes(0, 0, 0)
    }

    const timeElements: ITimeElement[] = []
    const timeElementsCount = Math.round(minutesOfDay / interval)
    for (let i = 0; i < timeElementsCount; i++) {
      const suggestHour = new Date(startTime.getTime() + i * interval * minuteInMiliseconds)
      timeElements.push(dateToTimeElement(suggestHour))
    }

    return timeElements
  }

  const dateToTimeElement = (date: Date) => {
    return { title: format(date, formatHour) }
  }

  const handleValue = (event: any, newValue: string | null) => {
    setSelectedHour(newValue || defaultValue)
  }

  const handleSearchValue = (event: any, newValue: string | null) => {
    setSearch(newValue || defaultValue)
  }

  const handleOnBlur = () => {
    const searchValue = search.toUpperCase()
    const parseDate = parse(searchValue, formatHour, defaultDate)
    if (isValid(parseDate)) {
      const dateCorrectValue = dateToTimeElement(parseDate).title
      setSelectedHour(dateCorrectValue)
      setSearch(dateCorrectValue)
      return
    }

    setSelectedHour(defaultValue)
    setSearch(defaultValue)
  }

  const handleFilter = (options: string[], params: FilterOptionsState<string>) => {
    let filtered = filter(options, params)

    if (params.inputValue !== defaultValue && filtered.length === 0) {
      filtered = filter(
        getHoursMinutes(roundDateMin).map(v => v.title),
        params
      )
    }

    if (
      params.inputValue === defaultValue &&
      selectedHour !== defaultValue &&
      !filtered.find(item => item === selectedHour)
    ) {
      filtered.unshift(selectedHour)
    }

    return filtered
  }

  return (
    <Autocomplete
      freeSolo
      value={selectedHour}
      noOptionsText={t`Sin opciones`}
      options={showHours().map(option => option.title)}
      onChange={handleValue}
      data-testid='autocomplete-field'
      onInputChange={handleSearchValue}
      inputValue={search}
      popupIcon={<AccessTimeIcon data-testid='autocomplete-btn' />}
      forcePopupIcon={true}
      onBlur={handleOnBlur}
      renderInput={params => <TextField {...params} {...restProps} />}
      filterOptions={handleFilter}
      autoHighlight={true}
    />
  )
}

export default TimePicker
