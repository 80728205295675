import { t } from '@lingui/macro'
import { Button, ButtonGroup, Grid, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BlurLinearIcon from '@material-ui/icons/BlurLinear'
import DehazeIcon from '@material-ui/icons/Dehaze'
import React from 'react'
import { switchViewType, viewSchedule, viewSlot } from './SlotProperties'
import { viewNameMonth } from './useMedicalScheduler'

const useStyles = makeStyles(theme => ({
  icons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(-1)
    }
  }
}))
interface SwitchScheduleViewProps {
  switchView: switchViewType
  setSwitchView: (value: switchViewType) => void
  currentViewName: string
}

const SwitchScheduleView: React.FC<SwitchScheduleViewProps> = ({ switchView, setSwitchView, currentViewName }) => {
  const classes = useStyles()
  React.useEffect(() => {
    if (currentViewName === viewNameMonth) {
      setSwitchView(viewSchedule)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewName])

  return (
    <>
      <Grid container={true} item={true} xs={true} justifyContent='flex-end' data-testid={'test-switch-schedule'}>
        <Grid item={true}>
          <ButtonGroup aria-label='outlined primary button group'>
            <Button
              {...(switchView === viewSchedule ? { disabled: true } : '')}
              startIcon={<BlurLinearIcon className={classes.icons} />}
              onClick={() => setSwitchView(viewSchedule)}
              data-testid={'test-switch-view-schedule'}
            >
              <Hidden smDown={true}>{t`Franja`}</Hidden>
            </Button>
            {currentViewName !== viewNameMonth && (
              <Button
                {...(switchView === viewSlot ? { disabled: true } : '')}
                startIcon={<DehazeIcon className={classes.icons} />}
                onClick={() => setSwitchView(viewSlot)}
                data-testid={'test-switch-view-slot'}
              >
                <Hidden smDown={true}>{t`Disponibilidad`}</Hidden>
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
    </>
  )
}
export default SwitchScheduleView
