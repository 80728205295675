import { Divider, IconButton, List, ListItem, ListItemText, Paper, Popover } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React, { useCallback, useEffect } from 'react'
import Notification from '../../models/Notification'

interface NotificationBadgetProps {
  notifications: Notification[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(1)
    },
    margin: {
      margin: theme.spacing.length * 2
    },
    icon: {
      color: theme.palette.primary.contrastText
    },
    list: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: 'inline'
    }
  })
)

type notificationType = Notification | null

const NotificationBadget: React.FunctionComponent<NotificationBadgetProps> = props => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [counter, setCounter] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    props.notifications.forEach((notification: notificationType) => {
      if (notification) {
        notification.checked = true
      }
    })
    getNotificationsAmount(false)
    setOpen(!open)
  }

  const getNotificationsAmount = useCallback(
    (disablePopover = true) => {
      const uncheckedNotifications = props.notifications.filter(
        (notification: notificationType) => notification?.checked === false
      )
      setCounter(uncheckedNotifications.length)

      if (disablePopover === true) {
        setAnchorEl(null)
      }
    },
    [props.notifications, setAnchorEl, setCounter]
  )

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  useEffect(() => {
    getNotificationsAmount()
  }, [props.notifications, getNotificationsAmount])

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClose}>
        <IconButton
          data-testid='appbar-notification-icon-button'
          id='appbar-notifications-icon'
          edge='start'
          className={classes.menuButton}
          color='inherit'
          aria-label='menu'
          onClick={handleClick}
        >
          <React.Fragment>
            {counter > 0 ? (
              <Badge
                className={classes.margin}
                badgeContent={counter}
                color='error'
                data-testid='appbar-notification-badget'
                overlap='rectangular'
              >
                <NotificationsIcon className={classes.icon} data-testid='appbar-notification-icon' />
              </Badge>
            ) : (
              <NotificationsIcon className={classes.icon} data-testid='appbar-icon-button' />
            )}
            {props.notifications.length > 0 && (
              <Popover
                id={id}
                data-testid='appbar-notification-popover'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Paper>
                  <List className={classes.list} data-testid='appbar-list'>
                    {props.notifications.map((notification: notificationType, index) => (
                      <React.Fragment key={`notifyfication${notification?.id || ''}`}>
                        <ListItem
                          data-testid='appbar-listitem'
                          button={true}
                          key={`notificationItem${notification?.id || ''}`}
                          alignItems='flex-start'
                        >
                          <ListItemText
                            data-testid='appbar-listitemtext'
                            primary={notification?.title}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  data-testid='appbar-typography'
                                  component='span'
                                  variant='caption'
                                  className={classes.inline}
                                  color='textSecondary'
                                >
                                  {notification?.helpText}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        {index + 1 === props.notifications.length ? (
                          ''
                        ) : (
                          <Divider
                            key={`notificationDivider${notification?.id || ''}`}
                            variant='inset'
                            component='li'
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Popover>
            )}
          </React.Fragment>
        </IconButton>
      </ClickAwayListener>
    </React.Fragment>
  )
}
export default NotificationBadget
