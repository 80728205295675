import React, { useEffect } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import {
  GoogleAuthGenerate,
  RequestGoogleAuthValidate,
  useGetGenerateTwoFactorAuth,
  useGetValidateEmail,
  useGetValidatecodeTwoFactorAuth
} from '../../../../api/api'
import { useAPISecurity } from '../../../../security/APISecurityContext'

const defaultConfigGoogleAuth: GoogleAuthGenerate = { QrCode: '', Code: '', MaskEmail: '' }
const milliSecondsToSeconds = 1000

export const useAddTwoFactorGoogle = (setErrorMessage: (message: string) => void) => {
  const [configGoogleAuth, setConfigGoogleAuth] = React.useState<GoogleAuthGenerate>(defaultConfigGoogleAuth)
  const addTwoFactorAuth = useGetGenerateTwoFactorAuth({})
  const validateFactorAuth = useGetValidatecodeTwoFactorAuth({})
  const { handleCloseSession, setSecurityTokens } = useAPISecurity()

  const [code, setCode] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [isMailValidated, setIsMailValidated] = React.useState(false)
  const validateEmailUser = useGetValidateEmail({ queryParams: { email }, lazy: true })

  useEffect(() => {
    if (!addTwoFactorAuth.loading && addTwoFactorAuth.data) {
      setConfigGoogleAuth(addTwoFactorAuth.data)
    }
  }, [addTwoFactorAuth.data, addTwoFactorAuth.loading])

  const handleOnChangeCode = (res: string) => {
    setCode(res)
  }
  const handleOnChangeEmail = (res: string) => {
    setEmail(res)
  }

  const handValidateEmail = () => {
    setErrorMessage('')
    void validateEmailUser
      .refetch()
      .then(response => {
        if (response !== undefined && response !== null && response.validate) {
          setIsMailValidated(true)
        } else {
          setErrorMessage('Correo ingresado es incorrecto, intente de nuevo')
        }
      })
      .catch(() => {
        setErrorMessage('Correo ingresado es incorrecto, intente de nuevo')
      })
  }
  const handleValidate = () => {
    const validatecode: RequestGoogleAuthValidate = {
      code,
      firstLogin: true
    }
    validateFactorAuth
      .mutate(validatecode)
      .then(tokens => {
        setErrorMessage('')
        try {
          const jwt = jwtDecode<JwtPayload>(tokens.access_token ?? '')
          if (jwt.iat) {
            const timeDifference = Math.floor(Date.now() / milliSecondsToSeconds) - jwt.iat

            setSecurityTokens({ accessToken: tokens.access_token, refreshToken: tokens.refresh_token, timeDifference })
          }
        } catch (error) {
          setErrorMessage('Código de validación es incorrecto, intente de nuevo')
        }
      })
      .catch(() => {
        setErrorMessage('Código de validación es incorrecto, intente de nuevo')
      })
  }

  const isLoading = addTwoFactorAuth.loading || validateFactorAuth.loading || validateEmailUser.loading

  return {
    handleOnChangeCode,
    handleValidate,
    handleOnChangeEmail,
    code,
    email,
    configGoogleAuth,
    isLoading,
    handValidateEmail,
    isMailValidated,
    handleCloseSession
  }
}
