import { Chip, DialogTitle, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import blue from '@material-ui/core/colors/blue'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import CloseIcon from '@material-ui/icons/Close'
import RoomIcon from '@material-ui/icons/Room'
import format from 'date-fns/format'
import React from 'react'
import { Exam, Professional, Schedule } from '../../../api/api'
import FormatDate from '../Utils/FormatDate'
import { getLocale } from '../Utils/getLocale'
const { FormatDateDisplayMaintenance, formatHour } = FormatDate()

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(-0.2)
  },
  subtitle: {
    marginBottom: theme.spacing(0)
  },
  iconButtonClosed: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(-0.2),
    marginRight: theme.spacing(-3),
    marginTop: theme.spacing(-2.5)
  },
  iconClosed: {
    fontSize: theme.spacing(2)
  },
  chipExam: {
    marginRight: theme.spacing(0.3),
    margingLeft: theme.spacing(0.3),
    color: 'white',
    background: blue[300]
  }
}))

const showHour = (date: string) => {
  return format(new Date(date), formatHour)
}

export interface ScheduleMaintenanceHeaderProps {
  currentProfessional: Professional | null
  handleClose: () => void
  schedule: Schedule
}
const ScheduleMaintenanceHeader: React.FC<ScheduleMaintenanceHeaderProps> = ({
  currentProfessional,
  schedule,
  handleClose
}) => {
  const classes = useStyles()
  const locale = getLocale()
  const date = format(new Date(schedule.start_date), FormatDateDisplayMaintenance, { locale }) || ''
  const timeRange = showHour(schedule.start_date) + ' - ' + showHour(schedule.end_date)

  return (
    <DialogTitle data-testid='test-header-maintenance'>
      <Grid container={true} direction='row' justifyContent='space-between'>
        <Grid item={true} xs={11} container={true} direction='column'>
          <Typography variant='h5' data-testid='test-name-professional'>
            {currentProfessional?.full_name}
          </Typography>
          <Typography
            variant='caption'
            display='block'
            gutterBottom={true}
            className={classes.subtitle}
            data-testid='test-date-schedule'
          >
            <CalendarTodayIcon className={classes.icon} /> {date} {timeRange}
          </Typography>
          <Typography
            variant='caption'
            display='block'
            gutterBottom={true}
            className={classes.subtitle}
            data-testid='test-room-schedule'
          >
            <RoomIcon className={classes.icon} /> {schedule.room.name}
          </Typography>
        </Grid>
        <Grid item={true} xs={1} container={true} direction='row' justifyContent='flex-end' alignItems='flex-start'>
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='span'
            className={classes.iconButtonClosed}
            onClick={handleClose}
            data-testid='test-close-schedule'
          >
            <CloseIcon className={classes.iconClosed} />
          </IconButton>
        </Grid>
        <Grid item={true} xs={12} data-testid='test-exams-schedule'>
          {schedule.exams.map((exam: Exam) => {
            return <Chip key={exam.id} size='small' label={exam.name} className={classes.chipExam} />
          })}
        </Grid>
      </Grid>
    </DialogTitle>
  )
}
export default ScheduleMaintenanceHeader
