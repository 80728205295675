import React from 'react'
import ChangePasswordPublicForm, { ChangePasswordPublicFormProps } from './ChangePasswordPublicForm'
import useChangePasswordPublic from './useChangePasswordPublic'

const ChangePasswordPublicFormContainer: React.FC = () => {
  const changePasswordPublicFormProps = useChangePasswordPublic() as ChangePasswordPublicFormProps
  return <ChangePasswordPublicForm {...changePasswordPublicFormProps} />
}

export default ChangePasswordPublicFormContainer
