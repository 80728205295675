import React from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { RequestGoogleAuthValidate, useGetValidatecodeTwoFactorAuth } from '../../../../api/api'
import { useAPISecurity } from '../../../../security/APISecurityContext'

const milliSecondsToSeconds = 1000

export const useValidateTwoFactorGoogle = (setErrorMessage: (message: string) => void) => {
  const validateFactorAuth = useGetValidatecodeTwoFactorAuth({})
  const { setIsAuthenticated, setSecurityTokens } = useAPISecurity()
  const [code, setCode] = React.useState('')
  const handleOnChangeCode = (res: string) => {
    setCode(res)
  }

  const handleCloseSession = () => {
    setSecurityTokens({ accessToken: '', refreshToken: '', timeDifference: 0 })
    setIsAuthenticated(false)
  }

  const handleValidate = async () => {
    const validatecode: RequestGoogleAuthValidate = {
      code,
      firstLogin: false
    }

    await validateFactorAuth
      .mutate(validatecode)
      .then(tokens => {
        setErrorMessage('')
        try {
          const jwt = jwtDecode<JwtPayload>(tokens.access_token ?? '')
          if (jwt.iat) {
            const timeDifference = Math.floor(Date.now() / milliSecondsToSeconds) - jwt.iat

            setSecurityTokens({ accessToken: tokens.access_token, refreshToken: tokens.refresh_token, timeDifference })
          }
        } catch (error) {
          setErrorMessage('Código de validación es incorrecto, intente de nuevo')
        }
      })
      .catch(() => {
        if (validateFactorAuth.error?.status === 401) {
          handleCloseSession()
        }
        setErrorMessage('Código de validación es incorrecto, intente de nuevo')
      })
  }

  const isLoading = validateFactorAuth.loading

  return { code, handleValidate, isLoading, handleOnChangeCode, handleCloseSession }
}
