import { t } from '@lingui/macro'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React, { FunctionComponent } from 'react'
import Document from '../../models/Document'
import Publication from '../../models/Publication'
import UserInformation from '../../models/UserInformation'
import { FeaturedPostProps } from './FeaturedPost'
import FeaturedPosts from './FeaturedPosts'
import Footer from './Footer'
import MainFeaturedPost, { MainFeaturedPostProps } from './MainFeaturedPost'
import MainSection from './MainSection'

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(3.75)
  }
}))

const defaultMainFeaturedPost: MainFeaturedPostProps = {
  title: t`Bienvenido a iMedical`,
  description: t({
    id: 'iMedical-Intro',
    message:
      'iMedicalServices es una compañia dedicada a brindar servicios y soluciones integrales en el sector salud, en modalidad de software como servicio y analitica de datos para EPS o IPS.'
  }),
  image: '',
  imageText: 'main image description',
  linkText: '',
  creator: '',
  createdAt: new Date()
}

export interface MainPageProps {
  publications?: Publication[]
  documents?: Document[]
  userInfo?: UserInformation
}

const MainPage: FunctionComponent<MainPageProps> = (props: MainPageProps) => {
  const classes = useStyles()

  const getMainFeaturedPost = () => {
    if (props.publications && props.publications.length > 0) {
      return {
        title: props.publications[0].title,
        description: props.publications[0].content,
        image: '',
        imageText: '',
        linkText: '',
        creator: props.publications[0].creator,
        createdAt: new Date(props.publications[0].date)
      }
    }

    return defaultMainFeaturedPost
  }

  const getFeaturedPosts = () => {
    const featuredPosts: FeaturedPostProps[] = []

    if (props.publications && props.publications.length > 1) {
      for (let index = 1; index < props.publications.length; index++) {
        const publication: FeaturedPostProps = {
          id: props.publications[index].id,
          title: props.publications[index].title,
          content: props.publications[index].content,
          imageURL: '',
          imageTitle: '',
          creator: props.publications[index].creator,
          createdAt: new Date(props.publications[index].date)
        }

        featuredPosts.push(publication)
      }
    }

    return featuredPosts
  }

  return (
    <Container maxWidth='lg' className={classes.content}>
      <main>
        <MainFeaturedPost {...getMainFeaturedPost()} />
        <FeaturedPosts featuredPosts={getFeaturedPosts()} />
        <MainSection title={`Documentos`} posts={props.documents} />
      </main>
      <Footer title='iMedicalCloud' description={t`Health Information Cloud System`} userInformation={props.userInfo} />
    </Container>
  )
}

export default MainPage
