import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const currentDateParamName = 'current_date'
const slash = '/'

const getCurrentDateFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const currentDateString = searchParams.get(currentDateParamName)

  if (currentDateString && Date.parse(currentDateString)) {
    return new Date(currentDateString)
  }

  return new Date()
}

function addTrailingSlash(url: string) {
  url += url.endsWith(slash) ? '' : slash
  return url
}

export const useCurrentDate = () => {
  const history = useHistory()
  const [currentDate, setCurrentDate] = React.useState(getCurrentDateFromURL())

  useEffect(() => {
    const pathname = addTrailingSlash(window.location.pathname)
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(currentDateParamName, currentDate.toDateString())

    history.replace({
      pathname,
      search: searchParams.toString()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate])

  return { currentDate, setCurrentDate }
}
