import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FunctionComponent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainFeaturedPost: {
      position: 'relative',
      marginBottom: theme.spacing(4),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderBottom: '0.4em solid',
      color: theme.palette.secondary.light,
      borderRadius: '0em'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    },
    mainFeaturedPostContent: {
      position: 'relative',
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
        paddingRight: 0
      }
    },
    contentText: {
      color: theme.palette.text.primary
    },
    contentContrastText: {
      color: theme.palette.primary.contrastText
    },
    divider: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(4),
      width: theme.spacing(40),
      height: '0.4em',
      borderBottom: '0.4em solid',
      color: theme.palette.primary.main
    }
  })
)

export interface MainFeaturedPostProps {
  image: string
  imageText: string
  title: string
  description: string
  linkText: string
  creator: string
  createdAt: Date
}

const MainFeaturedPost: FunctionComponent<MainFeaturedPostProps> = props => {
  const classes = useStyles()

  const formatDescription = () => {
    const lineas = props.description.split(/\r?\n/).filter(Boolean)
    return (
      <div>
        {lineas.map(item => (
          <p key={item}> {item} </p>
        ))}
      </div>
    )
  }

  return (
    <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${props.image})` }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={props.image} alt={props.imageText} />}
      <div className={classes.overlay} />
      <Grid container={true}>
        <Grid item={true} md={10}>
          <div className={classes.mainFeaturedPostContent}>
            <Typography variant='h4' color='secondary' gutterBottom={true}>
              {props.title}
            </Typography>
            <Box className={classes.divider} />
            <Box className={props.image === '' ? classes.contentText : classes.contentContrastText}>
              {formatDescription()}
            </Box>
            <Link variant='subtitle1' href='#'>
              {props.linkText}
            </Link>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MainFeaturedPost
