import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { FunctionComponent } from 'react'

type DialogClose = () => void

export interface SimpleDialogProps {
  open: boolean
  title: string
  contentText: string
  dialogActions: React.ReactNode
  dialogClose: DialogClose
}

const SimpleDialog: FunctionComponent<SimpleDialogProps> = props => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  if (!props.open) {
    return null
  }

  return (
    <Dialog
      data-testid='simple-dialog'
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.dialogClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>{props.dialogActions}</DialogActions>
    </Dialog>
  )
}

export default SimpleDialog
