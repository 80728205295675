import { i18n, Messages } from '@lingui/core'
import { detect, fromNavigator } from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import { en, es } from 'make-plural/plurals'
import React, { FC, ReactNode, useEffect } from 'react'
import { messages as messages_en } from './en/messages'
import { messages as messages_es } from './es/messages'

interface Props {
  children?: ReactNode
  locale?: string
}

export const I18nIMedical: FC<Props> = ({ children, locale }) => {
  const defaultFallback = 'es'
  const englishLocale = 'en'
  const [isLoading, setIsLoading] = React.useState(false)

  const browserLang = () => {
    if (locale) {
      return locale
    }

    const detectedLocale = detect(fromNavigator(), defaultFallback)
    if (detectedLocale === null) {
      return defaultFallback
    }

    return detectedLocale.substring(0, 2)
  }
  useEffect(() => {
    if (!isLoading) {
      if (browserLang() === englishLocale) {
        i18n.loadLocaleData(defaultFallback, { plurals: en })
        i18n.load(englishLocale, messages_en as Messages)
        i18n.activate(englishLocale)
      } else {
        i18n.loadLocaleData(defaultFallback, { plurals: es })
        i18n.load(defaultFallback, messages_es as Messages)
        i18n.activate(defaultFallback)
      }
      setIsLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return isLoading ? <I18nProvider i18n={i18n}>{children}</I18nProvider> : <></>
}
