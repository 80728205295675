import { Trans } from '@lingui/macro'
import { makeStyles, Theme, Typography, Button, CircularProgress, Backdrop, TextField, Link } from '@material-ui/core'
import React from 'react'
import OtpInput from 'react18-otp-input'
import './addTwoFactorAuthStyle.css'
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined'
import { green } from '@material-ui/core/colors'
import { GoogleAuthGenerate } from '../../../../api/api'
import { ReactComponent as LogoIos } from './iconApple24x24.svg'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  submit: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  cancel: {
    margin: theme.spacing(1, 0, 2, 0),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: theme.palette.error.contrastText
  }
}))

export interface IAddTwoFactorAuthGoogleProps {
  configGoogleAuth: GoogleAuthGenerate
  code: string
  email: string
  handleOnChangeCode: (value: string) => void
  handleOnChangeEmail: (value: string) => void
  handleValidate: () => void
  isLoading: boolean
  handValidateEmail: () => void
  isMailValidated: boolean
  handleCloseSession: () => void
}

const AddTwoFactorAuthGoogle: React.FC<IAddTwoFactorAuthGoogleProps> = props => {
  const classes = useStyles()

  const handleOnClick = () => {
    if (props.isMailValidated) {
      props.handleValidate()
    } else {
      props.handValidateEmail()
    }
  }

  return (
    <>
      <Backdrop
        id='main-backdrop-load'
        data-testid='backdrop-load-two-factor'
        open={props.isLoading}
        className={classes.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <form className={classes.form} noValidate={true}>
        <Typography variant='h5' gutterBottom color={'primary'}>
          <Trans>2FA - Segundo Factor de Autenticación</Trans>
        </Typography>
        <Typography variant='body1' gutterBottom>
          <Trans>Su entidad ha requerido un segundo factor de autenticación como política de ingreso al sistema.</Trans>
        </Typography>
        {!props.isMailValidated && (
          <>
            <Typography variant='body1' gutterBottom>
              <Trans>Por favor ingrese el correo asociado</Trans>
              {`${props?.configGoogleAuth.MaskEmail ?? ''}`}
            </Typography>
            <TextField
              variant='outlined'
              size='small'
              margin='normal'
              required={true}
              fullWidth={true}
              id='email'
              label={'Email'}
              name='email'
              autoFocus={true}
              value={props.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.handleOnChangeEmail(event.target.value)
              }}
            />
          </>
        )}
        {props.isMailValidated && (
          <>
            {props.configGoogleAuth.QrCode !== '' && (
              <img src={props.configGoogleAuth.QrCode} alt={'QR Code Google Auth'} />
            )}
            <Typography variant='body1' gutterBottom>
              <Trans>Por favor, escanea el código QR utilizando la aplicación Google Authenticator.</Trans>
            </Typography>
            <OtpInput
              inputStyle='inputStyle'
              numInputs={6}
              onChange={props.handleOnChangeCode}
              separator={<span>-</span>}
              isInputNum={true}
              shouldAutoFocus
              value={props.code}
            />{' '}
          </>
        )}

        <Button
          id='enter-button'
          data-testid='button-submit'
          onClick={handleOnClick}
          fullWidth={true}
          variant='contained'
          color='primary'
          className={classes.submit}
          disabled={
            (props.isMailValidated && props.code.length !== 6) || (!props.isMailValidated && props.email === '')
          }
        >
          <Trans>Validar</Trans>
        </Button>
        <Button
          id='enter-button'
          data-testid='button-cancel'
          onClick={props.handleCloseSession}
          fullWidth={true}
          variant='contained'
          color='secondary'
          className={classes.cancel}
        >
          <Trans>Cancelar</Trans>
        </Button>
        {props.isMailValidated && (
          <Typography variant='body1' gutterBottom>
            Descarga la aplicación para&nbsp;
            <Link href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'>
              <AndroidOutlinedIcon style={{ color: green[500] }} /> Android&nbsp;
            </Link>
            y para&nbsp;
            <Link href='https://apps.apple.com/es/app/google-authenticator/id388497605'>
              <LogoIos />
              iOS
            </Link>
            .
          </Typography>
        )}
      </form>
    </>
  )
}

export default AddTwoFactorAuthGoogle
