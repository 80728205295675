import { useEffect, useState } from 'react'
import { useIdle } from 'react-use'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { SecurityTokens, usePutToken } from '../api/api'
import { SecurityTokens as Security, useAPISecurity } from './APISecurityContext'

const everyMinute = 60000
const minute = 60
const milliSecondsToSeconds = 1000
const everyTenMinute = 600000

const needRefreshSecurityTokens = (securityToken: Security) => {
  if (
    securityToken.accessToken &&
    securityToken.accessToken !== '' &&
    securityToken.refreshToken &&
    securityToken.refreshToken !== ''
  ) {
    const jwtRefresh = jwtDecode<JwtPayload>(securityToken.refreshToken)

    const currentTime = Math.floor(Date.now() / milliSecondsToSeconds) - (securityToken.timeDifference ?? 0)
    const nearTokenExpirationTime = currentTime + 2 * minute
    if (jwtRefresh.exp && nearTokenExpirationTime > jwtRefresh.exp) {
      return true
    }
  }

  return false
}

function useAutoRefreshTokens(timeout: number = everyMinute, idleTimeOut: number = everyTenMinute) {
  const { securityTokens, setSecurityTokens } = useAPISecurity()
  const [errorRefreshingTokens, setErrorRefreshingTokens] = useState(false)
  const refreshTokenApi = usePutToken({})
  const idle = useIdle(idleTimeOut)

  useEffect(() => {
    if (idle) {
      setErrorRefreshingTokens(true)
    }
  })

  useEffect(() => {
    if (securityTokens.accessToken === '' || securityTokens.refreshToken === '') {
      setErrorRefreshingTokens(true)
      return
    }

    const intervalId = setInterval(() => {
      if (!idle && needRefreshSecurityTokens(securityTokens)) {
        refreshTokenApi
          .mutate({ refresh_token: securityTokens.refreshToken })
          .then((value: SecurityTokens) => {
            setSecurityTokens({ ...securityTokens, accessToken: value.access_token, refreshToken: value.refresh_token })
          })
          .catch(() => {
            setErrorRefreshingTokens(true)
            clearInterval(intervalId)
          })
      }
    }, timeout)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityTokens])

  return errorRefreshingTokens
}

export default useAutoRefreshTokens
