import { Trans } from '@lingui/macro'
import {
  Checkbox,
  Chip,
  createStyles,
  Grid,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import * as React from 'react'
import { Exam, ScheduleInput } from '../../../api/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullwidth: {
      width: '100%'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      color: theme.palette.secondary.contrastText,
      marginRight: theme.spacing(0.5)
    },
    paddingSelect: {
      paddingBottom: theme.spacing(1.25),
      paddingTop: theme.spacing(1.25),
      paddingRight: theme.spacing(3.25),
      paddingLeft: theme.spacing(1.5)
    }
  })
)

const emptyArray = 0
const ItemHeight = 48
const ItemPaddingTop = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
      width: 250
    }
  }
}

export interface IScheduleDetailsExamsProps {
  newSchedule: ScheduleInput
  setNewSchedule: (schedule: ScheduleInput) => void
  exams: Exam[]
}

const ScheduleDetailsExams = ({ newSchedule, setNewSchedule, exams }: IScheduleDetailsExamsProps) => {
  const classes = useStyles()
  const [selectedExams, setSelectedExams] = React.useState<Exam[]>([])
  const [groupAppointment, setGroupAppointment] = React.useState<boolean>(false)
  const [webAppointment, setWebAppointment] = React.useState<boolean>(false)
  const [individualExams, setIndividualExams] = React.useState<Exam[]>([])
  const [groupExams, setGroupExams] = React.useState<Exam[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChangeExams = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectExamIds = event.target.value as string[]
    const selectedId = Number(selectExamIds[selectExamIds.length - 1])
    const selected = exams.find(examIterator => examIterator.id === selectedId)
    if (!selected) {
      return
    }

    const exist = selectedExams.find(examIterator => examIterator.id === selected.id) ?? false
    if (exist) {
      setSelectedExams([...selectedExams.filter(e => e.id !== selected.id)])
      setNewSchedule({ ...newSchedule, exam_ids: [...newSchedule.exam_ids.filter(e => e !== selected.id)] })
    } else {
      setSelectedExams([...selectedExams, selected])
      setNewSchedule({ ...newSchedule, exam_ids: [...newSchedule.exam_ids, selected.id as number] })
    }
  }

  const handleChangeQuantityPatients = (event: React.ChangeEvent<{ value: any }>) => {
    const numberPatients = Number(event.target.value)
    setNewSchedule({ ...newSchedule, quantity_patients: numberPatients })
  }

  const handleWebAppointment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSchedule({ ...newSchedule, web_appointment: event.target.checked })
  }

  React.useEffect(() => {
    if (isLoading) {
      const tempGroupExams: Exam[] = []
      const tempIndividualExams: Exam[] = []
      exams.map((exam: Exam) => {
        if (exam.group_appointment) {
          tempGroupExams.push(exam)
        } else {
          tempIndividualExams.push(exam)
        }
      })

      setIndividualExams([...tempIndividualExams])
      setGroupExams([...tempGroupExams])
    }
  }, [exams, isLoading])

  React.useEffect(() => {
    setGroupAppointment(selectedExams.filter(exam => exam.group_appointment).length > emptyArray)
    setWebAppointment(selectedExams.length > emptyArray && selectedExams.every(exam => exam.web_appointment))
  }, [selectedExams])

  React.useEffect(() => {
    if (!isLoading) {
      setIsLoading(true)
    }
  }, [isLoading])

  React.useEffect(() => {
    if (!webAppointment) {
      setNewSchedule({ ...newSchedule, web_appointment: false })
    }
  }, [webAppointment])

  const examMenuItem = (exam: Exam) => (
    <MenuItem key={exam.id} value={exam.id} disabled={examDisabledStatus(exam)}>
      {!exam.group_appointment && (
        <Checkbox checked={!!selectedExams.find(examIterator => examIterator.id === exam.id)} />
      )}
      <ListItemText primary={exam.name} />
    </MenuItem>
  )

  const examDisabledStatus = (exam: Exam) =>
    selectedExams.length > 0 &&
    ((groupAppointment && selectedExams.filter(examIterator => examIterator.id === exam.id).length === 0) ||
      (!groupAppointment && (exam.group_appointment as boolean)))

  return (
    <>
      <Grid item={true} xs={1} />
      <Grid item={true} xs={4}>
        <Typography>
          <Trans>Exámenes</Trans>*
        </Typography>
      </Grid>
      <Grid item={true} xs={7}>
        <Select
          className={classes.fullwidth}
          data-testid='exams-select'
          SelectDisplayProps={{ 'data-testid': 'exams-select-btn' } as React.HTMLAttributes<HTMLDivElement>}
          labelId='exams'
          id='exams-select'
          multiple={true}
          variant='outlined'
          value={selectedExams}
          onChange={handleChangeExams}
          inputProps={{ className: classes.paddingSelect }}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as Exam[]).map(value => (
                <Chip key={value.id} label={value.name} className={classes.chip} color='secondary' />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {groupExams.length > 0 && individualExams.length > 0 && (
            <ListSubheader disableSticky={true}>
              <Trans>Exámenes grupales</Trans>
            </ListSubheader>
          )}
          {groupExams.map(examMenuItem)}
          {groupExams.length > 0 && individualExams.length > 0 && (
            <ListSubheader disableSticky={true}>
              <Trans>Exámenes individuales</Trans>
            </ListSubheader>
          )}
          {individualExams.map(examMenuItem)}
        </Select>
      </Grid>
      {groupAppointment && (
        <>
          <Grid item={true} xs={1} />
          <Grid item={true} xs={4}>
            <Typography>
              <Trans>Cantidad de pacientes</Trans>*
            </Typography>
          </Grid>
          <Grid item={true} xs={7}>
            <TextField
              type='number'
              className={classes.fullwidth}
              data-testid='quantity-patients-textfield'
              name='quantity-patients'
              variant='outlined'
              size='small'
              value={newSchedule.quantity_patients}
              onChange={handleChangeQuantityPatients}
            />
          </Grid>
        </>
      )}
      {webAppointment && (
        <>
          <Grid item={true} xs={1} />
          <Grid item={true} xs={4} data-testid='check-web-appointment'>
            <Typography>
              <Trans>Habilitar para citas web</Trans>
            </Typography>
          </Grid>
          <Grid item={true} xs={7}>
            <Checkbox
              checked={newSchedule.web_appointment}
              onChange={handleWebAppointment}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default ScheduleDetailsExams
