import { ConfirmationDialog } from '@devexpress/dx-react-scheduler-material-ui'
import { t, Trans } from '@lingui/macro'
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'
import { switchViewType, viewSlot } from './SlotProperties'

export interface IScheduleConfirmDialogProps extends ConfirmationDialog.LayoutProps {
  switchView: switchViewType
}

const ScheduleConfirmDialog: React.FC<IScheduleConfirmDialogProps> = ({ switchView, handleCancel, handleConfirm }) => {
  return (
    <>
      <DialogTitle id='alert-dialog-title'> {t`Confirmar acción`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {switchView === viewSlot ? (
            <Trans>¿Esta seguro que desea eliminar este espacio de la agenda?</Trans>
          ) : (
            <Trans>¿Esta seguro que desea eliminar esta franja de la agenda?</Trans>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          {t`Cancelar`}
        </Button>
        <Button onClick={handleConfirm} color='primary' autoFocus={true} data-testid={'test-delete-slot'}>
          {t`Eliminar`}
        </Button>
      </DialogActions>
    </>
  )
}

export default ScheduleConfirmDialog
